import useAuthentication from "hooks/useAuthentication";
import { GroupRole } from "core/auth/GroupRole";
import { useIsAdmin } from "core/auth/useIsAdmin";
import { AuthenticatedGroup } from "services/auth/AuthContextProvider";

export function findGroupInTree(
    groupId: string,
    tree: AuthenticatedGroup[]
): AuthenticatedGroup | null {
    const foundGroup = tree.find((group) => group.id === groupId);
    if (foundGroup) {
        return foundGroup;
    }

    for (const group of tree) {
        if (!group.sub || group.sub.length === 0) {
            continue;
        }

        const foundSubGroup = findGroupInTree(groupId, group.sub);

        if (foundSubGroup) {
            return foundSubGroup;
        }
    }

    return null;
}

export function useHasGroupRole(groupId: string, role: GroupRole): boolean {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();
    const foundGroup = findGroupInTree(groupId, auth.getGroups());

    return isAdmin || foundGroup?.roles.includes(role);
}
