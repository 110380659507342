import { useTemplate } from "hooks/useTemplate";
import { TemplateLayout } from "@castia/sdk";

export function useTemplateLayout(
    templateIdentifier: string,
    templateLayoutIdentifier?: string
): TemplateLayout | null | undefined {
    const template = useTemplate(templateIdentifier);

    if (!template.layouts || template.layouts.length === 0) {
        return null;
    }

    return template.layouts.length > 1 && templateLayoutIdentifier
        ? template.layouts.find(
              (layout) => layout.id === templateLayoutIdentifier
          )
        : template.layouts[0];
}
