import React, { ReactElement } from "react";
import { ButtonGroup, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { schedulePath } from "core/util/routes";
import { ScheduleDeleteButton } from "schedule/schedule/components/ScheduleDeleteButton";
import { FolderListItem } from "model/FolderListItem";
import { FolderNavigateUp } from "core/folder/FolderNavigateUp/FolderNavigateUp";
import { ScheduleMoveButton } from "schedule/schedule/components/ScheduleMoveButton";
import { FolderListGroupItem } from "core/folder/FolderListGroupItem/FolderListGroupItem";
import LocalEvents from "events/LocalEvents";
import { FolderType } from "model/FolderType";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";

interface ScheduleListProps {
    schedules: FolderListItem[];
    folderId?: string;
}

export function ScheduleList(props: ScheduleListProps): ReactElement {
    const organizationContext = useOrganizationContext();

    return (
        <ListGroup className="nav" data-cy="schedules-list">
            <FolderNavigateUp parentFolderId={props.folderId} />
            {props.schedules.map((folderListItem, index) => {
                if (folderListItem.type === "FOLDER") {
                    return (
                        <FolderListGroupItem
                            key={index}
                            folderListItem={folderListItem}
                            changeEvent={LocalEvents.SCHEDULES_CHANGED}
                            folderType={FolderType.SCHEDULE}
                        />
                    );
                }

                return (
                    <ListGroup.Item key={folderListItem.id}>
                        <div className="d-flex justify-content-between">
                            <div>
                                <Link
                                    className="nav-link"
                                    to={`${schedulePath}/${folderListItem.id}`}
                                >
                                    {folderListItem.name}
                                </Link>
                            </div>
                            <div>
                                <ButtonGroup>
                                    <HasGroupRole
                                        groupId={organizationContext}
                                        role={GroupRole.EDITOR}
                                    >
                                        <ScheduleMoveButton
                                            currentFolderId={props.folderId}
                                            scheduleId={folderListItem.id}
                                        />
                                        <OverflowMenu variant="secondary">
                                            <ScheduleDeleteButton
                                                schedule={folderListItem}
                                                inList={true}
                                                variant="dropdown"
                                            />
                                        </OverflowMenu>
                                    </HasGroupRole>
                                </ButtonGroup>
                            </div>
                        </div>
                    </ListGroup.Item>
                );
            })}
        </ListGroup>
    );
}
