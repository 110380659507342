import { GroupRole } from "core/auth/GroupRole";
import { useIsAdmin } from "core/auth/useIsAdmin";
import useAuthentication from "hooks/useAuthentication";
import { AuthenticatedGroup } from "services/auth/AuthContextProvider";

function findRoleInGroups(
    groups: AuthenticatedGroup[],
    role: GroupRole
): boolean {
    for (const group of groups) {
        if (group.roles?.includes(role)) {
            return true;
        }

        if (group.sub && group.sub.length > 0) {
            const foundRole = findRoleInGroups(group.sub, role);
            if (foundRole) {
                return true;
            }
        }
    }

    return false;
}

export function useHasGroupRoleOnAnyGroup(role: GroupRole): boolean {
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();
    const groups = auth.getGroups();

    return isAdmin || findRoleInGroups(groups, role);
}
