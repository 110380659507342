import React, { ReactElement } from "react";
import { ProgressBar } from "react-bootstrap";
import { UploadingItem } from "hooks/upload/useUploadProgress";
import style from "core/components/UploadContext/UploadProgressItem.scss";

interface UploadProgressItemProps {
    item: UploadingItem;
}

export function UploadProgressItem(
    props: UploadProgressItemProps
): ReactElement {
    return (
        <>
            <div className="text-truncate">{props.item.name}</div>
            {props.item.percentage < 100 ? (
                <ProgressBar
                    className={style.progressbar}
                    animated
                    now={props.item.percentage}
                    label={`${props.item.percentage.toFixed(2)}%`}
                />
            ) : (
                <ProgressBar
                    className={style.progressbar}
                    variant="success"
                    now={props.item.percentage}
                    label={`${props.item.percentage}%`}
                />
            )}
        </>
    );
}
