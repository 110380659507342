import useAuthentication from "hooks/useAuthentication";
import { useEffect, useRef, useState } from "react";
import apiRequestHeaders from "core/util/apiRequestHeaders";
import { ApiError } from "hooks/api/ApiError";
import { ORGANIZATION_CONTEXT_STORAGE_KEY } from "core/auth/GroupSelect";

export interface FetchData<T> {
    response?: T;
    error?: ApiError;
    isLoading: boolean;
    refreshData: (additionalQueryParams?: URLSearchParams) => void;
}

function paramsToObject(entries: URLSearchParams) {
    const result: Record<string, string> = {};
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
}

/**
 * Fetches the data at the given url with JWT authentication.
 * @param url
 * @param queryParams
 * @param skipAuth
 */
function useFetch<T>(
    url: string,
    queryParams?: URLSearchParams,
    skipAuth = false
): FetchData<T> {
    const auth = useAuthentication();
    const [response, setResponse] = useState<T>(null);
    const [error, setError] = useState<ApiError>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const queryParamsRef = useRef(queryParams);

    async function fetchData(
        overrideQueryParams?: URLSearchParams
    ): Promise<void> {
        setIsLoading(true);
        setError(null);

        try {
            let fetchUrl = url;
            if (overrideQueryParams) {
                queryParamsRef.current = new URLSearchParams(
                    Object.assign(
                        {},
                        paramsToObject(queryParamsRef.current),
                        paramsToObject(overrideQueryParams)
                    )
                );
                fetchUrl += "?" + queryParamsRef.current;
            } else if (queryParamsRef.current) {
                fetchUrl += "?" + queryParamsRef.current;
            }

            const apiResponse = await fetch(fetchUrl, {
                headers: skipAuth
                    ? apiRequestHeaders(null, null, true)
                    : apiRequestHeaders(
                          await auth.getToken(),
                          auth.organizationContext,
                          skipAuth
                      ),
            });
            if (!apiResponse.ok) {
                if (apiResponse.status === 403) {
                    const json = await apiResponse.json();
                    if (json.reason === "GROUP_ACCESS_DENIED") {
                        // If group access was denied, remove the organization context and reload to the homepage.
                        // This ensures everything is cleanly loaded
                        localStorage.removeItem(
                            ORGANIZATION_CONTEXT_STORAGE_KEY
                        );
                        location.replace("/");
                    }
                }
                setError({
                    message: apiResponse.statusText,
                    status: apiResponse.status,
                });
                return;
            }
            const json = await apiResponse.json();
            // TODO add class-transformer
            setResponse(json);
        } catch (apiError) {
            console.error(apiError);
            setError(apiError);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect((): void => {
        fetchData();
    }, [url]);

    return { response, error, isLoading, refreshData: fetchData };
}

export default useFetch;
