import React, { ChangeEvent, ReactElement, useRef, useState } from "react";
import {
    FormControl,
    InputGroup,
    OverlayTrigger,
    Popover,
} from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";
import Button from "core/components/UI/Button/Button";
import useSetDuration from "hooks/api/channel/useSetDuration";

interface SceneDurationButtonProps {
    channelId: string;
    channelSceneId: string;
    initialDuration: number;
    editable: boolean;
}

/**
 * Component to show the scene duration change button and the popover.
 * @param props
 * @constructor
 */
const SceneDurationButton: React.FunctionComponent<SceneDurationButtonProps> = (
    props
): ReactElement => {
    const [show, setShow] = useState(false);
    const durationRefContainer = useRef(props.initialDuration);
    const [duration, setDuration] = useState(props.initialDuration);
    const [invalid, setInvalid] = useState(false);
    const target = useRef(null);
    const { sendRequest, isLoading } = useSetDuration(
        props.channelId,
        props.channelSceneId
    );

    /**
     * Update the state when the input changes.
     * @param event
     */
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setInvalid(false);
        durationRefContainer.current = Number(event.currentTarget.value);
    };

    /**
     * Read the current duration from the state and store it. Then call the callback and hide the popover.
     */
    const apply = async (): Promise<void> => {
        // Check if the duration is over 1 and a whole number.
        if (
            durationRefContainer.current < 1 ||
            !Number.isInteger(durationRefContainer.current)
        ) {
            setInvalid(true);
            return;
        }

        await sendRequest({
            duration: durationRefContainer.current,
        });
        setDuration(durationRefContainer.current);
        setShow(false);
    };

    /**
     * Call apply on enter keypress.
     * @param event
     */
    const keyPress = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter") {
            apply();
        }
    };

    const popover = (
        <Popover
            id={`popover-${props.channelSceneId}`}
            data-boundary="viewport"
        >
            <Popover.Body data-boundary="viewport">
                <InputGroup>
                    <FormControl
                        placeholder="Duration (s)"
                        aria-label="Duration (s)"
                        as="input"
                        type="number"
                        isInvalid={invalid}
                        min={1}
                        step={1}
                        onKeyPress={keyPress}
                        onChange={handleInputChange}
                    />
                    <Button
                        variant="primary"
                        onClick={apply}
                        disabled={isLoading}
                    >
                        <FaCheck />
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={(): void => setShow(false)}
                        disabled={isLoading}
                    >
                        <FaTimes />
                    </Button>
                </InputGroup>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <OverlayTrigger
                placement="top"
                trigger="click"
                overlay={popover}
                defaultShow={false}
                delay={0}
                flip={false}
                show={show}
            >
                <Button
                    ref={target}
                    variant="primary"
                    size="sm"
                    disabled={!props.editable}
                    onClick={(): void => setShow(!show)}
                >
                    {duration.toFixed(0)}s
                </Button>
            </OverlayTrigger>
        </>
    );
};

export default SceneDurationButton;
