import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { Schedule } from "model/Schedule";

export function useFetchOneSchedule(scheduleId: string): FetchData<Schedule> {
    const fetch = useFetch<Schedule>(
        `${environment.apiBaseUrl}/api/v2/schedules/${scheduleId}`
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.SCHEDULES_CHANGED, eventCallback);
    useEventListener(LocalEvents.SCHEDULES_PERIODS_CHANGED, eventCallback);

    return fetch;
}
