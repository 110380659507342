import Device from "model/Device";
import { Card } from "react-bootstrap";
import * as React from "react";
import { ContentField } from "core/components/fields/ContentField";
import { ContentTypeField } from "core/components/fields/ContentTypeField";
import { ReactElement } from "react";
import { DeviceConnectedField } from "device/field/DeviceConnectedField";
import { DeviceContentFormModal } from "device/edit/DeviceContentFormModal";

interface DeviceOverviewCardProps {
    device: Device;
}

export function DeviceOverviewCard(
    props: DeviceOverviewCardProps
): ReactElement {
    return (
        <Card>
            <Card.Body>
                <dl>
                    <dt>Name</dt>
                    <dd>{props.device.name}</dd>
                    <dt>Content Type</dt>
                    <dd>
                        <ContentTypeField
                            contentType={props.device.contentType}
                        />
                    </dd>
                    <dt>
                        Content name{" "}
                        <DeviceContentFormModal device={props.device} />
                    </dt>
                    <dd>
                        <ContentField
                            contentId={props.device.contentId}
                            contentType={props.device.contentType}
                            deviceId={props.device.id}
                        />
                    </dd>
                    <dt>Connected</dt>
                    <dd>
                        <DeviceConnectedField
                            connected={props.device.connected}
                        />
                    </dd>
                    {props.device.wireGuardIp && (
                        <>
                            <dt>WireGuard IP</dt>
                            <dd>{props.device.wireGuardIp}</dd>
                        </>
                    )}
                </dl>
            </Card.Body>
        </Card>
    );
}
