import { FetchData, useFetch } from "@castia/sdk";
import environment from "core/util/environment";

export function useFetchManageableGroupRoles(
    groupId: string
): FetchData<string[]> {
    return useFetch<string[]>(
        `${environment.apiBaseUrl}/api/v1/group-roles/${groupId}`
    );
}
