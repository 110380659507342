import { FetchData, useFetch } from "@castia/sdk";
import environment from "core/util/environment";
import { ScheduleEvent } from "model/ScheduleEvent";

export function useFetchOneScheduleEvent(
    scheduleId: string,
    scheduleEventId: string
): FetchData<ScheduleEvent> {
    return useFetch(
        `${environment.apiBaseUrl}/api/v2/schedules/${scheduleId}/events/${scheduleEventId}`
    );
}
