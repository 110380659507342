import * as React from "react";
import styles from "core/components/LogoutButton/LogoutButton.scss";
import Button from "core/components/UI/Button/Button";
import { ReactElement } from "react";
import useAuthentication from "hooks/useAuthentication";

/**
 * Logout button which triggers a keycloak logout action on click.
 */
function LogoutButton(): ReactElement {
    const { logout } = useAuthentication();

    function logoutClick(): void {
        logout();
    }

    return (
        <Button onClick={logoutClick} className={styles.logoutButton}>
            Logout
        </Button>
    );
}

export default LogoutButton;
