import React, { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import Button from "core/components/UI/Button/Button";
import { useDeleteFolder } from "hooks/api/folder/useDeleteFolder";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";

interface FolderDeleteModalProps {
    closeModal: () => void;
    folderId: string;
    successEvent: string;
    showModal: boolean;
}

export function FolderDeleteModal(props: FolderDeleteModalProps): ReactElement {
    const {sendRequest, isLoading, error, response, reset} = useDeleteFolder(props.folderId, props.successEvent);

    function confirmDelete(): void {
        sendRequest();
    }

    if (response) {
        reset();
        props.closeModal();
    }

    return <Modal show={props.showModal} title="Delete folder?" onHide={props.closeModal}>
        <Modal.Body>
            {isLoading && <Loader />}
            {error && <Error>Something went wrong while trying to delete the folder. Please try again later.</Error>}
            {!isLoading && "Are you sure you wish to delete this folder? This will not delete the contents of the folder, but it will move all items outside of it."}
        </Modal.Body>
        <Modal.Footer>
            <Button variant={"secondary"} onClick={props.closeModal}>
                Cancel
            </Button>
            <Button variant={"danger"} onClick={confirmDelete}>
                Delete
            </Button>
        </Modal.Footer>
    </Modal>;
}
