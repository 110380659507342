import { Group } from "model/Group";
import React, { ReactElement } from "react";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { userPath } from "core/util/routes";
import { Paging, ResponseErrorHandler } from "@castia/sdk";
import { defaultScenePageSize } from "hooks/api/scene/useFetchAllScenesPaginated";
import styles from "./GroupUsersCard.scss";
import { UserInviteToGroupButton } from "group/button/UserInviteToGroupButton";
import { useFetchGroupUsers } from "group/hooks/useFetchGroupUsers";
import { useFetchManageableGroupRoles } from "user/hooks/useFetchManageableGroupRoles";
import { GroupRoleSelector } from "group/field/GroupRoleSelector";
import { useIsAdmin } from "core/auth/useIsAdmin";
import { ShowIf } from "core/util/ShowIf";
import { UserRemoveFromGroupButton } from "user/button/UserRemoveFromGroupButton";
import LocalEvents from "events/LocalEvents";

interface GroupUsersCardProps {
    group: Group;
}

export function GroupUsersCard(props: GroupUsersCardProps): ReactElement {
    const { response, isLoading, error, refreshData } = useFetchGroupUsers(
        props.group.id
    );
    const manageableGroupRoles = useFetchManageableGroupRoles(props.group.id);
    const isAdmin = useIsAdmin();

    return (
        <Card>
            <Card.Body>
                <Card.Title className="d-flex justify-content-between">
                    <span>Users</span>
                    <UserInviteToGroupButton groupId={props.group.id} />
                </Card.Title>
                <ResponseErrorHandler
                    isLoading={isLoading}
                    error={error}
                    retryAction={() => refreshData()}
                >
                    {response && (
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>E-mail</th>
                                    <th>Name</th>
                                    <th>Role</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {response.items.map((user, index) => {
                                    return (
                                        <tr key={index}>
                                            <ShowIf condition={isAdmin}>
                                                <td>
                                                    <Link
                                                        className="nav nav-link"
                                                        to={`${userPath}/${user.id}`}
                                                    >
                                                        {user.email}
                                                    </Link>
                                                </td>
                                            </ShowIf>
                                            <ShowIf condition={!isAdmin}>
                                                <td className="align-middle">
                                                    {user.email}
                                                </td>
                                            </ShowIf>
                                            <td className="align-middle">
                                                {user.firstName} {user.lastName}
                                            </td>
                                            <td>
                                                <GroupRoleSelector
                                                    groupId={props.group.id}
                                                    userId={user.id}
                                                    roles={
                                                        manageableGroupRoles.response
                                                    }
                                                    rolesLoading={
                                                        manageableGroupRoles.isLoading
                                                    }
                                                    rolesError={
                                                        manageableGroupRoles.error
                                                    }
                                                    initialRoleValue={user.role}
                                                />
                                            </td>
                                            <td>
                                                <UserRemoveFromGroupButton
                                                    userId={user.id}
                                                    groupId={props.group.id}
                                                    successEvent={
                                                        LocalEvents.GROUP_CHANGED
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                    <div className={styles.pagingContainer}>
                        <Paging
                            currentPage={response?.meta.currentPage}
                            onClick={(page): void => {
                                refreshData(
                                    new URLSearchParams({
                                        page: String(page),
                                        limit: String(defaultScenePageSize),
                                    })
                                );
                            }}
                            totalPages={response?.meta.totalPages}
                            showMax={10}
                        />
                    </div>
                </ResponseErrorHandler>
            </Card.Body>
        </Card>
    );
}
