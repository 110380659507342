import React from "react";

export function EventOverlapResolutionExplanation() {
    return <span>
        If you have overlapping events, the event with the highest priority will be shown. Events that occur less frequently have a higher priority. The events are sorted from left to right in the calendar according to this priority, so events on the left have priority over events on the right.
        Below is a list of the priorities:
        <ol>
            <li>One-time events</li>
            <li>Yearly events</li>
            <li>Monthly events</li>
            <li>Weekly events</li>
            <li>Daily events</li>
        </ol>
    </span>;
}
