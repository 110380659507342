import React, { ReactElement } from "react";
import { useFetchOneSchedule } from "hooks/api/schedule/useFetchOneSchedule";
import { ResponseErrorHandler } from "@castia/sdk";
import { schedulePath } from "core/util/routes";
import { Link } from "react-router-dom";

interface ChannelFieldProps {
    scheduleId: string;
}

export function ScheduleField(props: ChannelFieldProps): ReactElement {
    const { response, isLoading, error } = useFetchOneSchedule(
        props.scheduleId
    );

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            <Link to={`${schedulePath}/${props.scheduleId}`}>
                {response?.name}
            </Link>
        </ResponseErrorHandler>
    );
}
