import { useLocation } from "react-router-dom";
import { useMemo } from "react";

function paramsToObject(entries: IterableIterator<[string, string]>) {
    const result: { [index: string] : string } = {};
    for(const [key, value] of entries) { // each 'entry' is a [key, value] tuple
        result[key] = value;
    }
    return result;
}

export function useQuery<T>(): T {
    const {search} = useLocation();

    return useMemo(() => {
        const urlParams = new URLSearchParams(search);
        return paramsToObject(urlParams.entries());
    }, [search]) as any;
}
