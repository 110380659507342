import useFetch, { FetchData } from "hooks/useFetch";
import { Paginated } from "@castia/sdk";
import { useEffect } from "react";

export function useFetchPaginated<T>(
    url: string,
    pageSize: number,
    startPage = 1,
    urlSearchParams?: Record<string, string>
): FetchData<Paginated<T>> {
    const fetch = useFetch<Paginated<T>>(
        url,
        new URLSearchParams({
            page: String(startPage),
            limit: String(pageSize),
            ...urlSearchParams
        })
    );

    useEffect((): void => {
        // If no items were found on the requested page, and there are items on an earlier page, load those instead.
        if (
            fetch.response?.meta?.itemCount === 0 &&
            fetch.response?.meta?.totalPages > 0
        ) {
            fetch.refreshData(
                new URLSearchParams({
                    page: String(fetch.response.meta.totalPages),
                    limit: String(pageSize),
                    ...urlSearchParams
                })
            );
        }
    }, [fetch.response]);

    return fetch;
}
