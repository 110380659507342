import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React, { ReactElement } from "react";
import { UserCreateForm } from "user/create/UserCreateForm";

export function UserCreatePage(): ReactElement {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Create User</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <UserCreateForm />
            </PageLayout.Body>
        </PageLayout>
    );
}
