import { FetchData, useEventListener, useFetch } from "@castia/sdk";
import { Group } from "model/Group";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";

export function useFetchOneGroup(groupId: string): FetchData<Group> {
    const fetch = useFetch<Group>(
        `${environment.apiBaseUrl}/api/v2/groups/${groupId}`
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.GROUP_CHANGED, eventCallback);

    return fetch;
}
