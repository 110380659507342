import React, { ReactElement } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { GroupListPage } from "group/GroupListPage";
import { GroupCreatePage } from "group/GroupCreatePage";
import { GroupEditPage } from "group/GroupEditPage";
import { GroupShowPage } from "group/GroupShowPage";

export function GroupRouter({
    match,
}: RouteComponentProps): ReactElement<RouteComponentProps> {
    return (
        <Switch>
            <Route
                path={`${match.url}/create`}
                exact
                component={GroupCreatePage}
            />
            <Route
                path={`${match.url}/:groupId`}
                exact
                component={GroupShowPage}
            />
            <Route
                path={`${match.url}/:groupId/edit`}
                exact
                component={GroupEditPage}
            />
            <Route path={`${match.url}`} component={GroupListPage} />
        </Switch>
    );
}
