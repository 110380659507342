import { useHasGroupRole } from "core/auth/useHasGroupRole";
import React, { PropsWithChildren, ReactElement } from "react";
import { ShowIf } from "core/util/ShowIf";
import { GroupRole } from "core/auth/GroupRole";

interface HasGroupRoleProps {
    groupId: string;
    role: GroupRole;
}

export function HasGroupRole(
    props: PropsWithChildren<HasGroupRoleProps>
): ReactElement {
    const hasGroupRole = useHasGroupRole(props.groupId, props.role);

    return <ShowIf condition={hasGroupRole}>{props.children}</ShowIf>;
}
