import React, { ReactElement } from "react";
import { FolderMoveFormData } from "core/folder/FolderMoveButton/FolderMoveModalBody";
import { FolderMoveButton } from "core/folder/FolderMoveButton/FolderMoveButton";
import { FolderType } from "model/FolderType";
import { usePatchSchedule } from "hooks/api/schedule/usePatchSchedule";

interface ScheduleMoveButtonProps {
    scheduleId: string;
    currentFolderId: string;
}

export function ScheduleMoveButton(props: ScheduleMoveButtonProps): ReactElement {
    const persistData = usePatchSchedule(props.scheduleId);

    async function onSave(data: FolderMoveFormData) {
        await persistData.sendRequest({
            folder: data.folder,
        });
    }

    return (
        <FolderMoveButton
            folderType={FolderType.SCHEDULE}
            onSave={onSave}
            persistData={persistData}
            currentFolderId={props.currentFolderId}
        />
    );
}
