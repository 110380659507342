import Channel from "model/Channel";
import React, { ReactElement } from "react";
import { ChannelForm, ChannelFormFields } from "channel/ChannelForm";
import { useUpdateChannel } from "hooks/api/channel/useUpdateChannel";

interface ChannelEditFormProps {
    channel: Channel;
    onDone: () => void;
}

export function ChannelEditForm(props: ChannelEditFormProps): ReactElement {
    const { sendRequest, isLoading } = useUpdateChannel(props.channel.id);

    async function onSubmit(data: ChannelFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            props.onDone();
        }
    }

    return (
        <ChannelForm
            handleSubmit={onSubmit}
            isLoading={isLoading}
            existingChannel={props.channel}
            handleClose={props.onDone}
        />
    );
}
