import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import { SimpleFunction } from "core/util/types/FunctionTypes";

export function usePatchGroup(
    groupId: string,
    onSuccess?: SimpleFunction
): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v2/groups/${groupId}`,
        "PATCH",
        undefined,
        onSuccess
    );
}
