import { useFormContext, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { Frequency } from "rrule";
import React from "react";
import { getMonth } from 'date-fns';

export function YearlyInput() {
    const form = useFormContext();
    const frequency = useWatch({
        name: "frequency",
    });

    useEffect(() => {
        if (frequency === Frequency.YEARLY) {
            const currentDate = new Date(form.getValues("startDate"));
            // Clear all month and day values when yearly is selected.
            form.setValue("byweekday", []);
            form.setValue("bysetpos", null);
            form.setValue("bymonthday", new Date(form.getValues("startDate")).getDate());
            form.setValue("bymonth", getMonth(currentDate) + 1);
        } else if (frequency !== Frequency.YEARLY) {
            // Clear all year values when yearly is not selected.
            form.setValue("bymonth", null);
        }
    }, [frequency]);

    return <></>;
}
