import * as React from "react";
import { ReactElement } from "react";
import { FaUser } from "react-icons/fa";
import useAuthentication from "hooks/useAuthentication";

function Username(): ReactElement {
    const { getUsername } = useAuthentication();
    return (
        <span>
            <FaUser /> {getUsername()}
        </span>
    );
}

export default Username;
