import { PropsWithChildren, ReactElement } from "react";
import { Alert } from "react-bootstrap";
import * as React from "react";
import Button from "core/components/UI/Button/Button";

interface ErrorProps {
    retryAction?: () => void;
}

function Error(props: PropsWithChildren<ErrorProps>): ReactElement {
    return (
        <Alert variant="danger">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    {props.children}
                </div>
                {props.retryAction && (
                    <Button variant={"danger"} onClick={props.retryAction}>
                        Retry
                    </Button>
                )}
            </div>
        </Alert>
    );
}

export default Error;
