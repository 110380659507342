import React, { ReactElement, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { FolderDeleteModal } from "core/folder/FolderDeleteButton/FolderDeleteModal";

interface FolderDeleteButtonProps {
    folderId: string;
    successEvent: string;
    variant: "button" | "dropdown";
}

export function FolderDeleteButton(
    props: FolderDeleteButtonProps
): ReactElement {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {props.variant === "button" && (<Button
                variant="danger"
                onClick={() => setShowModal(true)}
                title="Delete folder"
            >
                <FaTrash />
            </Button>)}
            {props.variant === "dropdown" && (
                <Dropdown.Item
                    onClick={() => setShowModal(true)}
                    className="d-flex align-items-center"
                >
                    <FaTrash color="var(--bs-danger)" />
                    &nbsp;Delete folder
                </Dropdown.Item>
            )}
            <FolderDeleteModal
                closeModal={() => setShowModal(false)}
                folderId={props.folderId}
                successEvent={props.successEvent}
                showModal={showModal}
            />
        </>
    );
}
