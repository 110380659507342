import React, { ReactElement } from "react";

function PageLayoutHeaderSecondary(
    props: React.PropsWithChildren<unknown>
): ReactElement {
    return (
        <div className="d-flex justify-content-center align-items-center">
            {props.children}
        </div>
    );
}

export default PageLayoutHeaderSecondary;
