import { FetchData, useFetch } from "@castia/sdk";
import { TenantTheme } from "model/TenantTheme";
import environment from "core/util/environment";

export function useFetchOneTheme(
    tenantProfileId: string
): FetchData<TenantTheme> {
    return useFetch(
        `${environment.apiBaseUrl}/api/v1/tenant-profiles/${tenantProfileId}/theme`
    );
}
