import React, { ReactElement } from "react";
import { FaTrash } from "react-icons/fa";
import Button from "core/components/UI/Button/Button";
import useRemoveSceneFromChannel from "hooks/api/channel/useRemoveSceneFromChannel";

interface RemoveSceneFromChannelButtonProps {
    channelId: string;
    channelSceneId: string;
}

/**
 * Button to remove a scene from a channel
 * @param props
 * @constructor
 */
function RemoveSceneFromChannelButton(
    props: RemoveSceneFromChannelButtonProps
): ReactElement {
    const { sendRequest } = useRemoveSceneFromChannel(
        props.channelId,
        props.channelSceneId
    );

    function onClick(): void {
        sendRequest();
    }

    return (
        <Button
            variant="danger"
            size="sm"
            onClick={onClick}
            data-cy="remove-scene-from-channel"
        >
            <FaTrash />
        </Button>
    );
}

export default RemoveSceneFromChannelButton;
