import { FaDownload } from "react-icons/fa";
import { Font } from "model/Font";
import React from "react";

interface FontDownloadButtonProps {
    font: Font;
}

export function FontDownloadButton(props: FontDownloadButtonProps) {
    return (
        <a className="btn btn-info" href={props.font.location} download>
            <FaDownload />
        </a>
    );
}
