import React, { ReactElement } from "react";
import { useFetchAllGroups } from "group/hooks/useFetchAllGroups";
import { Paging, ResponseErrorHandler } from "@castia/sdk";
import { defaultScenePageSize } from "hooks/api/scene/useFetchAllScenesPaginated";
import styles from "./GroupListContainer.scss";
import { GroupList } from "group/list/GroupList";

export function GroupListContainer(): ReactElement {
    const { response, isLoading, error, refreshData } = useFetchAllGroups();

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            {response && (
                <GroupList groups={response.items} showHeader depth={0} />
            )}
            <div className={styles.pagingContainer}>
                <Paging
                    currentPage={response?.meta.currentPage}
                    onClick={(page): void => {
                        refreshData(
                            new URLSearchParams({
                                page: String(page),
                                limit: String(defaultScenePageSize),
                            })
                        );
                    }}
                    totalPages={response?.meta.totalPages}
                    showMax={10}
                />
            </div>
        </ResponseErrorHandler>
    );
}
