class ChannelScene {
    public id: string;
    public sceneId: string;
    public duration: number;
    public position: number;

    /**
     * @param scene
     * @param duration
     * @param position
     */
    public constructor(scene: string, duration: number, position: number) {
        this.sceneId = scene;
        this.duration = duration;
        this.position = position;
    }
}

export default ChannelScene;
