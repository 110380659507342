import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { FaSync } from "react-icons/fa";
import { toast } from "react-toastify";
import { useRefreshDevice } from "hooks/api/device/useRefreshDevice";

interface DeviceReloadButtonProps {
    deviceId: string
}

export function DeviceReloadButton(props: DeviceReloadButtonProps): ReactElement {
    const refresh = useRefreshDevice(props.deviceId);

    async function reloadPlayer(): Promise<void> {
        const confirmResult = confirm("Are you sure you want to update this player?");
        if (confirmResult) {
            await refresh.sendRequest();
            toast.info(`Player software update triggered!`);
        }
    }

    return <Button
        variant="info"
        onClick={reloadPlayer}
        title="Update player software"
    >
        <FaSync />
    </Button>;
}
