/* global process */
const environment = {
    environment: process.env.APP_ENV,
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    webSocketUrl: process.env.REACT_APP_WEBSOCKET_URL,
    keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL,
    keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM,
    videoUploadUrl: process.env.REACT_APP_VIDEO_UPLOAD_URL,
    release: process.env.REACT_APP_RELEASE,
    keycloakClientName: process.env.REACT_APP_KEYCLOAK_CLIENT_NAME,
    playerUrl: process.env.REACT_APP_PLAYER_URL,
};

export default environment;
