import React, { ReactElement } from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { GroupCreateForm } from "group/create/GroupCreateForm";

export function GroupCreatePage(): ReactElement {
    return <PageLayout>
        <PageLayout.Header>
            <PageLayout.Header.Title>Create Group</PageLayout.Header.Title>
        </PageLayout.Header>
        <PageLayout.Body>
            <GroupCreateForm />
        </PageLayout.Body>
    </PageLayout>;
}
