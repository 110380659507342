import React, { forwardRef, MutableRefObject } from "react";
import ChannelSceneDTO from "model/ChannelSceneDTO";
import usePlugins from "hooks/usePlugins";
import { ButtonGroup, Card, Col } from "react-bootstrap";
import styles from "channel/edit/preview/ChannelScenePreview.scss";
import { FaArrowsAlt, FaEdit } from "react-icons/fa";
import ScaledScene from "scene/ScenesEditorPage/components/ScaledScene/ScaledScene";
import PluginTemplateComponent from "core/components/Plugin/PluginTemplateComponent/PluginTemplateComponent";
import environment from "core/util/environment";
import SceneDurationButton from "channel/edit/preview/SceneDurationButton";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { scenesPath } from "core/util/routes";
import RemoveSceneFromChannelButton from "channel/edit/preview/RemoveSceneFromChannelButton";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { deepMerge } from "core/util/deepMerge";

interface SortableItemProps {
    channelScene: ChannelSceneDTO;
    channelId: string;
    hideContent: boolean;
    attributes?: {
        role: string;
        tabIndex: number;
        "aria-pressed": boolean | undefined;
        "aria-roledescription": string;
        "aria-describedby": string;
    };
    listeners?: DraggableSyntheticListeners;
    style?: React.CSSProperties;
}

const ChannelScenePreview = forwardRef(
    (
        {
            channelScene,
            channelId,
            hideContent,
            listeners,
            attributes,
            style,
        }: SortableItemProps,
        ref: MutableRefObject<HTMLDivElement>
    ) => {
        const { findTemplate } = usePlugins();
        const templateMetadata = findTemplate(channelScene.scene.template);

        const duration = templateMetadata.calculatedDuration
            ? templateMetadata.calculatedDuration(channelScene.scene.settings)
            : channelScene.duration;

        const settings = {
            title: channelScene.scene.settings.title,
            custom: deepMerge(
                {},
                templateMetadata.defaultSettings || {},
                channelScene.scene.settings.custom
            ),
        };

        return (
            <Col md={3} style={style} ref={ref} className="my-2">
                <Card>
                    <Card.Header className={styles.noPadding}>
                        <div
                            className={styles.header}
                            {...listeners}
                            {...attributes}
                        >
                            <span className="text-truncate">
                                {channelScene.scene.title}
                            </span>
                            <span>
                                <FaArrowsAlt />
                            </span>
                        </div>
                    </Card.Header>
                    <Card.Body className={styles.cardBody}>
                        <ScaledScene>
                            {!hideContent && (
                                <PluginTemplateComponent
                                    template={
                                        templateMetadata.templateIdentifier
                                    }
                                    layoutId={channelScene.scene.layout}
                                    resourceDirectory={
                                        templateMetadata.resourceDirectory
                                    }
                                    environment={environment}
                                    templateProps={{
                                        editable: false,
                                        content: channelScene.scene.content,
                                        settings: settings,
                                        preview: true,
                                    }}
                                />
                            )}
                        </ScaledScene>
                    </Card.Body>
                    <ButtonGroup>
                        <SceneDurationButton
                            channelId={channelId}
                            channelSceneId={channelScene.id}
                            initialDuration={duration}
                            editable={
                                templateMetadata.calculatedDuration instanceof
                                Function
                                    ? false
                                    : !templateMetadata.disableDurationModification
                            }
                        />
                        <LinkButton
                            to={`${scenesPath}/${channelScene.scene.id}`}
                            variant="secondary"
                            size="sm"
                        >
                            <FaEdit />
                        </LinkButton>
                        <RemoveSceneFromChannelButton
                            channelSceneId={channelScene.id}
                            channelId={channelId}
                        />
                    </ButtonGroup>
                </Card>
            </Col>
        );
    }
);
ChannelScenePreview.displayName = "ChannelScenePreview";
export default ChannelScenePreview;
