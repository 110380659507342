import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

/**
 * Delete a scene.
 * @param sceneId
 */
function useDeleteScene(sceneId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/scenes/${sceneId}`,
        "DELETE",
        LocalEvents.SCENES_CHANGED,
    );
}

export default useDeleteScene;
