import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Button, Form } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import React from "react";
import { FileInput } from "core/components/input/FileInput";
import { Font } from "model/Font";
import * as yup from "yup";

export interface FontFormFields {
    name: string;
    file: any;
}

const schema = yup.object({
    name: yup.string().required("Name is a required field"),
    file: yup
        .mixed()
        .test(
            "required-file",
            "Font File is a required field",
            function (value) {
                return value?.length > 0;
            }
        ),
});

interface FontFormProps {
    isLoading: boolean;
    existingFont?: Font;
    handleSubmit: (data: FontFormFields) => void;
}

export function FontForm(props: FontFormProps) {
    const isCreate = !props.existingFont;
    const form = useForm<FontFormFields>({
        resolver: yupResolver(schema, {
            context: { isCreate: isCreate },
        }),
        defaultValues: props.existingFont
            ? {
                  name: props.existingFont.name,
              }
            : {},
    });

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(props.handleSubmit)} data-cy="font-form">
                <TextInput name="name" label="Name" />

                <FileInput name="file" label="Font File" />

                <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isLoading}
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}
