import { useFetchOneUser } from "user/hooks/useFetchOneUser";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import React from "react";
import { UserProfileEditForm } from "user/edit/UserProfileEditForm";

export function UserProfileEditPage() {
    const { response, isLoading, error } = useFetchOneUser(undefined, true);

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Edit Profile</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && <UserProfileEditForm user={response} />}
                </ResponseErrorHandler>
            </PageLayout.Body>
        </PageLayout>
    );
}
