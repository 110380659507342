import React, { ReactElement } from "react";
import { Form, Modal } from "react-bootstrap";
import { FolderType } from "model/FolderType";
import Button from "core/components/UI/Button/Button";
import { useFetchAllFolders } from "hooks/api/folder/useFetchAllFolders";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { useForm, FormProvider } from "react-hook-form";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import { FolderSelectInput } from "core/folder/FolderSelectInput/FolderSelectInput";
import { PersistData } from "hooks/useFetchPersist";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";

interface FolderMoveModalProps {
    show: boolean;
    onClose: () => void;
    folderType: FolderType;
    onSave: (data: FolderMoveFormData) => void;
    persistData: PersistData;
    currentFolderId?: string;
}

export interface FolderMoveFormData {
    folder: string;
}

export function FolderMoveModalBody(props: FolderMoveModalProps): ReactElement {
    const folderResponse = useFetchAllFolders(props.folderType);
    const form = useForm<FolderMoveFormData>();

    function handleClose() {
        props.onClose();
    }

    function onSubmit(data: FolderMoveFormData): void {
        // If no folder is selected, the folder property is an empty string. But to unset it in the API we need to set it to null.
        data.folder = data.folder || null;
        props.onSave(data);
    }

    return (
        <>
            <Modal.Header>Move to folder</Modal.Header>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                {props.persistData.isLoading && <Loader />}
                {props.persistData.error && (
                    <Error>{props.persistData.error.message}</Error>
                )}
                <Modal.Body>
                    <FormProvider {...form}>
                        <ResponseErrorHandler
                            isLoading={folderResponse.isLoading}
                            error={folderResponse.error}
                            retryAction={() => folderResponse.refreshData()}
                        >
                            <div>
                                <Form.Label>Folder</Form.Label>
                            </div>
                            {folderResponse.response && (
                                <FolderSelectInput<FolderMoveFormData>
                                    name="folder"
                                    register={form.register}
                                    currentValue={props.currentFolderId || ""}
                                    folders={folderResponse.response}
                                />
                            )}
                            {form.formState.errors.folder && (
                                <FormInputError>
                                    {form.formState.errors.folder.message}
                                </FormInputError>
                            )}
                        </ResponseErrorHandler>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </>
    );
}
