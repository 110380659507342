import React, { ReactElement } from "react";
import { useUpdateFolder } from "hooks/api/folder/useUpdateFolder";
import { FolderFormValues } from "core/folder/FolderForm/FolderFormValues";
import { FormProvider, useForm } from "react-hook-form";
import { FolderForm } from "core/folder/FolderForm/FolderForm";
import Modal from "core/components/UI/Modal/Modal";

interface FolderEditModalProps {
    show: boolean;
    onDone: () => void;
    folderId: string;
    successEvent: string;
}

export function FolderEditModal(props: FolderEditModalProps): ReactElement {
    const { sendRequest, error, reset } = useUpdateFolder(props.folderId, props.successEvent);
    const form = useForm<FolderFormValues>();

    async function onSubmit(data: FolderFormValues): Promise<void> {
        const result = await sendRequest({ ...data });
        if (result) {
            form.reset();
            reset();
            props.onDone();
        }
    }

    function handleClose(): void {
        props.onDone();
    }

    return <Modal
        show={props.show}
        onHide={handleClose}
        size="lg"
        title="Edit folder"
    >
        <FormProvider {...form}>
            <FolderForm
                error={error}
                onSubmit={onSubmit}
                handleClose={handleClose}
            />
        </FormProvider>
    </Modal>;
}
