import React, { ReactElement } from "react";
import { Form } from "react-bootstrap";
import { ContentInput } from "core/components/input/ContentInput";

export function DeviceContentPage(): ReactElement {
    return (
        <>
            <p>You can immediately set the content for this device. Or skip it and set it later.</p>
            <Form.Label>Device content</Form.Label>
            <br />
            <ContentInput />
        </>
    );
}
