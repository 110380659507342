import { useHistory } from "react-router-dom";
import { FieldNamesMarkedBoolean } from "react-hook-form/dist/types/form";
import { keycloakRealmPath } from "core/util/routes";
import React from "react";
import { usePatchKeycloakRealm } from "hooks/api/keycloak-realm/usePatchKeycloakRealm";
import { KeycloakRealm } from "model/KeycloakRealm";
import {
    KeycloakRealmForm,
    KeycloakRealmFormFields,
} from "keycloak-realm/KeycloakRealmForm";

interface KeycloakRealmEditFormProps {
    keycloakRealm: KeycloakRealm;
}

export function KeycloakRealmEditForm(props: KeycloakRealmEditFormProps) {
    const { sendRequest, isLoading } = usePatchKeycloakRealm(
        props.keycloakRealm.id
    );
    const history = useHistory();

    async function onSubmit(
        data: KeycloakRealmFormFields,
        dirtyFields: FieldNamesMarkedBoolean<KeycloakRealmFormFields>
    ): Promise<void> {
        const updatedFields: any = {
            keycloakSettings: {},
        };
        Object.keys(dirtyFields).forEach(
            (key: keyof KeycloakRealmFormFields) => {
                const isDirty = dirtyFields[key];
                if (isDirty) {
                    updatedFields[key] = data[key];
                }
            }
        );

        const result = await sendRequest(updatedFields);
        if (result) {
            history.push(`${keycloakRealmPath}`);
        }
    }

    return (
        <KeycloakRealmForm
            handleSubmit={onSubmit}
            isLoading={isLoading}
            existingKeycloakRealm={props.keycloakRealm}
        />
    );
}
