import { Route, RouteComponentProps, Switch } from "react-router";
import React, { ReactElement } from "react";
import { WireGuardPeerListPage } from "wireguard-peer/WireGuardPeerListPage";

export function WireguardPeerRouter({
    match,
}: RouteComponentProps): ReactElement<RouteComponentProps> {
    return (
        <Switch>
            <Route path={`${match.url}`} component={WireGuardPeerListPage} />
        </Switch>
    );
}
