import { Route, RouteComponentProps, Switch } from "react-router";
import React, { ReactElement } from "react";
import { KeycloakRealmCreatePage } from "keycloak-realm/KeycloakRealmCreatePage";
import { KeycloakRealmShowPage } from "keycloak-realm/KeycloakRealmShowPage";
import { KeycloakRealmEditPage } from "keycloak-realm/KeycloakRealmEditPage";
import { KeycloakRealmListPage } from "keycloak-realm/KeycloakRealmListPage";

export function KeycloakRealmRouter({
    match,
}: RouteComponentProps): ReactElement<RouteComponentProps> {
    return (
        <Switch>
            <Route
                path={`${match.url}/create`}
                exact
                component={KeycloakRealmCreatePage}
            />
            <Route
                path={`${match.url}/:keycloakRealmId`}
                exact
                component={KeycloakRealmShowPage}
            />
            <Route
                path={`${match.url}/:keycloakRealmId/edit`}
                exact
                component={KeycloakRealmEditPage}
            />
            <Route path={`${match.url}`} component={KeycloakRealmListPage} />
        </Switch>
    );
}
