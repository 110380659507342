import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { TenantProfileEditForm } from "tenant-profile/edit/TenantProfileEditForm";
import { useParams } from "react-router-dom";
import { ResponseErrorHandler } from "@castia/sdk";
import { useFetchOneTenantProfile } from "hooks/api/tenant-profile/useFetchOneTenantProfile";

interface TenantProfilePageParams {
    tenantProfileId: string;
}

export function TenantProfileEditPage() {
    const { tenantProfileId } = useParams<TenantProfilePageParams>();
    const { response, isLoading, error } =
        useFetchOneTenantProfile(tenantProfileId);

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>
                    Edit Tenant Profile
                </PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && (
                        <TenantProfileEditForm tenantProfile={response} />
                    )}
                </ResponseErrorHandler>
            </PageLayout.Body>
        </PageLayout>
    );
}
