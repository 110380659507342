import React, { ReactElement } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import DeviceCreatePage from "device/DeviceCreatePage";
import { DeviceShowPage } from "device/DeviceShowPage";
import DeviceListPage from "device/DeviceListPage";
import DeviceEditPage from "device/DeviceEditPage";

/**
 * Routes for /devices sub pages
 * @param match
 */
function DeviceRouter({
    match,
}: RouteComponentProps): ReactElement<RouteComponentProps> {
    return (
        <Switch>
            <Route
                path={`${match.url}/register`}
                exact
                component={DeviceCreatePage}
            />
            <Route
                path={`${match.url}/:deviceId`}
                exact
                component={DeviceShowPage}
            />
            <Route
                path={`${match.url}/:deviceId/edit`}
                exact
                component={DeviceEditPage}
            />
            <Route path={`${match.url}`} component={DeviceListPage} />
        </Switch>
    );
}

export default DeviceRouter;
