import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";

export function useUpdateScheduleEvent(
    scheduleId: string,
    scheduleEventId: string
) {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v2/schedules/${scheduleId}/events/${scheduleEventId}`,
        "PUT"
    );
}
