import React, { ReactElement } from "react";

interface ThemeVariableFieldProps {
    themeVariableValue: string;
}

export function ThemeVariableField(
    props: ThemeVariableFieldProps
): ReactElement {
    if (/^#[0-9A-F]{6}$/i.test(props.themeVariableValue)) {
        return (
            <div
                style={{
                    backgroundColor: props.themeVariableValue,
                    padding: "0.5em",
                    borderRadius: "0.5em",
                }}
            >
                {props.themeVariableValue}
            </div>
        );
    }

    return <>props.themeVariableValue</>;
}
