import React, { ReactElement } from "react";
import { User } from "model/User";
import { Card } from "react-bootstrap";
import { formatDateTime } from "core/util/dateTimeUtil";
import { useRoleLabel } from "hooks/useRoleLabel";
import { IsAdmin } from "core/auth/IsAdmin";

interface UserOverviewCardProps {
    user: User;
}

export function UserOverviewCard(props: UserOverviewCardProps): ReactElement {
    const getRoleLabel = useRoleLabel();

    return (
        <Card>
            <Card.Body>
                <dl>
                    <IsAdmin>
                        <dt>ID</dt>
                        <dd>{props.user.id}</dd>
                    </IsAdmin>
                    <dt>E-mail</dt>
                    <dd>{props.user.email}</dd>
                    <dt>Name</dt>
                    <dd>
                        {props.user.firstName} {props.user.lastName}
                    </dd>
                    <IsAdmin>
                        <dt>Created at</dt>
                        <dd>
                            {formatDateTime(new Date(props.user.createdAt))}
                        </dd>
                        <dt>Updated at</dt>
                        <dd>
                            {formatDateTime(new Date(props.user.updatedAt))}
                        </dd>
                        <dt>Roles</dt>
                        <dd>
                            <ul>
                                {props.user.roles
                                    .map((role) => getRoleLabel(role))
                                    .map((roleLabel, index) => {
                                        return <li key={index}>{roleLabel}</li>;
                                    })}
                            </ul>
                        </dd>
                    </IsAdmin>
                </dl>
            </Card.Body>
        </Card>
    );
}
