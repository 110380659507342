import { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { Paginated } from "@castia/sdk";
import { useFetchPaginated } from "hooks/useFetchPaginated";
import { Font } from "model/Font";

export const fontPageSize = 18;

/**
 * Fetch the image list.
 */
export function useFetchAllFonts(): FetchData<Paginated<Font>> {
    const fetch = useFetchPaginated<Font>(
        `${environment.apiBaseUrl}/api/v1/media/fonts`,
        fontPageSize,
        1
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.FONTS_CHANGED, eventCallback);

    return fetch;
}
