import styles from "device/field/DeviceConnectedField.scss";
import { classNames } from "@castia/sdk";
import React, { ReactElement } from "react";

interface DeviceConnectedFieldProps {
    connected: boolean;
}

export function DeviceConnectedField(
    props: DeviceConnectedFieldProps
): ReactElement {
    return (
        <div
            className={classNames(
                styles.indicator,
                props.connected ? styles.connected : styles.disconnected
            )}
        />
    );
}
