import { FaTrash } from "react-icons/fa";
import { Button } from "react-bootstrap";
import React from "react";
import { useDeleteNewsItem } from "hooks/api/news/useDeleteNewsItem";

interface NewsDeleteButtonProps {
    newsItemId: string;
}

export function NewsDeleteButton(props: NewsDeleteButtonProps) {
    const { sendRequest } = useDeleteNewsItem(props.newsItemId);

    function onClick() {
        const shouldDelete = confirm(
            "Are you sure you want to delete this news item?"
        );

        if (shouldDelete) {
            sendRequest();
        }
    }

    return (
        <Button variant="danger" onClick={onClick}>
            <FaTrash />
        </Button>
    );
}
