import * as React from "react";
import { ReactElement, useEffect, useState } from "react";
import { Route, RouteComponentProps, useLocation } from "react-router-dom";
import HomePage from "home/HomePage";
import SceneOverviewPage from "scene/ScenesOverviewPage/SceneOverviewPage";
import Header from "../../core/components/Header/Header";
import styles from "./AdminRouter.scss";
import {
    FaCalendarAlt,
    FaFont,
    FaHome,
    FaImage,
    FaLaptop,
    FaLaptopCode,
    FaNewspaper,
    FaPhotoVideo,
    FaTv,
    FaUser,
    FaUsers,
    FaVideo,
    FaHouseUser,
    FaGlobe,
    FaNetworkWired,
} from "react-icons/fa";
import { Accordion, Nav } from "react-bootstrap";
import { classNames } from "@castia/sdk";
import ChannelListPage from "channel/ChannelListPage";
import DeviceRouter from "routers/AdminRouter/sub-routers/DeviceRouter";
import Sidebar from "core/components/Sidebar/Sidebar";
import SceneEditorPage from "scene/ScenesEditorPage/SceneEditorPage";
import ChannelEditor from "channel/ChannelEditorPage";
import { toast, ToastContainer } from "react-toastify";
import ImagePage from "image/ImagePage";
import {
    fontPath,
    groupPath,
    imagePath,
    mediaPath,
    newsPath,
    playerAdminPage,
    profilePath,
    rootPath,
    schedulePath,
    userPath,
    videoPath,
    tenantProfilePath,
    keycloakRealmPath,
    wireguardPeerAdminPage,
} from "core/util/routes";
import { SchedulePage } from "schedule/SchedulePage";
import { ScheduleDetailPage } from "schedule/ScheduleDetailPage";
import { VideoPage } from "video/VideoPage";
import { IsSysAdmin } from "core/auth/IsSysAdmin";
import { PlayerAdminPage } from "sysadmin/PlayerAdminPage/PlayerAdminPage";
import { GroupSelect } from "core/auth/GroupSelect";
import { UserRouter } from "routers/AdminRouter/sub-routers/UserRouter";
import useAuthentication from "hooks/useAuthentication";
import { ShowIf } from "core/util/ShowIf";
import { GroupRouter } from "routers/AdminRouter/sub-routers/GroupRouter";
import { HasRole } from "core/auth/HasRole";
import { Role } from "core/auth/Role.enum";
import usePlugins from "hooks/usePlugins";
import { PluginPageComponent } from "core/components/Plugin/PluginPageComponent";
import { useHasGroupRoleOnAnyGroup } from "core/auth/useHasGroupRoleOnAnyGroup";
import { GroupRole } from "core/auth/GroupRole";
import { useIsAdmin } from "core/auth/useIsAdmin";
import { UserProfileRouter } from "routers/AdminRouter/sub-routers/UserProfileRouter";
import { findGroupInTree } from "core/auth/useHasGroupRole";
import { NewsRouter } from "./sub-routers/NewsRouter";
import { FontRouter } from "routers/AdminRouter/sub-routers/FontRouter";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { TenantProfileRouter } from "routers/AdminRouter/sub-routers/TenantProfileRouter";
import { KeycloakRealmRouter } from "routers/AdminRouter/sub-routers/KeycloakRealmRouter";
import { WireguardPeerRouter } from "routers/AdminRouter/sub-routers/WireguardPeerRouter";

const DEFAULT_TOAST_DURATION = 4000;

function AdminRouter({
    match,
}: RouteComponentProps): ReactElement<RouteComponentProps> {
    const [sidebarToggled, toggle] = useState(false);
    const location = useLocation();
    const auth = useAuthentication();
    const plugins = usePlugins();
    const isReseller = useHasGroupRoleOnAnyGroup(GroupRole.RESELLER);
    const isOrganizationAdmin = useHasGroupRoleOnAnyGroup(
        GroupRole.ORGANIZATION_ADMIN
    );
    const isAdmin = useIsAdmin();
    const org = useOrganizationContext();

    useEffect(() => {
        window.addEventListener("error", (e) => {
            // Hide ResizeObserver errors for dev mode. They can trigger while running cypress tests
            if (e.message.includes("ResizeObserver")) {
                const resizeObserverErrDiv = document.getElementById(
                    "webpack-dev-server-client-overlay-div"
                );
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay"
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });
    }, []);

    return (
        <>
            <Sidebar toggled={sidebarToggled}>
                <nav>
                    <ShowIf
                        condition={auth.getFlatGroups().length > 1 || isAdmin}
                    >
                        <GroupSelect />
                    </ShowIf>
                    <Nav
                        defaultActiveKey="/"
                        className={classNames(styles.sidebarNav, "flex-column")}
                    >
                        <Accordion
                            flush
                            defaultActiveKey={
                                location.pathname.includes(mediaPath)
                                    ? mediaPath
                                    : mediaPath
                            }
                        >
                            <Sidebar.MenuItem to={`${match.url}`} exact>
                                <FaHome /> <span>Overview</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem
                                to={`${match.url}/scenes`}
                                data-cy="menu-scenes"
                            >
                                <FaImage /> <span>Scenes</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={`${match.url}/channels`}>
                                <FaTv /> <span>Channels</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={schedulePath}>
                                <FaCalendarAlt /> <span>Scheduling</span>
                            </Sidebar.MenuItem>
                            <Accordion.Item
                                className="border-0"
                                eventKey={mediaPath}
                            >
                                <Accordion.Header className="bg-primary">
                                    <FaPhotoVideo /> <span>Media</span>
                                </Accordion.Header>
                                <Accordion.Body className="bg-primary px-3 py-0">
                                    <Sidebar.CollapseMenuItem to={imagePath}>
                                        <FaImage /> <span>Images</span>
                                    </Sidebar.CollapseMenuItem>
                                    <Sidebar.CollapseMenuItem to={videoPath}>
                                        <FaVideo /> <span>Videos</span>
                                    </Sidebar.CollapseMenuItem>
                                    <HasGroupRole
                                        groupId={org}
                                        role={GroupRole.LOCATION_ADMIN}
                                    >
                                        <Sidebar.CollapseMenuItem to={fontPath}>
                                            <FaFont /> <span>Fonts</span>
                                        </Sidebar.CollapseMenuItem>
                                    </HasGroupRole>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Sidebar.MenuItem to={`${match.url}/devices`}>
                                <FaLaptop /> <span>Devices</span>
                            </Sidebar.MenuItem>
                        </Accordion>
                        <ShowIf
                            condition={
                                isReseller || isOrganizationAdmin || isAdmin
                            }
                        >
                            <Sidebar.MenuHeader>Admin</Sidebar.MenuHeader>

                            <HasRole role={Role.ADMIN}>
                                <Sidebar.MenuItem to={userPath}>
                                    <FaUser /> <span>Users</span>
                                </Sidebar.MenuItem>
                            </HasRole>
                            <Sidebar.MenuItem to={groupPath}>
                                <FaUsers /> <span>Groups</span>
                            </Sidebar.MenuItem>
                            <HasRole role={Role.ADMIN}>
                                <Sidebar.MenuItem to={newsPath}>
                                    <FaNewspaper /> <span>News</span>
                                </Sidebar.MenuItem>
                            </HasRole>
                        </ShowIf>

                        <IsSysAdmin>
                            <Sidebar.MenuHeader>
                                System Admin
                            </Sidebar.MenuHeader>
                            <Sidebar.MenuItem to={tenantProfilePath}>
                                <FaHouseUser /> <span>Tenant Profiles</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={keycloakRealmPath}>
                                <FaGlobe /> <span>Keycloak Realms</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={playerAdminPage}>
                                <FaLaptopCode /> <span>Player Admin</span>
                            </Sidebar.MenuItem>
                            <Sidebar.MenuItem to={wireguardPeerAdminPage}>
                                <FaNetworkWired />{" "}
                                <span>WireGuard Peer Entries</span>
                            </Sidebar.MenuItem>
                        </IsSysAdmin>
                        {plugins.pages?.length > 0 && (
                            <>
                                <Sidebar.MenuHeader>Plugins</Sidebar.MenuHeader>
                                {plugins.pages.map((page) => {
                                    return (
                                        <ShowIf
                                            key={page.key}
                                            condition={page.accessControl(
                                                auth.getRoles(),
                                                auth.organizationContext,
                                                findGroupInTree(
                                                    auth.organizationContext,
                                                    auth.getGroups()
                                                ),
                                                auth.getFlatGroups(),
                                                auth.getGroups
                                            )}
                                        >
                                            <Sidebar.MenuItem
                                                to={`${rootPath}/${page.key}${page.path}`}
                                            >
                                                <page.menuIcon />{" "}
                                                <span>{page.menuTitle}</span>
                                            </Sidebar.MenuItem>
                                        </ShowIf>
                                    );
                                })}
                            </>
                        )}
                    </Nav>
                </nav>
            </Sidebar>

            <div className={styles.notHeader}>
                <Header
                    onMenuToggleClick={(): void => toggle(!sidebarToggled)}
                />

                <div className={styles.pageContentWrapper}>
                    <div className={styles.content}>
                        {/* The components will be rendered here because they're sub-components of the <Switch> in the index.tsx */}
                        <Route
                            path={`${match.url}`}
                            exact
                            component={HomePage}
                        />
                        <Route
                            path={`${match.url}/home`}
                            component={HomePage}
                        />
                        <Route
                            path={`${match.url}/scenes`}
                            exact
                            component={SceneOverviewPage}
                        />
                        <Route
                            path={`${match.url}/scenes/:scene`}
                            exact
                            component={SceneEditorPage}
                        />
                        <Route
                            path={`${match.url}/channels`}
                            exact
                            component={ChannelListPage}
                        />
                        <Route
                            path={`${match.url}/channels/:channel`}
                            exact
                            component={ChannelEditor}
                        />
                        <Route
                            path={`${match.url}/devices`}
                            component={DeviceRouter}
                        />
                        <Route
                            path={schedulePath}
                            exact
                            component={SchedulePage}
                        />
                        <Route
                            path={`${schedulePath}/:scheduleId`}
                            exact
                            component={ScheduleDetailPage}
                        />
                        <Route path={imagePath} component={ImagePage} />
                        <Route path={videoPath} component={VideoPage} />
                        <Route path={fontPath} component={FontRouter} />
                        <Route
                            path={playerAdminPage}
                            exact
                            component={PlayerAdminPage}
                        />
                        <Route path={`${userPath}`} component={UserRouter} />
                        <Route path={`${newsPath}`} component={NewsRouter} />
                        <Route
                            path={`${profilePath}`}
                            component={UserProfileRouter}
                        />
                        <Route
                            path={`${tenantProfilePath}`}
                            component={TenantProfileRouter}
                        />
                        <Route
                            path={`${keycloakRealmPath}`}
                            component={KeycloakRealmRouter}
                        />
                        <Route
                            path={`${wireguardPeerAdminPage}`}
                            component={WireguardPeerRouter}
                        />
                        <Route path={`${groupPath}`} component={GroupRouter} />
                        {plugins.pages?.map((page) => {
                            return (
                                <Route
                                    path={`${rootPath}/${page.key}${page.path}`}
                                    key={page.key}
                                    render={(props) => (
                                        <PluginPageComponent
                                            page={page}
                                            {...props}
                                        />
                                    )}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <ToastContainer
                autoClose={DEFAULT_TOAST_DURATION}
                position={toast.POSITION.BOTTOM_CENTER}
                className={styles.toast}
            />
        </>
    );
}

export default AdminRouter;
