import { Route, RouteComponentProps, Switch } from "react-router";
import React, { ReactElement } from "react";
import { FontCreatePage } from "font/FontCreatePage";
import { FontPage } from "font/FontPage";

export function FontRouter({
    match,
}: RouteComponentProps): ReactElement<RouteComponentProps> {
    return (
        <Switch>
            <Route
                path={`${match.url}/create`}
                exact
                component={FontCreatePage}
            />
            <Route path={`${match.url}`} component={FontPage} />
        </Switch>
    );
}
