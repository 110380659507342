import { Group } from "model/Group";
import React, { ReactElement } from "react";
import { GroupListItem } from "group/list/GroupListItem";
import styles from "./GroupList.scss";
import { classNames } from "@castia/sdk";

interface GroupListProps {
    groups: Group[];
    showHeader?: boolean;
    depth: number;
}

export function GroupList(props: GroupListProps): ReactElement {
    return (
        <div
            className={classNames(
                styles.grid,
                props.depth === 0 ? "p-2 pb-0" : ""
            )}
        >
            {props.showHeader && (
                <>
                    <span className={styles.cell}>
                        <strong>Name</strong>
                    </span>
                    <span className={styles.cell}></span>
                </>
            )}
            <>
                {props.groups.map((group, index) => {
                    return (
                        <GroupListItem
                            key={index}
                            group={group}
                            depth={props.depth}
                        />
                    );
                })}
            </>
        </div>
    );
}
