import React, { ReactElement } from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { WireGuardPeerEntryListContainer } from "wireguard-peer/list/WireGuardPeerEntryListContainer";

export function WireGuardPeerListPage(): ReactElement {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>WireGuard Peers</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>

                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <WireGuardPeerEntryListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}
