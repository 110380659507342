import { ReactElement } from "react";
import { FormStepProps } from "core/components/Form/FormStepProps";
import { SceneCreateFormType } from "scene/ScenesOverviewPage/components/SceneCreator/SceneCreateModal";
import React from "react";
import { SceneLayoutForm } from "scene/ScenesOverviewPage/components/SceneLayoutForm";

interface SceneCreateFormStep2Props extends FormStepProps<SceneCreateFormType> {
    template: string;
}

export function SceneCreateFormStep2(
    props: SceneCreateFormStep2Props
): ReactElement {
    return (
        <SceneLayoutForm
            onSubmit={props.onSubmit}
            secondaryButtonAction={props.previous}
            secondaryButtonText="Previous"
            template={props.template}
        />
    );
}
