import React, { ReactElement, useEffect } from "react";
import styles from "core/components/Header/PublishButton/PublishButton.scss";
import Button from "core/components/UI/Button/Button";
import usePublishChanges from "hooks/api/device/usePublishChanges";
import { toast } from "react-toastify";

/**
 * Button to push changes to all devices
 */
function PublishButton(): ReactElement {
    const { sendRequest, isLoading, response } = usePublishChanges();

    useEffect((): void => {
        if (response && !isLoading) {
            toast.info("The latest changes were published to all devices!");
        }
    }, [isLoading]);

    async function push(): Promise<void> {
        await sendRequest();
    }

    return (
        <>
            <Button
                variant="primary"
                className={styles.pushButton}
                onClick={push}
            >
                Publish
            </Button>
        </>
    );
}

export default PublishButton;
