import useFetchChannel from "hooks/api/channel/useFetchChannel";
import React, { ReactElement } from "react";
import { ResponseErrorHandler } from "@castia/sdk";
import { channelsPath } from "core/util/routes";
import { Link } from "react-router-dom";

interface ChannelFieldProps {
    channelId: string;
}

export function ChannelField(props: ChannelFieldProps): ReactElement {
    const { response, isLoading, error } = useFetchChannel(props.channelId);

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            <Link to={`${channelsPath}/${props.channelId}`}>
                {response?.title}
            </Link>
        </ResponseErrorHandler>
    );
}
