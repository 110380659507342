import React, { ReactElement } from "react";
import Loader from "core/components/UI/Loader/Loader";
import { ApiError } from "hooks/api/ApiError";
import Error from "core/components/UI/Error/Error";

interface ResponseErrorHandlerProps {
    error?: ApiError;
    isLoading: boolean;
    retryAction?: () => void;
}

export default function ResponseErrorHandler(
    props: React.PropsWithChildren<ResponseErrorHandlerProps>
): ReactElement {
    if (props.isLoading) {
        return <Loader />;
    }

    if (props.error) {
        switch (props.error.status) {
            case 404:
                return (
                    <Error retryAction={props.retryAction}>
                        The requested resource could not be found!
                    </Error>
                );
            case 500:
                return (
                    <Error retryAction={props.retryAction}>
                        Something went wrong while retrieving the data! Please
                        try again later.
                    </Error>
                );
        }
    }
    if (props.error) {
        return (
            <Error retryAction={props.retryAction}>
                Something went wrong while retrieving the data! Please try again
                later. ({props.error.message})
            </Error>
        );
    }

    return <>{props.children}</>;
}
