import { Link } from "react-router-dom";
import React, { PropsWithChildren } from "react";

interface ListGroupItemLinkProps {
    to: string;
}

/**
 * Custom version of the react-bootstrap ListGroup.Item with href. This version uses a react-router-dom link instead of
 * a plain <a> tag. This ensures proper routing without page reloads.
 * @param props
 * @constructor
 */
export function ListGroupItemLink(
    props: PropsWithChildren<ListGroupItemLinkProps>
): React.ReactElement {
    return (
        <Link className="list-group-item list-group-item-action" to={props.to}>
            {props.children}
        </Link>
    );
}
