import React, { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";

function PageLayoutBody(props: React.PropsWithChildren<unknown>): ReactElement {
    return (
        <Row>
            <Col>{props.children}</Col>
        </Row>
    );
}

export default PageLayoutBody;
