import { userPageSize } from "user/hooks/useFetchAllUsers";
import { Paging, ResponseErrorHandler } from "@castia/sdk";
import styles from "user/list/UserListContainer.scss";
import React from "react";
import { useFetchAllWireGuardPeerEntries } from "wireguard-peer/hooks/useFetchAllWireGuardPeerEntries";
import { WireGuardPeerEntryList } from "wireguard-peer/list/WireGuardPeerEntryList";

export function WireGuardPeerEntryListContainer() {
    const { response, isLoading, error, refreshData } = useFetchAllWireGuardPeerEntries();

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            {response && <WireGuardPeerEntryList wireGuardPeerEntries={response.items} />}
            <div className={styles.pagingContainer}>
                <Paging
                    currentPage={response?.meta.currentPage}
                    onClick={(page): void => {
                        refreshData(
                            new URLSearchParams({
                                page: String(page),
                                limit: String(userPageSize),
                            })
                        );
                    }}
                    totalPages={response?.meta.totalPages}
                    showMax={10}
                />
            </div>
        </ResponseErrorHandler>
    );
}
