import React from "react";
import { ReactElement } from "react";
import Modal from "core/components/UI/Modal/Modal";
import { ChannelEditForm } from "channel/edit/ChannelEditForm";
import Channel from "model/Channel";

interface ChannelEditModalProps {
    show: boolean;
    onDone: () => void;
    channel: Channel;
}

export function ChannelEditModal(props: ChannelEditModalProps): ReactElement {
    return (
        <Modal
            show={props.show}
            onHide={props.onDone}
            size="lg"
            title="Edit channel"
        >
            <ChannelEditForm channel={props.channel} onDone={props.onDone} />
        </Modal>
    );
}
