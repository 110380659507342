import React, { ReactElement, useEffect, useState } from "react";
import useFetchAllImages, {
    imagePageSize,
} from "hooks/api/media/image/useFetchAllImages";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import ImageOverview from "image/list/ImageOverview";
import Image from "model/Image";
import { Paging } from "@castia/sdk";

function ImageLibrary(): ReactElement {
    const [images, setImages] = useState<Image[]>(null);

    const { response, isLoading, error, refreshData } = useFetchAllImages();

    // Set the images in the state. This ensures that reloads caused by newly uploaded images don't cause a flashing
    // effect caused by the ResponseErrorHandler component.
    useEffect((): void => {
        if (response) {
            setImages(response.items);
        }
    }, [response]);

    if (images) {
        return (
            <div>
                <ImageOverview images={images} />
                <div className="d-flex justify-content-end">
                    <Paging
                        currentPage={response.meta.currentPage}
                        onClick={(page): void => {
                            refreshData(
                                new URLSearchParams({
                                    page: String(page),
                                    limit: String(imagePageSize),
                                })
                            );
                        }}
                        totalPages={response.meta.totalPages}
                        showMax={10}
                    />
                </div>
            </div>
        );
    }

    return <ResponseErrorHandler isLoading={isLoading} error={error} />;
}

export default ImageLibrary;
