import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTemplatePreviewModes } from "hooks/useTemplatePreviewModes";
import { TemplateSettings } from "@castia/sdk";

interface ScenePreviewModeSelectorProps {
    templateIdentifier: string;
    templateLayoutId?: string;
    settings?: TemplateSettings;
    onModeChange: (selectedPreviewModeId: string) => void;
}

export function ScenePreviewModeSelector(props: ScenePreviewModeSelectorProps) {
    const previewModes = useTemplatePreviewModes(
        props.templateIdentifier,
        props.templateLayoutId,
        props.settings
    );
    const [selectedPreviewMode, setSelectedPreviewMode] = useState<string>(
        previewModes?.[0]?.id
    );

    useEffect(() => {
        if (selectedPreviewMode) {
            props.onModeChange(selectedPreviewMode);
        } else {
            // Explicitly set to null to ensure a value other than undefined is set.
            props.onModeChange(null);
        }
    }, [selectedPreviewMode]);

    useEffect(() => {
        // If the preview items change, set the first one as default again.
        setSelectedPreviewMode(previewModes?.[0]?.id);
    }, [JSON.stringify([...(previewModes?.map((pm) => pm.id) || [])])]);

    if (!previewModes) {
        return <></>;
    }

    return (
        <div className="d-flex">
            <label className="d-flex align-self-center text-nowrap text-center me-2">
                Preview mode:
            </label>
            <Form.Select
                className="me-4"
                onChange={(event) => {
                    setSelectedPreviewMode(event.target.value);
                }}
                value={selectedPreviewMode}
            >
                {previewModes.map((previewMode) => {
                    return (
                        <option key={previewMode.id} value={previewMode.id}>
                            {previewMode.displayName}
                        </option>
                    );
                })}
            </Form.Select>
        </div>
    );
}
