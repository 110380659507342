import * as React from "react";
import ChannelListContainer from "channel/list/ChannelListContainer";
import { ReactElement } from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import ChannelCreateButton from "channel/list/ChannelList/ChannelCreator/ChannelCreateButton";
import { FolderType } from "model/FolderType";
import LocalEvents from "events/LocalEvents";
import { FolderCreateButton } from "core/folder/FolderCreateButton/FolderCreateButton";
import { HasGroupRole } from "core/auth/HasGroupRole";
import useAuthentication from "hooks/useAuthentication";
import { GroupRole } from "core/auth/GroupRole";
import { ButtonGroup } from "react-bootstrap";

function ChannelListPage(): ReactElement {
    const auth = useAuthentication();
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Channels</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <HasGroupRole
                        groupId={auth.organizationContext}
                        role={GroupRole.EDITOR}
                    >
                        <ButtonGroup>
                            <FolderCreateButton
                                type={FolderType.CHANNEL}
                                successEvent={LocalEvents.CHANNELS_CHANGED}
                            />
                            <ChannelCreateButton />
                        </ButtonGroup>
                    </HasGroupRole>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <ChannelListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}

export default ChannelListPage;
