import useFetch, { FetchData } from "hooks/useFetch";
import Device from "model/Device";
import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";

/**
 * Call to fetch a device and its content
 * @param deviceId
 */
function useFetchOneDevice(deviceId: string): FetchData<Device> {
    const fetch = useFetch<Device>(
        `${environment.apiBaseUrl}/api/v1/devices/${deviceId}`
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.DEVICES_CHANGED, eventCallback);

    return fetch;

}

export default useFetchOneDevice;
