import FullCalendar from "@fullcalendar/react";
import rrulePlugin from "@fullcalendar/rrule";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import nlLocale from "@fullcalendar/core/locales/nl";
import React, { useEffect, useRef, useState } from "react";
import { useHasGroupRole } from "core/auth/useHasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { FullCalendarRepeatingEvent } from "schedule/schedule-event/model/FullCalendarRepeatingEvent";
import { EventCreateModal } from "schedule/schedule-event/components/EventCreateModal";
import {
    DateSelectArg,
    EventApi,
    EventClickArg,
    EventContentArg,
} from "@fullcalendar/common";
import { useParams } from "react-router-dom";
import { Schedule } from "model/Schedule";
import { addMilliseconds, addYears, isAfter } from "date-fns";
import { ScheduleEvent } from "model/ScheduleEvent";
import { EventUpdateModal } from "schedule/schedule-event/components/EventUpdateModal";
import { EventInput } from "@fullcalendar/core";
import { EventFormType } from "schedule/schedule-event/components/EventForm";
import { getCollisions } from "schedule/schedule-event/validator/event-overlapping-validator";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import isBefore from "date-fns/isBefore";
import { FullCalendarEvent } from "schedule/schedule-event/model/FullCalendarEvent";
import { formatInTimeZone } from "date-fns-tz";
import dayGridPlugin from "@fullcalendar/daygrid";

interface FullScheduleCalendarProps {
    schedule: Schedule;
}

interface RouteInfo {
    scheduleId: string;
}

export interface EventValidationResult {
    error?: string;
    warning?: string;
}

function getEventColor(frequency?: number) {
    switch (frequency) {
        case 0:
            return "var(--bs-danger)";
        case 1:
            return "var(--bs-warning)";
        case 2:
            return "var(--bs-info)";
        case 3:
            return "var(--bs-purple)";
        default:
            return "var(--bs-primary)";
    }
}

function getFrequencyName(frequency: number) {
    switch (frequency) {
        case 0:
            return "year";
        case 1:
            return "month";
        case 2:
            return "week";
        case 3:
            return "day";
        default:
            return "unknown";
    }
}

/**
 * Convert the scheduleEvent to a TimetableEvent.
 * @param scheduleEvent
 */
function scheduleEventToCalendarEvent(
    scheduleEvent: ScheduleEvent
): FullCalendarRepeatingEvent | FullCalendarEvent {
    if (scheduleEvent.repeating) {
        return {
            id: scheduleEvent.id,
            title:
                scheduleEvent.channel?.title +
                `<br><i>Every ${
                    scheduleEvent.interval > 1 ? scheduleEvent.interval : ""
                } ${getFrequencyName(scheduleEvent.frequency)}${
                    scheduleEvent.interval > 1 ? "s" : ""
                }</i>`,
            duration: {
                milliseconds: scheduleEvent.duration,
            },
            rrule: scheduleEvent.rrule,
            extendedProps: {
                channelId: scheduleEvent.channel?.id,
                frequency: scheduleEvent.frequency,
                interval: scheduleEvent.interval,
            },
            color: getEventColor(scheduleEvent.frequency),
            channel: scheduleEvent.channel,
        };
    }

    return {
        id: scheduleEvent.id,
        title: scheduleEvent.channel?.title,
        start: new Date(scheduleEvent.startDateTime),
        end: addMilliseconds(
            new Date(scheduleEvent.startDateTime),
            scheduleEvent.duration
        ),
        color: getEventColor(),
        channel: scheduleEvent.channel,
    };
}

export function FullScheduleCalendar(props: FullScheduleCalendarProps) {
    const calendarRef = useRef<FullCalendar>(null);

    const { scheduleId } = useParams<RouteInfo>();
    const [events, setEvents] =
        useState<(FullCalendarRepeatingEvent | FullCalendarEvent)[]>();
    const organizationContext = useOrganizationContext();
    const isEditor = useHasGroupRole(organizationContext, GroupRole.EDITOR);
    const [selection, setSelection] = useState<DateSelectArg | null>(null);
    const [clickedEvent, setClickedEvent] = useState<EventApi | null>(null);

    useEffect(() => {
        const propsEvents = props.schedule.scheduleEvents.map(
            scheduleEventToCalendarEvent
        );
        setEvents(propsEvents);
    }, [props.schedule]);

    useEventListener(LocalEvents.SCHEDULE_EVENT_DELETED, (deletedEvent) => {
        closeUpdateModal();
        removeEventFromCalendar(deletedEvent.eventId);
    });

    function handleSelection(arg: DateSelectArg) {
        setSelection(arg);
    }

    function closeCreateModal(): void {
        setSelection(null);
    }

    function handleEventClick(info: EventClickArg) {
        if (isEditor) {
            setClickedEvent(info.event);
        }
    }

    function closeUpdateModal(): void {
        setClickedEvent(null);
    }

    function addEventToCalendar(createdEvent: ScheduleEvent) {
        if (createdEvent) {
            setEvents((currentEvents: FullCalendarRepeatingEvent[]) => [
                ...currentEvents,
                scheduleEventToCalendarEvent(createdEvent),
            ]);
        }
    }

    function removeEventFromCalendar(deletedEventId: string) {
        setEvents((currentEvents: FullCalendarRepeatingEvent[]) =>
            currentEvents.filter((event) => event.id !== deletedEventId)
        );
    }

    function updateEventInCalendar(updatedEvent: ScheduleEvent) {
        const items = [...events];
        const itemIndex = events
            .map((event) => event.id)
            .indexOf(updatedEvent.id);
        items[itemIndex] = scheduleEventToCalendarEvent(updatedEvent);

        setEvents(items);
    }

    function validateEvent(
        newEvent: EventFormType,
        existingEventId?: string
    ): EventValidationResult {
        const startDateTime = new Date(
            newEvent.startDate + "T" + newEvent.startTime + "Z"
        );
        const endDateTime = new Date(
            newEvent.endDate + "T" + newEvent.endTime + "Z"
        );
        if (
            isBefore(endDateTime, startDateTime) ||
            startDateTime.getTime() === endDateTime.getTime()
        ) {
            return {
                error: "The end date and time must be after the start date and time",
            };
        }

        if (isAfter(startDateTime, addYears(new Date(Date.now()), 2))) {
            return {
                error: "The event is too far in the future. Please make an event that starts within 2 years from now",
            };
        }

        if (
            isBefore(startDateTime, new Date(Date.now())) &&
            !newEvent.repeating
        ) {
            return {
                error: "You cannot create an event in the past.",
            };
        }

        const collisions = getCollisions(events, newEvent, existingEventId);
        if (collisions.blocking?.length > 0) {
            const start = formatInTimeZone(
                collisions.blocking[0].start,
                "UTC",
                "EEEE dd-MM-yyyy HH:mm"
            );
            const end = formatInTimeZone(
                collisions.blocking[0].end,
                "UTC",
                "EEEE dd-MM-yyyy HH:mm"
            );
            const channel = collisions.blocking[0].channelTitle;
            return {
                error: `The event you are trying to create or edit collides with one or more other events. Please resolve the issue and try again. The first collision is with the event from ${start} till ${end} showing channel '${channel}'`,
            };
        }
        if (collisions.soft?.length > 0) {
            return {
                warning: `The event you are adding or editing overlaps with another event with a different frequency. Beware that the event might not be displayed as expected. You can either resolve the issue or continue and the overlap will be resolved according to the priority rules. You can continue to save the event or go back to resolve the issue manually.`,
            };
        }

        return {};
    }

    const eventContent = (info: EventContentArg) => {
        return (
            <>
                <b>{info.timeText}</b>
                <p dangerouslySetInnerHTML={{ __html: info.event.title }} />
            </>
        );
    };

    return (
        <>
            <FullCalendar
                ref={calendarRef}
                timeZone="UTC"
                plugins={[
                    rrulePlugin as any,
                    timeGridPlugin,
                    interactionPlugin,
                    dayGridPlugin,
                ]}
                initialView="timeGridWeek"
                eventTimeFormat={{
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                }}
                slotLabelFormat={{
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                }}
                locale={nlLocale}
                events={events}
                dayHeaderFormat={{
                    weekday: "long",
                    month: "short",
                    day: "numeric",
                }}
                headerToolbar={{
                    right: "today prev,next",
                }}
                editable={false}
                eventStartEditable
                eventResizableFromStart
                height="auto"
                aspectRatio={1.35}
                eventColor="var(--bs-primary)"
                selectable={isEditor}
                selectMirror
                eventOverlap={true}
                selectOverlap={true}
                allDaySlot={false}
                slotDuration="00:15:00"
                eventAllow={() => false}
                eventClick={handleEventClick}
                select={handleSelection}
                eventContent={eventContent}
                eventOrder={(a: EventInput, b: EventInput) => {
                    const eventA = events.find((e) => e.id === a.id);
                    const eventB = events.find((e) => e.id === b.id);

                    if ("rrule" in eventA && "rrule" in eventB) {
                        if (
                            eventA.extendedProps.frequency >
                            eventB.extendedProps.frequency
                        ) {
                            return 1;
                        } else if (
                            eventA.extendedProps.frequency ===
                            eventB.extendedProps.frequency
                        ) {
                            if (
                                eventA.extendedProps.interval <
                                eventB.extendedProps.interval
                            ) {
                                return 1;
                            } else if (
                                eventA.extendedProps.interval ===
                                eventB.extendedProps.interval
                            ) {
                                return 0;
                            }
                            return -1;
                        }
                        return -1;
                    }
                    if ("rrule" in eventA) {
                        return 1;
                    } else if ("rrule" in eventB) {
                        return -1;
                    }

                    return 0;
                }}
                eventOrderStrict={true}
            />
            <EventCreateModal
                show={!!selection}
                selection={selection}
                closeModal={closeCreateModal}
                scheduleId={scheduleId}
                addEventToCalendar={addEventToCalendar}
                validateEvent={validateEvent}
            />
            <EventUpdateModal
                show={!!clickedEvent}
                closeModal={closeUpdateModal}
                scheduleId={scheduleId}
                updateEventInCalendar={updateEventInCalendar}
                scheduleEventId={clickedEvent?.id}
                validateEvent={validateEvent}
            />
        </>
    );
}
