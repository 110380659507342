import { InputProps } from "core/components/input/InputProps";
import { useFormContext } from "react-hook-form";
import { Form } from "react-bootstrap";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import React from "react";

export function FileInput(props: InputProps) {
    const form = useFormContext();
    const { errors } = form.formState;

    return (
        <div className={props.className ? props.className : "mb-3"}>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            <Form.Control
                name={props.name}
                placeholder={props.label}
                {...form.register(props.name, props.registerOptions)}
                type="file"
                multiple={false}
            />
            {errors[props.name] && (
                <FormInputError>{errors[props.name].message}</FormInputError>
            )}
        </div>
    );
}
