import { DeviceInfo } from "model/DeviceInfo";
import React, { ReactElement } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { format } from "date-fns";
import styles from "./PlayerInfoCard.scss";

interface PlayerInfoCardProps {
    deviceInfo: DeviceInfo;
}

export function PlayerInfoCard({
    deviceInfo,
}: PlayerInfoCardProps): ReactElement {
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    Player info{" "}
                    <em className={styles.small}>
                        (Updated at{" "}
                        {format(
                            new Date(deviceInfo.updatedAt),
                            "dd-LL-yyyy HH:mm:ss"
                        )}
                        )
                    </em>
                </Card.Title>
                <Row>
                    <Col md={4} className="mb-3">
                        <strong>Player version</strong>
                        <div>{deviceInfo.playerVersion}</div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <strong>Player started at</strong>
                        <div>
                            {format(
                                new Date(deviceInfo.playerStartTime),
                                "dd-LL-yyyy HH:mm:ss"
                            )}
                        </div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <strong>Last content retrieval</strong>
                        <div>
                            {format(
                                new Date(deviceInfo.lastContentRetrievalTime),
                                "dd-LL-yyyy HH:mm:ss"
                            )}
                        </div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <strong>Browser name</strong>
                        <div>{deviceInfo.browserName}</div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <strong>Browser version</strong>
                        <div>{deviceInfo.browserVersion}</div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <strong>IP Address</strong>
                        <div>{deviceInfo.ipAddress}</div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <strong>Screen resolution</strong>
                        <div>{deviceInfo.screenResolution}</div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}
