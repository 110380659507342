import React from "react";
import styles from "./LogoPreviewField.scss";

interface FaviconPreviewFieldProps {
    faviconUrl: string;
}

export function FaviconPreviewField(props: FaviconPreviewFieldProps) {
    return (
        <img
            src={props.faviconUrl}
            alt="Favicon preview"
            className={styles.logo}
        />
    );
}
