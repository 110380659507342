import { FetchData } from "hooks/useFetch";
import { Paginated, useEventListener } from "@castia/sdk";
import { FolderListItem } from "model/FolderListItem";
import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";

const schedulePageSize = 10;

export function useFetchAllSchedulesPaginated(folderId?: string): FetchData<Paginated<FolderListItem>> {
    const fetch = useFetchPaginated<FolderListItem>(
        `${environment.apiBaseUrl}/api/v2/schedules`,
        schedulePageSize,
        1,
        folderId ? {
            folderId: folderId,
        }: undefined
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.SCHEDULES_CHANGED, eventCallback);

    return fetch;
}
