import React, { ReactElement } from "react";
import { UploadProgressItem } from "core/components/UploadContext/UploadProgressItem";
import { UploadingItem } from "hooks/upload/useUploadProgress";

interface UploadProgressListProps {
    items: UploadingItem[]
}

export function UploadProgressList(props: UploadProgressListProps): ReactElement {
    return (
        <>
            <div>
                {props.items.map((item) => (
                    <UploadProgressItem key={item.id} item={item} />
                ))}
            </div>
        </>
    );
}
