import { Editor } from "react-draft-wysiwyg";
import React, { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import Error from "core/components/UI/Error/Error";

interface RichTextInputProps {
    displayName: string;
    name: string;
}

export function RichTextInput(props: RichTextInputProps) {
    const { control } = useFormContext();
    const {
        field,
        fieldState: { error },
    } = useController({
        name: props.name,
        control: control,
    });
    const [editorState, setEditorState] = useState<EditorState>(
        field.value
            ? EditorState.createWithContent(convertFromRaw(field.value))
            : EditorState.createEmpty()
    );

    function onEditorStateChange(newEditorState: any) {
        setEditorState(newEditorState);
        field.onChange(convertToRaw(newEditorState.getCurrentContent()));
    }

    return (
        <>
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                onBlur={field.onBlur}
                editorStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    // borderColor: error ? theme.palette.error.main : theme.palette.grey.A400,
                    minHeight: "200px",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    borderRadius: "0.5rem",
                }}
                toolbar={{
                    options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "remove",
                        "history",
                        "link",
                    ],
                }}
                placeholder={props.displayName}
            />
            {error?.message && <Error>{error.message}</Error>}
        </>
    );
}
