import * as React from "react";
import { FunctionComponent } from "react";
import { ReactElement } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import TemplatePage from "../../pages/TemplatePage/TemplatePage";
import AuthContextProvider from "services/auth/AuthContextProvider";
import PluginContextProvider from "core/components/Plugin/PluginContext/PluginContextProvider";

const TemplateRouter: FunctionComponent<RouteComponentProps> = function ({
    match,
}: RouteComponentProps): ReactElement {
    return (
        <div>
            <main role="main">
                <Switch>
                    <AuthContextProvider>
                        <PluginContextProvider>
                            <Route
                                path={`${match.url}`}
                                exact
                                component={TemplatePage}
                            />
                        </PluginContextProvider>
                    </AuthContextProvider>
                </Switch>
            </main>
        </div>
    );
};

export default TemplateRouter;
