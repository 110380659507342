import React, { PropsWithChildren, ReactElement, useState } from "react";
import styles from "core/components/Form/Wizard/Wizard.scss";
import Button from "core/components/UI/Button/Button";
import { classNames } from "@castia/sdk";
import { useForm, FormProvider, UseFormProps } from "react-hook-form";

interface WizardProps<T> {
    onSubmit: (values: T) => void;
    initialValues: T;
}

export function Wizard<T>(props: PropsWithChildren<WizardProps<T>>): ReactElement {
    const [page, setPage] = useState(0);
    const [values, setValues] = useState<T>(props.initialValues);
    const form = useForm<UseFormProps<T>>({
        defaultValues: props.initialValues,
    });

    function next(newValues: T): void {
        setValues(newValues);
        setPage((currentPage) => Math.min(currentPage + 1, React.Children.count(props.children) - 1));
    }

    function previous(): void {
        setPage((currentPage) => Math.max(currentPage - 1, 0));
    }

    function handleSubmit(newValues: T) {
        const isLastPage = page === (React.Children.count(props.children) - 1);
        if (isLastPage) {
            return props.onSubmit(newValues);
        }
        next(values);
    }

    const activePage = React.Children.toArray(props.children)[page];
    const isLastPage = page === React.Children.count(props.children) - 1;
    const isFirstPage = page === 0;
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit as never)}>
                <div className={styles.wizard}>
                    {activePage}
                    <div className={styles.buttons}>
                        {!isFirstPage && (
                            <Button
                                className={styles.previous}
                                type="button"
                                onClick={previous}
                            >
                                « Previous
                            </Button>
                        )}
                        {!isLastPage && (
                            <Button
                                className={classNames(
                                    styles.next,
                                    "float-right"
                                )}
                                type="submit"
                            >
                                Next »
                            </Button>
                        )}
                        {isLastPage && (
                            <Button
                                className={classNames(
                                    styles.submit,
                                    "float-right"
                                )}
                                type="submit"
                                // disabled={isSubmitting}
                            >
                                Submit
                            </Button>
                        )}
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}
