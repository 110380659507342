import { useTemplate } from "hooks/useTemplate";
import { TemplateSettings, PreviewMode } from "@castia/sdk";

export function useTemplatePreviewModes(
    templateIdentifier: string,
    templateLayoutId?: string,
    settings?: TemplateSettings
): PreviewMode[] | undefined {
    const template = useTemplate(templateIdentifier);

    const previewModes =
        typeof template.previewModes === "function"
            ? template.previewModes(templateLayoutId, settings)
            : template.previewModes;

    if (!previewModes || previewModes.length === 0) {
        return undefined;
    }

    return previewModes;
}
