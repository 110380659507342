import React, { ReactElement, useEffect, useState } from "react";
import * as singleSpa from "single-spa";
import usePlugins from "hooks/usePlugins";
import Parcel from "single-spa-react/parcel";
import { SimpleFunction } from "core/util/types/FunctionTypes";
import { TemplateContextProps } from "@castia/sdk";

interface PluginTemplateComponentProps extends TemplateContextProps {
    template: string;
    layoutId?: string;
}

/**
 * Load a template from a plugin.
 * @param props
 * @constructor
 */
function PluginTemplateComponent(
    props: PluginTemplateComponentProps
): ReactElement {
    const { findTemplate } = usePlugins();
    const [templateToLoad, setTemplateToLoad] = useState(
        findTemplate(props.template)
    );
    const layoutToShow =
        templateToLoad.layouts.length > 1 && props.layoutId
            ? templateToLoad.layouts.find(
                  (layout) => layout.id === props.layoutId
              )
            : templateToLoad.layouts[0];

    const [reload, setReload] = useState(true);

    // This useEffect is a workaround for the fact that the Parcel does not completely rerender when the config prop
    // changes. This results in the first template always being shown.
    useEffect((): SimpleFunction => {
        setReload(true);

        const timeout = setTimeout((): void => {
            setTemplateToLoad(findTemplate(props.template));
            setReload(false);
        }, 1);

        return (): void => {
            clearTimeout(timeout);
        };
    }, [props.template]);

    if (reload) {
        return <></>;
    }

    if (!templateToLoad) {
        return <div>Could not find the requested template</div>;
    }

    return (
        <Parcel
            config={layoutToShow.component}
            wrapWith="div"
            wrapStyle={{ height: "100%" }}
            mountParcel={singleSpa.mountRootParcel}
            {...props}
        />
    );
}

export default PluginTemplateComponent;
