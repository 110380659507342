import { useHistory } from "react-router-dom";
import { fontPath } from "core/util/routes";
import React from "react";
import { useCreateFont } from "hooks/api/media/font/useCreateFont";
import { FontForm, FontFormFields } from "font/FontForm";

export function FontCreateForm() {
    const { sendRequest, isLoading } = useCreateFont();
    const history = useHistory();

    async function onSubmit(data: FontFormFields): Promise<void> {
        const files = Array.from(data.file);
        delete data.file;
        const result = await sendRequest({
            file: files[0],
            ...data,
        });

        if (result) {
            history.push(`${fontPath}`);
        }
    }

    return <FontForm handleSubmit={onSubmit} isLoading={isLoading} />;
}
