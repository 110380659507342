import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { Paging } from "@castia/sdk";
import { defaultScenePageSize } from "hooks/api/scene/useFetchAllScenesPaginated";
import React from "react";
import { useNewsItems } from "hooks/api/news/useNewsItems";
import { NewsList } from "news/list/NewsList";
import styles from "./NewsListContainer.scss";

export function NewsListContainer() {
    const { response, refreshData, isLoading, error } = useNewsItems();

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            {response && <NewsList newsItems={response.items} />}
            {response && response.items?.length === 0 && (
                <span className="mb-3">
                    There are currently no news items or updates about the
                    platform.
                </span>
            )}
            <div className={styles.pagingContainer}>
                <Paging
                    currentPage={response?.meta.currentPage}
                    onClick={(page): void => {
                        refreshData(
                            new URLSearchParams({
                                page: String(page),
                                limit: String(defaultScenePageSize),
                            })
                        );
                    }}
                    totalPages={response?.meta.totalPages}
                    showMax={10}
                />
            </div>
        </ResponseErrorHandler>
    );
}
