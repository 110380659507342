import * as React from "react";
import DeviceList from "device/list/DeviceList";
import { ReactElement } from "react";
import useFetchAllDevices from "hooks/api/device/useFetchAllDevices";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";

function DeviceListContainer(): ReactElement {
    const { isLoading, response, error } = useFetchAllDevices();

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            <DeviceList devices={response} />
        </ResponseErrorHandler>
    );
}

export default DeviceListContainer;
