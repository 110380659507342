import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";

/**
 * Set the duration of a scene in a channel.
 * @param channelId
 * @param channelSceneId
 */
function useSetDuration(
    channelId: string,
    channelSceneId: string
): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/channels/${channelId}/scenes/${channelSceneId}`,
        "PATCH"
    );
}

export default useSetDuration;
