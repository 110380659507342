import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useDeleteVideos(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/media/videos`,
        "DELETE",
        LocalEvents.VIDEOS_CHANGED
    );
}
