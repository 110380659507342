import React, { ReactElement } from "react";
import { FolderType } from "model/FolderType";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "core/components/UI/Modal/Modal";
import { FolderForm } from "core/folder/FolderForm/FolderForm";
import { FolderFormValues } from "core/folder/FolderForm/FolderFormValues";
import useCreateFolder from "hooks/api/folder/useCreateFolder";

interface FolderCreateModalProps {
    show: boolean;
    onDone: () => void;
    folderType: FolderType;
    successEvent?: string;
    parentId?: string;
}

export function FolderCreateModal(props: FolderCreateModalProps): ReactElement {
    const { sendRequest, error, reset } = useCreateFolder(
        props.successEvent
    );
    const form = useForm<FolderFormValues>();

    async function onSubmit(data: FolderFormValues): Promise<void> {
        const result = await sendRequest({ type: props.folderType, parent: props.parentId || null, ...data });
        if (result) {
            form.reset();
            reset();
            props.onDone();
        }
    }

    function handleClose(): void {
        props.onDone();
    }

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            size="lg"
            title="Create new folder"
        >
            <FormProvider {...form}>
                <FolderForm
                    error={error}
                    onSubmit={onSubmit}
                    handleClose={handleClose}
                />
            </FormProvider>
        </Modal>
    );
}
