import React, { PropsWithChildren } from "react";
import styles from "./AspectRatioBox.scss";

export function AspectRatioBox(props: PropsWithChildren<unknown>) {
    return (
        <div className={styles.aspectRatioBox}>
            <div className={styles.content}>{props.children}</div>
        </div>
    );
}
