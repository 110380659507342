import { FetchData, Paginated, useEventListener } from "@castia/sdk";
import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";
import { TenantProfile } from "model/TenantProfile";

export const tenantProfilePageSize = 10;

export function useFetchPaginatedTenantProfiles(
    urlSearchParams?: Record<string, string>
): FetchData<Paginated<TenantProfile>> {
    const fetch = useFetchPaginated<TenantProfile>(
        `${environment.apiBaseUrl}/api/v1/tenant-profiles`,
        tenantProfilePageSize,
        1,
        urlSearchParams
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.TENANT_PROFILES_CHANGED, eventCallback);

    return fetch;
}
