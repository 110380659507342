import { default as React, ReactElement } from "react";
import { Form, FormGroup } from "react-bootstrap";
import Modal from "core/components/UI/Modal/Modal";
import Button from "core/components/UI/Button/Button";
import useCreateChannel from "hooks/api/channel/useCreateChannel";
import { useForm } from "react-hook-form";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import Error from "core/components/UI/Error/Error";
import { useQuery } from "hooks/useQuery";
import { FolderIdParams } from "core/folder/FolderIdParams";

interface ChannelCreatorModalProps {
    onDone: () => void;
    show: boolean;
}

interface FormValues {
    title: string;
}

/**
 * Modal to create a new channel.
 * @param props
 * @constructor
 */
function ChannelCreateModal(props: ChannelCreatorModalProps): ReactElement {
    const { sendRequest, error, reset } = useCreateChannel();
    const form = useForm();
    const { folderId } = useQuery<FolderIdParams>();

    async function onSubmit(data: FormValues): Promise<void> {
        const result = await sendRequest({
            title: data.title,
            folder: folderId,
        });
        if (result) {
            form.reset();
            reset();
            props.onDone();
        }
    }

    function handleClose(): void {
        props.onDone();
    }

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            size="lg"
            title="Create new channel"
        >
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Body>
                    {error && (
                        <Error>
                            Something went wrong while saving the channel.
                        </Error>
                    )}
                    <FormGroup>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            {...form.register("title", {
                                required: "Title is a required field",
                            })}
                        />
                        {form.formState.errors.title && (
                            <FormInputError>
                                {form.formState.errors.title.message}
                            </FormInputError>
                        )}
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default ChannelCreateModal;
