import { useRoleLabel } from "hooks/useRoleLabel";
import { Loader } from "@castia/sdk";
import React, { useState } from "react";
import { ApiError } from "hooks/api/ApiError";
import { FaExclamationCircle } from "react-icons/fa";
import Select from "react-select";
import { useUpdateGroupUser } from "group/hooks/useUpdateGroupUser";

interface GroupRoleSelectorProps {
    userId: string;
    groupId: string;
    roles: string[];
    rolesLoading: boolean;
    rolesError?: ApiError;
    initialRoleValue: string;
}

export function GroupRoleSelector(props: GroupRoleSelectorProps) {
    const getRoleLabel = useRoleLabel();
    const updateGroupUser = useUpdateGroupUser(props.groupId, props.userId);
    const [roleValue, setRoleValue] = useState<string>(props.initialRoleValue);

    if (props.rolesLoading || updateGroupUser.isLoading) {
        return <Loader />;
    }

    if (props.rolesError) {
        return <FaExclamationCircle />;
    }

    return (
        <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            name="role"
            options={props.roles.map((role) => {
                return {
                    label: getRoleLabel(role),
                    value: role,
                };
            })}
            isMulti={false}
            value={{
                label: getRoleLabel(roleValue),
                value: roleValue,
            }}
            onChange={async (selected) => {
                const response = await updateGroupUser.sendRequest({
                    role: selected.value,
                });
                if (response) {
                    setRoleValue(selected.value);
                }
            }}
        />
    );
}
