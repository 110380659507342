import environment from "core/util/environment";
import useFetchPersist, { PersistData } from "hooks/useFetchPersist";

/**
 * Call to update scene content.
 * @param sceneId
 */
function useUpdateSceneContent(sceneId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/scenes/${sceneId}/content`,
        "PUT"
    );
}

export default useUpdateSceneContent;
