import { default as React, ReactElement } from "react";
import { Redirect } from "react-router";
import Button, { ButtonProps } from "core/components/UI/Button/Button";

interface LinkButtonProps extends ButtonProps {
    to: string;
    onClick?: () => void;
}

interface LinkButtonState {
    clicked: boolean;
}

/**
 * Bootstrap-based button with support for a 'to' property like the react-router link. This button triggers a redirect
 * when clicked.
 * The optional onClick handler is triggered before the redirect.
 */
class LinkButton extends React.Component<LinkButtonProps, LinkButtonState> {
    public constructor(props: LinkButtonProps) {
        super(props);
        this.state = {
            clicked: false,
        };
    }

    private onClick(): void {
        if (this.props.onClick) {
            this.props.onClick();
        }
        this.setState({
            clicked: true,
        });
    }

    public render(): ReactElement {
        if (this.state.clicked) {
            return <Redirect to={this.props.to} push={true} />;
        }

        const variant = this.props.variant || "secondary";

        return (
            <Button
                {...this.props}
                variant={variant}
                size={this.props.size || "sm"}
                onClick={this.onClick.bind(this)}
                className={this.props.className}
            >
                {this.props.children}
            </Button>
        );
    }
}

export default LinkButton;
