import React, { ReactElement } from "react";
import { useFetchAllUsers, userPageSize } from "user/hooks/useFetchAllUsers";
import { Paging, ResponseErrorHandler } from "@castia/sdk";
import { UserList } from "user/list/UserList";
import styles from "./UserListContainer.scss";

export function UserListContainer(): ReactElement {
    const { response, isLoading, error, refreshData } = useFetchAllUsers();

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            {response && <UserList users={response.items} />}
            <div className={styles.pagingContainer}>
                <Paging
                    currentPage={response?.meta.currentPage}
                    onClick={(page): void => {
                        refreshData(
                            new URLSearchParams({
                                page: String(page),
                                limit: String(userPageSize),
                            })
                        );
                    }}
                    totalPages={response?.meta.totalPages}
                    showMax={10}
                />
            </div>
        </ResponseErrorHandler>
    );
}
