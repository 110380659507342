import {
    ButtonGroup,
    ToggleButton,
} from "react-bootstrap";
import { FaLock, FaLockOpen } from "react-icons/fa";
import * as React from "react";
import { useTemplate } from "hooks/useTemplate";
import { useTemplateLayout } from "hooks/useTemplateLayout";

interface SceneMovableToggleProps {
    movingLocked: boolean;
    setMovingLocked: (locked: boolean) => void;
    templateIdentifier: string;
    layoutIdentifier?: string;
}

export function SceneMovableToggle(props: SceneMovableToggleProps) {
    const template = useTemplate(props.templateIdentifier);
    const templateLayout = useTemplateLayout(props.templateIdentifier, props.layoutIdentifier);

    if ((!templateLayout || !templateLayout.hasMovableWidgets) && !template?.hasMovableWidgets) {
        return <></>;
    }

    return (
        <ButtonGroup className="me-4">
            <ToggleButton
                value="0"
                id="movable-check-enabled"
                type="radio"
                variant="outline-info"
                name="movable-check"
                checked={props.movingLocked}
                onChange={() => {
                    props.setMovingLocked(true);
                }}
                title="Lock layout"
                style={{zIndex: 0}}
            >
                <FaLock />
            </ToggleButton>
            <ToggleButton
                value="1"
                id="movable-check-disabled"
                type="radio"
                variant="outline-info"
                name="movable-check"
                checked={!props.movingLocked}
                onChange={() => {
                    props.setMovingLocked(false);
                }}
                title="Unlock layout"
                style={{zIndex: 0}}
            >
                <FaLockOpen />
            </ToggleButton>
        </ButtonGroup>
    );
}
