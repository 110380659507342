import * as React from "react";
import { ReactElement, useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import ScaledScene from "scene/ScenesEditorPage/components/ScaledScene/ScaledScene";
import { Content } from "model/Content";
import styles from "scene/ScenesEditorPage/SceneEditorPage.scss";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { ButtonGroup, Container } from "react-bootstrap";
import useUpdateSceneContent from "hooks/api/scene/useUpdateSceneContent";
import DeleteSceneButton from "scene/ScenesEditorPage/components/DeleteSceneButton/DeleteSceneButton";
import SceneSettingsButton from "scene/ScenesEditorPage/components/SceneSettingsButton/SceneSettingsButton";
import SettingsSidebar from "scene/ScenesEditorPage/components/SettingsSidebar/SettingsSidebar";
import useFetchOneScene from "hooks/api/scene/useFetchOneScene";
import TemplateContainer from "core/components/TemplateContainer/TemplateContainer";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { scenesRootPath } from "core/util/routes";
import Loader from "core/components/UI/Loader/Loader";
import { SceneLayoutButton } from "scene/ScenesEditorPage/components/SceneLayoutButton";
import { usePatchSceneSettings } from "hooks/api/scene/usePatchSceneSettings";
import { expandObject } from "@castia/sdk";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { deepMerge } from "core/util/deepMerge";
import { debounce } from "core/util/debounce";
import { SceneMovableToggle } from "scene/ScenesEditorPage/components/SceneMovableToggle";
import { ScenePreviewModeSelector } from "scene/ScenesEditorPage/components/ScenePreviewModeSelector";

interface RouteInfo {
    scene: string;
}

const settingsSaveTimeout = 500;

/**
 * Page on which a scene can be edited.
 * @constructor
 */
function SceneEditorPage(): ReactElement {
    const { scene } = useParams<RouteInfo>();
    const { response, isLoading, error } = useFetchOneScene(scene);
    const patchSettings = usePatchSceneSettings(scene);
    const { sendRequest } = useUpdateSceneContent(scene);
    const [toggled, setToggled] = useState(true);
    const organizationContext = useOrganizationContext();
    const [movingLocked, setMovingLocked] = useState(true);
    const [previewMode, setPreviewMode] = useState<string | undefined | null>();

    const updatedSettings = useRef(null);

    const debouncedUpdate = useCallback(
        debounce(() => {
            const updatedSettingsRequest = updatedSettings.current;
            updatedSettings.current = null;
            patchSettings.sendRequest({
                custom: updatedSettingsRequest,
            });
        }, settingsSaveTimeout),
        []
    );

    const handleSettingsChange = useCallback(
        (value: string, key: string) => {
            if (updatedSettings.current) {
                updatedSettings.current = deepMerge(
                    updatedSettings.current,
                    expandObject({
                        [key]: value,
                    })
                );
            } else {
                updatedSettings.current = expandObject({
                    [key]: value,
                });
            }

            debouncedUpdate();
        },
        [debouncedUpdate]
    );

    function saveContent(content: Content): void {
        sendRequest(content);
    }

    function saveSettingsItem(value: string, key: string) {
        handleSettingsChange(value, key);
    }

    function toggleSettingsSidebar(): void {
        setToggled(!toggled);
    }

    return (
        <>
            {!isLoading && response && (
                <SettingsSidebar
                    toggled={toggled}
                    scene={response}
                    toggleSidebar={toggleSettingsSidebar}
                    settings={response.settings}
                />
            )}
            <Container>
                <ResponseErrorHandler isLoading={!response} error={error}>
                    <div className={styles.header}>
                        <h4>{response?.title}</h4>
                        {isLoading && <Loader />}
                        <div className="d-flex">
                            <HasGroupRole
                                groupId={organizationContext}
                                role={GroupRole.READER}
                            >
                                <ScenePreviewModeSelector
                                    templateIdentifier={response?.template}
                                    templateLayoutId={response?.layout}
                                    settings={response?.settings}
                                    onModeChange={(previewModeId) => {
                                        setPreviewMode(previewModeId);
                                    }}
                                />
                            </HasGroupRole>
                            <HasGroupRole
                                groupId={organizationContext}
                                role={GroupRole.EDITOR}
                            >
                                <SceneMovableToggle
                                    movingLocked={movingLocked}
                                    setMovingLocked={setMovingLocked}
                                    templateIdentifier={response?.template}
                                    layoutIdentifier={response?.layout}
                                />
                            </HasGroupRole>
                            <ButtonGroup>
                                <HasGroupRole
                                    groupId={organizationContext}
                                    role={GroupRole.EDITOR}
                                >
                                    <SceneSettingsButton
                                        onClick={toggleSettingsSidebar}
                                    />
                                    <SceneLayoutButton scene={response} />
                                </HasGroupRole>
                                <LinkButton
                                    size="md"
                                    to={`${scenesRootPath}/${scene}`}
                                    title="Preview"

                                >
                                    <FaEye />
                                </LinkButton>
                                <HasGroupRole
                                    groupId={organizationContext}
                                    role={GroupRole.EDITOR}
                                >
                                    <DeleteSceneButton sceneId={scene} variant="button" />
                                </HasGroupRole>
                            </ButtonGroup>
                        </div>
                    </div>
                    {previewMode !== undefined && (
                        <ScaledScene>
                            <TemplateContainer
                                key={response?.template + response?.layout}
                                scene={response}
                                editable={true}
                                onContentChange={saveContent}
                                onSettingsItemChange={saveSettingsItem}
                                movable={!movingLocked}
                                previewMode={previewMode}
                            />
                        </ScaledScene>
                    )}
                </ResponseErrorHandler>
            </Container>
        </>
    );
}

export default SceneEditorPage;
