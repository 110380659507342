import Parcel from "single-spa-react/parcel";
import * as singleSpa from "single-spa";
import React from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import environment from "core/util/environment";
import { PageConfiguration } from "@castia/sdk";

interface PluginPageComponentProps {
    page: PageConfiguration;
}

export function PluginPageComponent(props: PluginPageComponentProps) {
    return (
        <PageLayout>
            <Parcel
                config={props.page.component}
                wrapWith="div"
                wrapStyle={{ height: "100%" }}
                mountParcel={singleSpa.mountRootParcel}
                environment={environment}
                templateProps={{}}
                {...props}
            />
        </PageLayout>
    );
}
