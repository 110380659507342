import React, { ReactElement } from "react";
import { DeviceSceneControl } from "device/edit/content/DeviceSceneControl";
import { DeviceChannelControl } from "device/edit/content/DeviceChannelControl";
import { DeviceScheduleControl } from "device/edit/content/DeviceScheduleControl";

export type ContentType = "SCENE" | "CHANNEL" | "SCHEDULE";

interface DeviceContentSourceProps {
    contentType: ContentType;
    contentId: string;
}

export function DeviceContentSource(
    props: DeviceContentSourceProps
): ReactElement {
    switch (props.contentType) {
        case "SCENE":
            return <DeviceSceneControl contentId={props.contentId} />;
        case "CHANNEL":
            return <DeviceChannelControl contentId={props.contentId} />;
        case "SCHEDULE":
        default:
            return <DeviceScheduleControl contentId={props.contentId} />;
    }
}
