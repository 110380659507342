import React, { ReactElement, useEffect, useState } from "react";
import { classNames, getProperty } from "@castia/sdk";
import styles from "scene/ScenesEditorPage/components/SettingsSidebar/SettingsSidebar.scss";
import Scene from "model/Scene";
import { Form, FormGroup } from "react-bootstrap";
import Button from "core/components/UI/Button/Button";
import { FaSave, FaTimes } from "react-icons/fa";
import SettingsItem from "scene/ScenesEditorPage/components/SettingsSidebar/SettingsItem/SettingsItem";
import { TemplateSettings } from "@castia/sdk";
import useUpdateSceneSettings from "hooks/api/scene/useUpdateSceneSettings";
import usePlugins from "hooks/usePlugins";
import { toast } from "react-toastify";
import { PluginEnvironmentContext } from "@castia/sdk";
import environment from "core/util/environment";
import { deepCloneJson } from "core/util/deepCloneJson";
import { SettingsType } from "@castia/sdk";
import { EnrichedTemplateConfiguration } from "core/components/Plugin/PluginContext/PluginContextProvider";

interface SettingsSidebarProps extends React.HTMLAttributes<HTMLDivElement> {
    toggled: boolean;
    scene: Scene;
    toggleSidebar: () => void;
    settings: TemplateSettings;
}

/**
 * Sidebar containing the scene settings form
 * @param props
 * @constructor
 */
function SettingsSidebar(props: SettingsSidebarProps): ReactElement {
    const [settings, setSettings] = useState(deepCloneJson(props.settings));
    const [title, setTitle] = useState(props.scene.title);
    const { findTemplate } = usePlugins();
    const updateSceneSettings = useUpdateSceneSettings(props.scene.id);

    useEffect((): void => {
        if (updateSceneSettings.error) {
            toast.error(
                "Something went wrong while updating the settings. Please try again later."
            );
        }
    }, [updateSceneSettings.isLoading]);

    const foundTemplate = findTemplate(props.scene.template);

    const classes = ["bg-light", styles.sidebarWrapper];
    props.toggled && classes.push(styles.toggled);

    async function onSubmit(): Promise<void> {
        await updateSceneSettings.sendRequest(
            Object.assign(settings, { title: title })
        );
        props.toggleSidebar();
    }

    function setValue(key: string, value: unknown): void {
        if (!settings.custom) {
            settings.custom = {};
        }
        settings.custom[key] = value;
        setSettings(Object.assign({}, settings));
    }

    function updateTitle(event: React.ChangeEvent<HTMLInputElement>): void {
        setTitle(event.currentTarget.value);
    }

    function renderSettingsItem(
        settingName: string,
        Setting: SettingsType,
        defaultValue: unknown
    ): ReactElement {
        const currentValue =
            settings?.custom?.[settingName] !== undefined
                ? settings?.custom?.[settingName]
                : defaultValue;

        return (
            <SettingsItem
                key={settingName}
                SettingComponent={Setting}
                settingName={settingName}
                value={currentValue}
                setValue={setValue}
                otherSettings={deepCloneJson(settings)}
            />
        );
    }

    function renderTemplateSettings(
        settingsItems: Record<string, SettingsType>
    ): ReactElement {
        if (!settingsItems) {
            return <></>;
        }
        return (
            <>
                {Object.entries(foundTemplate.settings).map(
                    ([settingName, Setting]): ReactElement =>
                        renderSettingsItem(
                            settingName,
                            Setting,
                            getProperty(foundTemplate.defaultSettings, settingName),
                        )
                )}
            </>
        );
    }

    function renderLayoutSettings(
        template: EnrichedTemplateConfiguration,
        layoutId: string
    ): ReactElement {
        if (!layoutId) {
            return;
        }
        const currentLayout = template.layouts.find(
            (layout) => layout.id === layoutId
        );
        if (!currentLayout || !currentLayout.settings) {
            return;
        }
        return (
            <>
                {Object.entries(currentLayout.settings).map(
                    ([settingName, Setting]): ReactElement =>
                        renderSettingsItem(
                            settingName,
                            Setting,
                            currentLayout.defaultSettings?.[settingName]
                        )
                )}
            </>
        );
    }

    return (
        <>
            <div className={classNames(...classes)}>
                <div className={styles.scrollContainer}>
                    <div className={classNames(styles.sidebarHeading, "bg-light")}>
                        <h4>Settings</h4>
                        <div>
                            <Button
                                form="settings-form"
                                type="submit"
                                variant="primary"
                                disabled={updateSceneSettings.isLoading}
                                onClick={onSubmit}
                            >
                                <FaSave />
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={props.toggleSidebar}
                            >
                                <FaTimes />
                            </Button>
                        </div>
                    </div>
                    <div className={classNames(styles.listGroup, "list-group")}>
                        <Form>
                            <FormGroup className={styles.form}>
                                <div>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        name="title"
                                        value={title}
                                        onChange={updateTitle}
                                    />
                                </div>
                                {/* Settings items are defined in the SDK and they can use the PluginEnvironmentContext, so wrap all settings in it */}
                                <PluginEnvironmentContext.Provider
                                    value={{
                                        environment: {
                                            environment: environment.environment,
                                            apiBaseUrl: environment.apiBaseUrl,
                                        },
                                        resourceDirectory:
                                        foundTemplate.resourceDirectory,
                                        editable: true,
                                    }}
                                >
                                    {foundTemplate &&
                                        foundTemplate.settings &&
                                        renderTemplateSettings(
                                            foundTemplate.settings
                                        )}
                                    {foundTemplate &&
                                        renderLayoutSettings(
                                            foundTemplate,
                                            props.scene.layout
                                        )}
                                </PluginEnvironmentContext.Provider>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SettingsSidebar;
