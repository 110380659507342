import { Folder } from "model/Folder";

export function findFolderInTree(folderTree: Folder[], id: string): Folder {
    for (const folder of folderTree) {
        if (folder.id === id) {
            return folder;
        }

        const found = findFolderInTree(folder.children || [], id);
        if (found) {
            return found;
        }
    }
}
