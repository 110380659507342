import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

/**
 * Delete device call.
 */
function useDeleteImages(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/media/images`,
        "DELETE",
        LocalEvents.IMAGES_CHANGED
    );
}

export default useDeleteImages;
