import React, { ReactElement, useState } from "react";
import Button from "core/components/UI/Button/Button";
import Modal from "core/components/UI/Modal/Modal";
import { Font } from "model/Font";
import { useDeleteFont } from "hooks/api/media/font/useDeleteFont";
import { FaTrash } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";

interface FontDeleteButtonProps {
    font: Font;
    variant: "button" | "dropdown";
}

/**
 * Button which deletes a scene via a 'are you sure' popup.
 * @param props
 * @constructor
 */
export function FontDeleteButton(props: FontDeleteButtonProps): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const { sendRequest } = useDeleteFont(props.font.id);

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    const confirmDelete = (): void => {
        sendRequest();
        closeModal();
    };

    return (
        <>
            {props.variant === "button" && (
                <Button
                    variant="danger"
                    size="md"
                    onClick={openModal}
                    data-cy="font-delete-button"
                    title="Delete font"
                >
                    <FaTrash />
                </Button>
            )}
            {props.variant === "dropdown" && (
                <Dropdown.Item
                    onClick={openModal}
                    className="d-flex align-items-center"
                    data-cy="font-delete-button"
                >
                    <FaTrash color="var(--bs-danger)" />
                    &nbsp;Delete font
                </Dropdown.Item>
            )}
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Are you sure?"
            >
                <Modal.Body>
                    Are you sure you want to delete the font {props.font.name}?
                    If this font is used in any scenes it will no longer display
                    the correct font, even if you re-upload it!
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        variant={"danger"}
                        onClick={confirmDelete}
                        data-cy="font-delete-confirm-button"
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
