import React, { ReactElement } from "react";
import { FolderMoveButton } from "core/folder/FolderMoveButton/FolderMoveButton";
import { FolderType } from "model/FolderType";
import { FolderMoveFormData } from "core/folder/FolderMoveButton/FolderMoveModalBody";
import { useUpdateChannel } from "hooks/api/channel/useUpdateChannel";

interface ChannelMoveButtonProps {
    channelId: string;
    currentFolderId: string;
}

export function ChannelMoveButton(props: ChannelMoveButtonProps): ReactElement {
    const persistData = useUpdateChannel(props.channelId);

    async function onSave(data: FolderMoveFormData) {
        await persistData.sendRequest({
            folder: data.folder,
        });
    }

    return (
        <FolderMoveButton
            folderType={FolderType.CHANNEL}
            onSave={onSave}
            persistData={persistData}
            currentFolderId={props.currentFolderId}
        />
    );
}
