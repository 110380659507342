import React, { ReactElement, useState } from "react";
import Button from "core/components/UI/Button/Button";
import Modal from "core/components/UI/Modal/Modal";
import { useDeleteVideos } from "hooks/api/media/video/useDeleteVideos";
import { Video } from "model/Video";

interface VideoDeleteButtonProps {
    videos: Video[];
}

export function VideoDeleteButton(props: VideoDeleteButtonProps): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const { sendRequest } = useDeleteVideos();

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    const confirmDelete = (): void => {
        sendRequest({
            ids: props.videos.map((video) => video.id),
        });
        closeModal();
    };

    return (
        <>
            <div className="d-grid">
                <Button variant="danger" size="sm" onClick={openModal}>
                    Delete
                </Button>
            </div>
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Are you sure?"
            >
                <Modal.Body>
                    Are you sure you want to delete the following videos?
                    <ul>
                        {props.videos.map((video): ReactElement => {
                            return <li key={video.id}>{video.name}</li>;
                        })}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant={"danger"} onClick={confirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
