import { FetchData } from "hooks/useFetch";
import { Paginated } from "@castia/sdk";
import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { Video } from "model/Video";

export const videoPageSize = 18;

/**
 * Fetch the video list.
 */
function useFetchAllVideos(): FetchData<Paginated<Video>> {
    const fetch = useFetchPaginated<Video>(
        `${environment.apiBaseUrl}/api/v1/media/videos`,
        videoPageSize,
        1
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.VIDEOS_CHANGED, eventCallback);

    return fetch;
}

export default useFetchAllVideos;
