import React, { ReactElement } from "react";
import useFetchOneScene from "hooks/api/scene/useFetchOneScene";
import { ResponseErrorHandler } from "@castia/sdk";
import { scenesPath } from "core/util/routes";
import { Link } from "react-router-dom";

interface ChannelFieldProps {
    sceneId: string;
}

export function SceneField(props: ChannelFieldProps): ReactElement {
    const { response, isLoading, error } = useFetchOneScene(props.sceneId);

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            <Link to={`${scenesPath}/${props.sceneId}`}>
                {response?.title}
            </Link>
        </ResponseErrorHandler>
    );
}
