import * as React from "react";
import { ReactElement, useEffect, useRef, useState } from "react";
import RegistrationCodeInput from "device/create/input/RegistrationCodeInput";
import styles from "device/create/DeviceRegistrationForm.scss";
import LoaderCheckMark from "core/components/UI/LoaderCheckmark/LoaderCheckMark";
import { sleep } from "core/util/sleep";
import { RegistrationCompleteEventData } from "events/DeviceEventEmitter";
import LocalEvents from "events/LocalEvents";
import { classNames, useEventListener } from "@castia/sdk";
import useRegisterDevice from "hooks/api/device/useRegisterDevice";
import environment from "core/util/environment";
import { useTenantProfileContext } from "tenant-profile/TenantProfileContext";

interface DeviceRegistrationFormProps {
    onComplete: (deviceId: string) => void;
}

/**
 * Device registration form.
 * @param props
 * @constructor
 */
function DeviceRegistrationForm(
    props: DeviceRegistrationFormProps
): ReactElement {
    const context = useTenantProfileContext();
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const storedRegistrationCode = useRef("");
    const { error, sendRequest } = useRegisterDevice();

    useEventListener(
        LocalEvents.DEVICES_REGISTRATION_COMPLETE,
        registrationComplete
    );

    useEffect(() => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    async function registrationComplete({
        registrationCode,
        deviceId,
    }: RegistrationCompleteEventData): Promise<void> {
        if (registrationCode === storedRegistrationCode.current) {
            const remainingTime = 1000;
            await sleep(remainingTime);
            setLoadingComplete(true);
            await sleep(2000);
            props.onComplete(deviceId);
            return;
        }
    }

    function submit(registrationCode: string): void {
        storedRegistrationCode.current = registrationCode;
        setLoading(true);
        sendRequest(null, [
            { key: "registrationCode", value: registrationCode },
        ]);
    }

    return (
        <>
            {!loading ? (
                <>
                    <div className={styles.intro}>
                        Navigate to{" "}
                        <a href={context.playerUrl || environment.playerUrl} target="_blank" rel="noreferrer">
                            {context.playerUrl || environment.playerUrl}
                        </a>{" "}
                        on the device you wish to register.
                    </div>
                    <RegistrationCodeInput
                        disabled={loading}
                        onComplete={(value): void => {
                            submit(value);
                        }}
                    />
                    <div className={classNames(styles.error, error ? "visible": "invisible")}>
                        Device not found. Did you enter the correct code?
                    </div>
                </>
            ) : (
                <LoaderCheckMark complete={loadingComplete} />
            )}
        </>
    );
}

export default DeviceRegistrationForm;
