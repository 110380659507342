// Root paths
export const rootPath = "/app";
export const registerRootPath = "/register";
export const scenesRootPath = "/scenes";
export const templatesRootPath = "/templates";
export const systemRootPath = "/sysadmin";

// App paths
export const devicesPath = `${rootPath}/devices`;
export const registerDevicePath = `${devicesPath}/register`;
export const scenesPath = `${rootPath}/scenes`;
export const channelsPath = `${rootPath}/channels`;
export const mediaPath = `${rootPath}/media`;
export const imagePath = `${mediaPath}/images`;
export const videoPath = `${mediaPath}/videos`;
export const fontPath = `${mediaPath}/fonts`;
export const planningPath = `${rootPath}/planning`;
export const schedulePath = `${planningPath}/schedules`;
export const userPath = `${rootPath}/users`;
export const newsPath = `${rootPath}/news`;
export const profilePath = `${rootPath}/profile`;
export const groupPath = `${rootPath}/groups`;
export const tenantProfilePath = `${rootPath}/tenant-profiles`;
export const keycloakRealmPath = `${rootPath}/keycloak-realms`;

// Sysadmin paths
export const playerAdminPage = `${rootPath}${systemRootPath}/player-management`;
export const wireguardPeerAdminPage = `${rootPath}${systemRootPath}/wireguard-peers`;
