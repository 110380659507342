import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { NewsEditForm } from "news/edit/NewsEditForm";
import { useFetchOneNewsItem } from "hooks/api/news/useFetchOneNewsItem";
import { useParams } from "react-router-dom";
import { ResponseErrorHandler } from "@castia/sdk";

interface NewsEditPageParams {
    newsItemId: string;
}

export function NewsEditPage() {
    const { newsItemId } = useParams<NewsEditPageParams>();
    const { response, isLoading, error } = useFetchOneNewsItem(newsItemId);

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Edit News</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && <NewsEditForm newsItem={response} />}
                </ResponseErrorHandler>
            </PageLayout.Body>
        </PageLayout>
    );
}
