import { DeviceScreenshot } from "model/DeviceScreenshot";
import { Carousel } from "react-bootstrap";
import styles from "device/show/DeviceScreenshotCarousel.scss";
import React, { ReactElement } from "react";
import { format } from "date-fns";

interface DeviceScreenshotCarouselProps {
    screenshots: DeviceScreenshot[];
    setActiveItem: (itemKey: number) => void;
    activeItem: number;
    fullscreen?: boolean;
}

export function DeviceScreenshotCarousel(
    props: DeviceScreenshotCarouselProps
): ReactElement {
    return (
        <Carousel
            interval={null}
            variant={props.fullscreen ? undefined : "dark"}
            activeIndex={props.activeItem}
            onSelect={(eventKey) => props.setActiveItem(eventKey)}
        >
            {props.screenshots.map((deviceScreenshot) => {
                return (
                    <Carousel.Item key={deviceScreenshot.id}>
                        <div className={styles.carouselItem}>
                            <div
                                className={styles.thumbnail}
                                style={{
                                    backgroundImage: `url(${deviceScreenshot.location})`,
                                    height: props.fullscreen
                                        ? "100vh"
                                        : "300px",
                                    maxHeight: props.fullscreen
                                        ? "100vh"
                                        : "300px",
                                    width: "100%",
                                    maxWidth: props.fullscreen
                                        ? "auto"
                                        : "500px",
                                }}
                            />
                        </div>
                        <Carousel.Caption>
                            <span className={styles.caption}>
                                {format(
                                    new Date(deviceScreenshot.date),
                                    "dd-LL-yyyy HH:mm:ss"
                                )}
                            </span>
                        </Carousel.Caption>
                    </Carousel.Item>
                );
            })}
        </Carousel>
    );
}
