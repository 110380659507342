import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { GroupSummary } from "model/GroupSummary";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { useEventEmitter, useLocalStorage } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { useGroupSummary } from "hooks/api/groups/useGroupSummary";
import useAuthentication from "hooks/useAuthentication";
import { flatten } from "core/auth/flattenGroups";

export const ORGANIZATION_CONTEXT_STORAGE_KEY = "organization-context";

export function GroupSelect(): ReactElement {
    const { response } = useGroupSummary();
    const authentication = useAuthentication();
    const [organizationContext, setOrganizationContext] = useLocalStorage(
        ORGANIZATION_CONTEXT_STORAGE_KEY,
        { initialValue: authentication.organization() }
    );
    const [organizationState, setOrganizationState] = useState<
        string | undefined
    >(organizationContext);
    const emitter = useEventEmitter();

    function selectOrganization(organizationId: string) {
        setOrganizationContext(organizationId);
        setOrganizationState(organizationId);
        emitter.emit(LocalEvents.ORGANIZATION_CONTEXT_CHANGED);
    }

    // This useEffect ensures the selector state will be set to undefined if the organization-context is not found in the list of groups.
    // This can happen if you delete the group you have selected.
    useEffect(() => {
        if (response && organizationState) {
            const groups = flatten(response, "subGroups");
            if (!groups.find((group) => group.id === organizationState)) {
                selectOrganization(undefined);
            }
        }
    }, [response]);

    function getDropdownItems(
        groups: GroupSummary[],
        nesting = 0
    ): ReactNode[] {
        const result = [];

        for (const group of groups) {
            const subGroups = group.subGroups
                ? getDropdownItems(group.subGroups, nesting + 1)
                : [];
            result.push(
                <Dropdown.Item
                    key={group.id}
                    value={group.id}
                    style={{ paddingLeft: nesting + 1 + "rem" }}
                    onClick={() => selectOrganization(group.id)}
                    active={organizationState === group.id}
                >
                    {group.name}
                </Dropdown.Item>,
                ...subGroups
            );
        }

        return result;
    }

    function getOrganizationName() {
        return flatten<GroupSummary>(response, "subGroups")?.find(
            (group) => group.id === organizationState
        )?.name;
    }

    return (
        <Dropdown className="w-100" align="end">
            <Dropdown.Toggle
                variant="light"
                className="w-100 rounded-0 d-flex align-items-center justify-content-center"
            >
                <span className="text-truncate">
                    {response && getOrganizationName()}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {response && getDropdownItems(response)}
            </Dropdown.Menu>
        </Dropdown>
    );
}
