import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";
import { PersistData } from "@castia/sdk";

export function useCreateKeycloakRealm(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/keycloak-realms`,
        "POST",
        LocalEvents.KEYCLOAK_REALMS_CHANGED
    );
}
