import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "react-bootstrap";

function DeviceNamePage(): ReactElement {
    const { register } = useFormContext();

    return (
        <>
            <Form.Label>Device name</Form.Label>
            <Form.Control
                name="deviceName"
                type="text"
                placeholder="Device name"
                {...register("deviceName")}
            />
        </>
    );
}

export default DeviceNamePage;
