import { Frequency } from "rrule";
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { weekdays } from "schedule/schedule-event/input/WeekdaysInput";
import { SelectInput2 } from "core/components/input/SelectInput2";
import { getISODay } from "date-fns";

export function FrequencyInput() {
    const form = useFormContext();
    const byweekday = useWatch({
        name: "byweekday",
    });
    const frequency = useWatch({
        name: "frequency",
        defaultValue: form.getValues("frequency"),
    });

    useEffect(() => {
        if (byweekday?.length === weekdays.length) {
            form.setValue("frequency", Frequency.DAILY);
        } else if (form.getValues("frequency") === Frequency.DAILY) {
            form.setValue("frequency", Frequency.WEEKLY);
        }
    }, [byweekday]);

    useEffect(() => {
        if (frequency === Frequency.DAILY) {
            form.setValue(
                "byweekday",
                weekdays.map((day) => day.value)
            );
        } else if (
            frequency === Frequency.WEEKLY &&
            (form.getValues("byweekday")?.length === weekdays.length ||
                form.getValues("byweekday")?.length === 0)
        ) {
            const day = getISODay(new Date(form.getValues("startDate")));

            form.setValue("byweekday", [weekdays[day - 1].value]);
        }
    }, [frequency]);

    return (
        <SelectInput2
            name={"frequency"}
            choices={[
                {
                    value: Frequency.DAILY,
                    label: "Day(s)",
                },
                {
                    value: Frequency.WEEKLY,
                    label: "Week(s)",
                },
                {
                    value: Frequency.MONTHLY,
                    label: "Month(s)",
                },
                {
                    value: Frequency.YEARLY,
                    label: "Year(s)",
                },
            ]}
        />
    );
}
