import React, { ReactElement } from "react";
import styles from "./PageLayoutHeaderTitle.scss";

function PageLayoutHeaderTitle(
    props: React.PropsWithChildren<unknown>
): ReactElement {
    return (
        <div>
            <h1 className={styles.title}>{props.children}</h1>
        </div>
    );
}

export default PageLayoutHeaderTitle;
