import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useAddUserToGroup(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v2/groups/$groupId/invite`,
        "POST",
        LocalEvents.USER_CHANGED
    );
}
