/**
 * Creates a Headers object containing the jwt bearer token.
 * @param jwt
 * @param organizationContext
 * @param skipAuth
 */
function apiRequestHeaders(
    jwt?: string,
    organizationContext?: string,
    skipAuth?: boolean
): Headers {
    const headers = new Headers();
    if (!skipAuth) {
        headers.append("Authorization", "Bearer " + jwt);
    }
    headers.append("Content-Type", "application/json");
    if (organizationContext && !skipAuth) {
        headers.append("Organization-Context", organizationContext);
    }

    return headers;
}

export default apiRequestHeaders;
