import { ReactElement, useState } from "react";
import Button from "core/components/UI/Button/Button";
import * as React from "react";
import { ScheduleCreateModal } from "schedule/schedule/components/ScheduleCreateModal";

export function ScheduleCreateButton(): ReactElement {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                variant={"primary"}
                size={"sm"}
                onClick={(): void => setShowModal(true)}
                data-cy="create-schedule-button"
            >
                New Schedule
            </Button>
            <ScheduleCreateModal
                show={showModal}
                onDone={(): void => setShowModal(false)}
            />
        </>
    );
}
