import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

/**
 * Call to remove a scene from a channel
 * @param channelId
 * @param channelSceneId
 */
function useRemoveSceneFromChannel(
    channelId: string,
    channelSceneId: string
): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/channels/${channelId}/scenes/${channelSceneId}`,
        "DELETE",
        LocalEvents.CHANNELS_SCENES_ADDED
    );
}

export default useRemoveSceneFromChannel;
