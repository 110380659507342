import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { NewsCreateForm } from "news/create/NewsCreateForm";

export function NewsCreatePage() {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Create News</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <NewsCreateForm />
            </PageLayout.Body>
        </PageLayout>
    );
}
