import { Route, RouteComponentProps, Switch } from "react-router";
import React, { ReactElement } from "react";
import { TenantProfileListPage } from "tenant-profile/TenantProfileListPage";
import { TenantProfileCreatePage } from "tenant-profile/TenantProfileCreatePage";
import { TenantProfileEditPage } from "tenant-profile/TenantProfileEditPage";
import { TenantProfileShowPage } from "tenant-profile/TenantProfileShowPage";
import { TenantThemeEditPage } from "tenant-profile/theme/TenantThemeEditPage";

export function TenantProfileRouter({
    match,
}: RouteComponentProps): ReactElement<RouteComponentProps> {
    return (
        <Switch>
            <Route
                path={`${match.url}/create`}
                exact
                component={TenantProfileCreatePage}
            />
            <Route
                path={`${match.url}/:tenantProfileId`}
                exact
                component={TenantProfileShowPage}
            />
            <Route
                path={`${match.url}/:tenantProfileId/edit`}
                exact
                component={TenantProfileEditPage}
            />
            <Route
                path={`${match.url}/:tenantProfileId/theme/edit`}
                exact
                component={TenantThemeEditPage}
            />
            <Route path={`${match.url}`} component={TenantProfileListPage} />
        </Switch>
    );
}
