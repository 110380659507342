import React, { ReactElement } from "react";
import { VideoLibrary } from "video/VideoLibrary";
import { VideoUploadButton } from "video/VideoUploadButton";
import { Col, Container, Row } from "react-bootstrap";
import styles from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/ListPageLayoutHeaderSecondary.scss";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";

export function VideoPage(): ReactElement {
    const organizationContext = useOrganizationContext();

    return (
        <Container>
            <Row>
                <Col lg={10} md={9} sm={12}>
                    <h1>Videos</h1>
                </Col>
                <Col className={styles.buttonCol} lg={2} md={3} sm={12}>
                    <HasGroupRole
                        groupId={organizationContext}
                        role={GroupRole.EDITOR}
                    >
                        <VideoUploadButton />
                    </HasGroupRole>
                </Col>
            </Row>
            <VideoLibrary />
        </Container>
    );
}
