import React, { PropsWithChildren, ReactElement } from "react";
import styles from "core/components/Sidebar/Sidebar.scss";
import { NavLink, NavLinkProps } from "react-router-dom";
import logo from "../../../../assets/white-castia-logo.png";
import { Navbar } from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import { classNames } from "@castia/sdk";
import { useTenantProfileContext } from "tenant-profile/TenantProfileContext";

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
    toggled: boolean;
}

const Sidebar = (props: SidebarProps): ReactElement => {
    const context = useTenantProfileContext();
    const classes = ["bg-primary", styles.sidebarWrapper];
    props.toggled && classes.push(styles.toggled);

    return (
        <div className={classNames(...classes)}>
            <div className={classNames(styles.sidebarHeading, "bg-primary")}>
                <Navbar.Brand href="/">
                    <img src={context.theme.logo || logo} className={styles.logo} alt="Castia logo" />
                </Navbar.Brand>
            </div>
            <div
                className={classNames(
                    styles.listGroup,
                    "list-group",
                    "list-group-flush",
                    "text-light"
                )}
            >
                {props.children}
            </div>
        </div>
    );
};

const MenuItem = (props: NavLinkProps): ReactElement => {
    return (
        <NavLink
            {...props}
            className={classNames(
                styles.menuItem,
                "list-group-item list-group-item-action text-light bg-primary"
            )}
            activeClassName={styles.active}
        >
            {props.children}
        </NavLink>
    );
};

const CollapseMenuItem = (props: NavLinkProps): ReactElement => {
    return (
        <NavLink
            {...props}
            className={classNames(
                styles.collapseMenuItem,
                "list-group-item list-group-item-action text-primary border-0"
            )}
            activeClassName={styles.active}
        >
            <span>{props.children}</span>
        </NavLink>
    );
};

const MenuCollapse = (props: PropsWithChildren<unknown>): ReactElement => {
    return (
        <NavLink
            to={"#"}
            className={classNames(
                styles.menuItem,
                "list-group-item list-group-item-action bg-primary text-light"
            )}
        >
            <div className="d-flex w-100 align-items-center">
                {props.children} <FaCaretDown className="ml-auto" />
            </div>
        </NavLink>
    );
};

const MenuHeader = (props: PropsWithChildren<unknown>): ReactElement => {
    return (
        <div className={styles.menuHeader}>
            {props.children}
        </div>
    );
};

Sidebar.MenuItem = MenuItem;
Sidebar.CollapseMenuItem = CollapseMenuItem;
Sidebar.MenuCollapse = MenuCollapse;
Sidebar.MenuHeader = MenuHeader;
export default Sidebar;
