import { Paging, ResponseErrorHandler } from "@castia/sdk";
import styles from "user/list/UserListContainer.scss";
import React from "react";
import {
    keycloakRealmPageSize,
    useFetchPaginatedKeycloakRealms,
} from "hooks/api/keycloak-realm/useFetchPaginatedKeycloakRealms";
import { KeycloakRealmList } from "keycloak-realm/list/KeycloakRealmList";

export function KeycloakRealmListContainer() {
    const { response, isLoading, error, refreshData } =
        useFetchPaginatedKeycloakRealms();

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            {response && <KeycloakRealmList keycloakRealms={response.items} />}
            <div className={styles.pagingContainer}>
                <Paging
                    currentPage={response?.meta.currentPage}
                    onClick={(page): void => {
                        refreshData(
                            new URLSearchParams({
                                page: String(page),
                                limit: String(keycloakRealmPageSize),
                            })
                        );
                    }}
                    totalPages={response?.meta.totalPages}
                    showMax={10}
                />
            </div>
        </ResponseErrorHandler>
    );
}
