import React, { PropsWithChildren, ReactElement } from "react";
import useAuthentication from "hooks/useAuthentication";

export function IsSysAdmin(props: PropsWithChildren<unknown>): ReactElement {
    const auth = useAuthentication();

    const roles = auth.getRoles();
    if (roles.includes("sysadmin")) {
        return <>{props.children}</>;
    }
    return null;
}
