import React, { ReactElement } from "react";
import Button from "core/components/UI/Button/Button";
import { FaCog } from "react-icons/fa";

interface SceneSettingsButtonProps {
    onClick: () => void;
}

function SceneSettingsButton(props: SceneSettingsButtonProps): ReactElement {
    return (
        <Button onClick={props.onClick} title="Settings">
            <FaCog />
        </Button>
    );
}

export default SceneSettingsButton;
