import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";
import { tenantProfilePath } from "core/util/routes";
import LocalEvents from "events/LocalEvents";
import React from "react";

interface TenantProfileDeleteButtonProps {
    tenantProfileId: string;
}

export function TenantProfileDeleteButton(
    props: TenantProfileDeleteButtonProps
) {
    return (
        <DeleteWithConfirmButton
            apiPath={`api/v1/tenant-profiles/${props.tenantProfileId}`}
            title={`Delete Tenant Profile`}
            message={
                "WARNING: Are you sure you want to delete this tenant profile? Users of this tenant WILL NOT be able to login anymore!"
            }
            successRedirectPath={tenantProfilePath}
            successEvent={LocalEvents.TENANT_PROFILES_CHANGED}
        />
    );
}
