import React from "react";
import { Card, Table } from "react-bootstrap";
import { useFetchInstalledPluginsForGroup } from "hooks/api/plugin/useFetchInstalledPluginsForGroup";
import { ResponseErrorHandler } from "@castia/sdk";
import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";
import { InstallPluginForGroupButton } from "group/button/InstallPluginForGroupButton";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import LocalEvents from "events/LocalEvents";

interface GroupPluginsCardProps {
    groupId: string;
}

export function GroupPluginsCard(props: GroupPluginsCardProps) {
    const { response, isLoading, error, refreshData } =
        useFetchInstalledPluginsForGroup(props.groupId);

    return (
        <Card>
            <Card.Body>
                <Card.Title className="d-flex justify-content-between">
                    <div>Plugins</div>
                    <HasGroupRole
                        groupId={props.groupId}
                        role={GroupRole.ORGANIZATION_ADMIN}
                    >
                        <InstallPluginForGroupButton groupId={props.groupId} />
                    </HasGroupRole>
                </Card.Title>
                <ResponseErrorHandler
                    isLoading={isLoading}
                    error={error}
                    retryAction={() => refreshData()}
                >
                    {response && (
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {response
                                    .filter((plugin) => !plugin.core)
                                    .map((plugin, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="align-middle px-4 py-2">
                                                    {plugin.name}
                                                </td>
                                                <td className="text-end">
                                                    <HasGroupRole
                                                        groupId={props.groupId}
                                                        role={
                                                            GroupRole.ORGANIZATION_ADMIN
                                                        }
                                                    >
                                                        <DeleteWithConfirmButton
                                                            apiPath={`api/v1/plugins/installed/${plugin.installedPluginId}`}
                                                            title="Remove plugin"
                                                            message="Are you sure you want to remove this plugin from the group? If something from this plugin is in use it might break the players."
                                                            successEvent={LocalEvents.GROUP_CHANGED}
                                                        />
                                                    </HasGroupRole>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    )}
                </ResponseErrorHandler>
            </Card.Body>
        </Card>
    );
}
