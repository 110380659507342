import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useCreateGroup(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v2/groups`,
        "POST",
        LocalEvents.GROUPS_CHANGED
    );
}
