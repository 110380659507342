import { Card, Col, Row } from "react-bootstrap";
import React, { useState } from "react";
import { useNewsItems } from "hooks/api/news/useNewsItems";
import { classNames, ResponseErrorHandler } from "@castia/sdk";
import { NewsShowModal } from "news/show/NewsShowModal";
import { formatDate } from "core/util/dateTimeUtil";
import styles from "./NewsCard.scss";
import { NewsItem } from "model/NewsItem";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { newsPath } from "core/util/routes";

export function NewsCard() {
    const { response, isLoading, error, refreshData } = useNewsItems(4);
    const [clickedItem, setClickedItem] = useState(null);
    const [show, setShow] = useState(false);

    function showNewsItem(newsItem: NewsItem) {
        setClickedItem(newsItem);
        setShow(true);
    }

    return (
        <>
            <Col md={9}>
                <Card>
                    <Card.Body>
                        <Card.Title className="d-flex justify-content-between">
                            <span>News</span>
                            <LinkButton to={newsPath}>All news</LinkButton>
                        </Card.Title>
                        <hr />
                        <ResponseErrorHandler
                            isLoading={isLoading}
                            error={error}
                            retryAction={() => refreshData()}
                        >
                            {response && (
                                <div>
                                    {response.items.map((item) => {
                                        return (
                                            <div key={item.id} className="mb-4">
                                                <Row>
                                                    <Col
                                                        className={styles.lead}
                                                    >
                                                        <small className="text-info">
                                                            {formatDate(
                                                                new Date(
                                                                    item.createdAt
                                                                )
                                                            )}
                                                        </small>
                                                        <a
                                                            className={classNames(
                                                                "text-info",
                                                                styles.pointer
                                                            )}
                                                            onClick={() => {
                                                                showNewsItem(
                                                                    item
                                                                );
                                                            }}
                                                        >
                                                            Read &#8250;
                                                        </a>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        onClick={() => {
                                                            showNewsItem(item);
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.pointer
                                                            }
                                                        >
                                                            {item.title}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    })}
                                    {response.items?.length === 0 && <span>There are currently no news items or updates about the platform.</span>}
                                </div>
                            )}
                        </ResponseErrorHandler>
                    </Card.Body>
                </Card>
            </Col>
            <NewsShowModal
                show={show}
                setShowModal={setShow}
                newsItem={clickedItem}
            />
        </>
    );
}
