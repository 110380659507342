import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { ApiError } from "@castia/sdk/dist/hooks/ApiError";
import { Form, Modal } from "react-bootstrap";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import Button from "core/components/UI/Button/Button";
import { FolderFormValues } from "core/folder/FolderForm/FolderFormValues";

interface FolderFormProps {
    error: ApiError;
    onSubmit: (date: FolderFormValues) => Promise<void>;
    handleClose: () => void;
}

export function FolderForm(props: FolderFormProps): ReactElement {
    const form = useFormContext();

    return (
        <Form onSubmit={form.handleSubmit(props.onSubmit)}>
            <Modal.Body>
                <Form.Label>Name</Form.Label>
                <Form.Control
                    name="name"
                    type="string"
                    {
                        ...form.register('name', {
                            required: "Name is a required field",
                        })
                    }
                />
                {form.formState.errors.name && (
                    <FormInputError>{form.formState.errors.name.message}</FormInputError>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Form>
    );
}
