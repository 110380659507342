import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useUpdateScene(sceneId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/scenes/${sceneId}`,
        "PATCH",
        LocalEvents.SCENES_CHANGED
    );
}
