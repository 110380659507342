import React, { ReactElement } from "react";
import environment from "core/util/environment";
import { SettingsType } from "@castia/sdk/dist/template/TemplateMetadata";
import { TemplateSettings } from "@castia/sdk";

interface SettingsItemProps {
    SettingComponent: SettingsType;
    settingName: string;
    value: string;
    setValue: (key: string, value: unknown) => void;
    otherSettings: TemplateSettings;
}

/**
 * Load a {@link SettingsType} as a component. This has a hidden input to which the values get copied for
 * react-hook-form integration.
 * @param props
 * @constructor
 */
function SettingsItem(props: SettingsItemProps): ReactElement {
    function setValueInSettingsObject(value: unknown): void {
        props.setValue(props.settingName, value);
    }

    return (
        <>
            <props.SettingComponent
                key={props.settingName}
                onChange={(updatedValue: string): void => {
                    setValueInSettingsObject(updatedValue);
                }}
                environment={environment}
                value={props.value}
                otherSettings={props.otherSettings}
            />
        </>
    );
}

export default SettingsItem;
