import { default as React, PropsWithChildren, ReactElement } from "react";
import { classNames } from "@castia/sdk";
import styles from "core/components/UI/FormInputError/FormInputError.scss";

/**
 * Component to show an error underneath an input field. Pass the error message as a child element.
 * @param props
 * @constructor
 */
function FormInputError(props: PropsWithChildren<unknown>): ReactElement {
    return (
        <div className={classNames("invalid-feedback", styles.showError)}>
            {props.children}
        </div>
    );
}

export default FormInputError;
