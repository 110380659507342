import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";

/**
 * Register device call.
 *
 * When using the sendRequest, set the 'registrationCode' parameter to the correct value.
 */
function useRegisterDevice(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/devices/register/$registrationCode`,
        "POST"
    );
}

export default useRegisterDevice;
