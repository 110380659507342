import { useFetchAllKeycloakRealms } from "hooks/api/keycloak-realm/useFetchAllKeycloakRealms";
import { ResponseErrorHandler } from "@castia/sdk";
import React from "react";
import { SelectInput } from "core/components/input/SelectInput";

interface KeycloakRealmInputProps {
    name: string;
    label: string;
}

export function KeycloakRealmInput(props: KeycloakRealmInputProps) {
    const { response, isLoading, error, refreshData } =
        useFetchAllKeycloakRealms();

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            {response && response.length > 0 && (
                <>
                    <SelectInput
                        name={props.name}
                        label={props.label}
                        choices={response.map((keycloakRealm) => {
                            return {
                                label: keycloakRealm.realmName,
                                value: keycloakRealm.id,
                            };
                        })}
                    ></SelectInput>
                </>
            )}
        </ResponseErrorHandler>
    );
}
