import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";

function useCreateChannel(successEvent?: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/folders`,
        "POST",
        successEvent
    );
}

export default useCreateChannel;
