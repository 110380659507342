import environment from "core/util/environment";
import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import LocalEvents from "events/LocalEvents";

/**
 * Call to update scene settings.
 * @param sceneId
 */
function useUpdateSceneSettings(sceneId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/scenes/${sceneId}/settings`,
        "PUT",
        LocalEvents.SCENE_SETTINGS_CHANGED
    );
}

export default useUpdateSceneSettings;
