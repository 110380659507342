import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import { PersistData } from "@castia/sdk";

export function usePatchKeycloakRealm(keycloakRealmId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/keycloak-realms/${keycloakRealmId}`,
        "PATCH"
    );
}
