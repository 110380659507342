import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import Channel from "model/Channel";
import Device from "model/Device";

interface SceneUsage {
    channels: Channel[];
    devices: Device[];
}

/**
 * Call to fetch a scene and its content
 * @param sceneId
 */
export function useFetchSceneUsage(sceneId: string): FetchData<SceneUsage> {
    return useFetch<SceneUsage>(
        `${environment.apiBaseUrl}/api/v1/scenes/${sceneId}/usage`
    );
}
