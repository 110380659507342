import useFetch from "hooks/useFetch";
import environment from "core/util/environment";
import { DeviceScreenshot } from "model/DeviceScreenshot";
import { FetchData } from "@castia/sdk";

export function useDeviceScreenshots(deviceId: string): FetchData<DeviceScreenshot[]> {
    return useFetch<DeviceScreenshot[]>(
        `${environment.apiBaseUrl}/api/v1/devices/${deviceId}/screenshots`
    );
}
