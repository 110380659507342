import React, { ReactElement } from "react";
import * as RB from "react-bootstrap";

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
    show: boolean;
    onHide?: () => void;
    size?: "sm" | "lg" | "xl";
    title: string;
}

/**
 * Component to show a Modal. This also exports a Modal.Body and Modal.Footer property, which can both be used as
 * sub-elements.
 * @param props
 * @constructor
 */
const Modal = (props: ModalProps): ReactElement => {
    return (
        <RB.Modal {...props}>
            <RB.Modal.Header closeButton>{props.title}</RB.Modal.Header>
            {props.children}
        </RB.Modal>
    );
};

const Body = (props: React.HTMLAttributes<HTMLDivElement>): ReactElement => {
    return <RB.Modal.Body {...props}>{props.children}</RB.Modal.Body>;
};

const Footer = (props: React.HTMLAttributes<HTMLDivElement>): ReactElement => {
    return <RB.Modal.Footer>{props.children}</RB.Modal.Footer>;
};

Modal.Body = Body;
Modal.Footer = Footer;
export default Modal;
