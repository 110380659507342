import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import React, { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "core/components/UI/Button/Button";
import { FaTrash } from "react-icons/fa";
import Modal from "core/components/UI/Modal/Modal";
import Loader from "core/components/UI/Loader/Loader";
import { Dropdown } from "react-bootstrap";
import styles from "./DeleteWithConfirmButton.scss";

interface DeleteWithConfirmButtonProps {
    apiPath: string;
    successEvent?: string;
    title: string;
    message: string;
    successRedirectPath?: string;
    cyDeleteButton?: string;
    cyConfirmDeleteButton?: string;
    variant?: "button" | "dropdown";
    showTitleOnButton?: boolean;
}

export function DeleteWithConfirmButton(
    props: DeleteWithConfirmButtonProps
): ReactElement {
    const variant = props.variant || "button";
    const { sendRequest } = useFetchPersist(
        `${environment.apiBaseUrl}/${props.apiPath}`,
        "DELETE",
        props.successEvent
    );

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    const confirmDelete = async (): Promise<void> => {
        setLoading(true);
        await sendRequest();
        closeModal();
        props.successRedirectPath && history.push(props.successRedirectPath);
    };

    return (
        <>
            {variant === "button" && (
                <Button
                    variant="danger"
                    onClick={openModal}
                    title={props.title}
                    data-cy={props.cyDeleteButton}
                >
                    <FaTrash /> {props.showTitleOnButton && props.title}
                </Button>
            )}
            {variant === "dropdown" && (
                <Dropdown.Item
                    onClick={openModal}
                    className="d-flex align-items-center"
                >
                    <FaTrash color="var(--bs-danger)" />
                    &nbsp;{props.title}
                </Dropdown.Item>
            )}
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title={props.title}
                className={styles.darkenedBackground}
            >
                <Modal.Body>
                    {loading && <Loader />}
                    {!loading && showModal && props.message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        variant={"danger"}
                        onClick={confirmDelete}
                        data-cy={props.cyConfirmDeleteButton}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
