import React, { ReactElement, useState } from "react";
import Button from "core/components/UI/Button/Button";
import { FaEdit } from "react-icons/fa";
import { Schedule } from "model/Schedule";
import { ScheduleUpdateModal } from "schedule/schedule/button/ScheduleUpdateModal";

interface ScheduleUpdateButtonProps {
    schedule: Schedule;
}

export function ScheduleUpdateButton(
    props: ScheduleUpdateButtonProps
): ReactElement {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                variant={"secondary"}
                size={"md"}
                onClick={(): void => setShowModal(true)}
            >
                <FaEdit /> Edit
            </Button>
            <ScheduleUpdateModal
                show={showModal}
                onDone={(): void => setShowModal(false)}
                schedule={props.schedule}
            />
        </>
    );
}
