import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { useFetchFolder } from "hooks/api/folder/useFetchFolder";
import { FaLevelUpAlt } from "react-icons/fa";

interface SceneFolderNavigateUpProps {
    currentFolderId: string | null;
    onFolderNavigateUp: (folderId: string) => void;
}

function SceneFolderNavigateUpButton(props: SceneFolderNavigateUpProps) {
    const folder = useFetchFolder(props.currentFolderId);

    return (
        <Button
            onClick={() =>
                props.onFolderNavigateUp(folder?.response?.parent?.id)
            }
        >
            <FaLevelUpAlt /> ..
        </Button>
    );
}

export function SceneFolderNavigateUp(
    props: SceneFolderNavigateUpProps
): ReactElement {
    if (props.currentFolderId === null) {
        return null;
    }

    return (
        <SceneFolderNavigateUpButton
            currentFolderId={props.currentFolderId}
            onFolderNavigateUp={props.onFolderNavigateUp}
        />
    );
}
