import { ReactElement } from "react";
import * as yup from "yup";
import Channel from "model/Channel";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Form, FormGroup } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import Modal from "core/components/UI/Modal/Modal";
import Button from "core/components/UI/Button/Button";

export interface ChannelFormFields {
    title: string;
    transitionType: string;
}

const schema = yup.object({
    title: yup.string().required("Title is a required field"),
    transitionType: yup.string(),
});

interface ChannelFormProps {
    existingChannel?: Channel;
    handleSubmit: (data: ChannelFormFields) => void;
    isLoading: boolean;
    handleClose: () => void;
}

export function ChannelForm(props: ChannelFormProps): ReactElement {
    const form = useForm<ChannelFormFields>({
        resolver: yupResolver(schema),
        defaultValues: props.existingChannel
            ? {
                  title: props.existingChannel.title,
                  transitionType: props.existingChannel.transitionType,
              }
            : {},
    });

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(props.handleSubmit)}>
                <Modal.Body>
                    <TextInput name="title" label="Title" />
                    <FormGroup>
                        <Form.Label>Transition Type</Form.Label>
                        <Form.Select {...form.register("transitionType")}>
                            <option value="">None</option>
                            <option value="slideFromLeft">
                                Slide from left
                            </option>
                            <option value="slideFromRight">
                                Slide from right
                            </option>
                            <option value="slideFromBottom">
                                Slide from bottom
                            </option>
                            <option value="slideFromTop">Slide from top</option>
                            <option value="fadeInOut">Fade in and out</option>
                            <option value="crossFade">Cross Fade</option>
                        </Form.Select>
                        {form.formState.errors.transitionType && (
                            <FormInputError>
                                {form.formState.errors.transitionType.message}
                            </FormInputError>
                        )}
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        {props.existingChannel ? "Update" : "Create"}
                    </Button>
                </Modal.Footer>
            </Form>
        </FormProvider>
    );
}
