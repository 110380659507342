import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";
import useFetchMultipartPost, {
    PersistData,
} from "hooks/useFetchMultipartPost";

export function useCreateFont(): PersistData {
    return useFetchMultipartPost(
        `${environment.apiBaseUrl}/api/v1/media/fonts`,
        LocalEvents.FONTS_CHANGED
    );
}
