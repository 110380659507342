import "@babel/polyfill";
import * as React from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";

import { ErrorInfo, ReactElement } from "react";
import AdminRouter from "./routers/AdminRouter/AdminRouter";
import TemplateRouter from "./routers/TemplateRouter/TemplateRouter";
import SceneRouter from "routers/SceneRouter/SceneRouter";
import PrivateRoute from "./core/components/PrivateRoute/PrivateRoute";
import Page404 from "pages/error-pages/Page404/Page404";

import "@castia/sdk/dist/sdk.css";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";
import "./scss/styles.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    registerDevicePath,
    registerRootPath,
    rootPath,
    scenesRootPath,
    templatesRootPath,
} from "core/util/routes";
import { AppProps } from "single-spa";

interface RootProps extends AppProps {
    hasError: boolean;
}

interface RootState {
    error?: Error;
    errorInfo?: ErrorInfo;
}

export default class Root extends React.Component<
    RootProps & AppProps,
    RootState
> {
    public constructor(props: RootProps & AppProps) {
        super(props);
        this.state = {};
    }

    public componentDidCatch(error: Error, info: ErrorInfo): void {
        this.setState({
            error: error,
            errorInfo: info,
        });
    }

    public render(): ReactElement {
        // TODO Add better error handling.
        if (this.state.errorInfo) {
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }

        return (
            <Router>
                <Switch>
                    <Route
                        path="/"
                        exact
                        render={(): ReactElement => {
                            return <Redirect to={rootPath} />;
                        }}
                    />
                    <Route
                        path={templatesRootPath}
                        component={TemplateRouter}
                    />
                    <PrivateRoute
                        path={scenesRootPath}
                        component={SceneRouter}
                    />
                    <PrivateRoute path={rootPath} component={AdminRouter} />
                    <Route
                        path={registerRootPath}
                        render={(): ReactElement => {
                            return <Redirect to={registerDevicePath} />;
                        }}
                    />
                    <Route component={Page404} />
                </Switch>
            </Router>
        );
    }
}
