import { FetchData } from "@castia/sdk";
import useFetch from "hooks/useFetch";
import environment from "core/util/environment";
import { DeviceInfo } from "model/DeviceInfo";

export function useDeviceInfo(deviceId: string): FetchData<DeviceInfo> {
    return useFetch<DeviceInfo>(
        `${environment.apiBaseUrl}/api/v1/devices/${deviceId}/info`
    );
}
