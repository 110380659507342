import { FetchData } from "hooks/useFetch";
import { KeycloakRealm } from "model/KeycloakRealm";
import { useEventListener, useFetch } from "@castia/sdk";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";

export function useFetchOneKeycloakRealm(
    keycloakRealmId: string
): FetchData<KeycloakRealm> {
    const fetch = useFetch<KeycloakRealm>(
        `${environment.apiBaseUrl}/api/v1/keycloak-realms/${keycloakRealmId}`
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.KEYCLOAK_REALMS_CHANGED, eventCallback);

    return fetch;
}
