import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

/**
 * Call to add scenes to a channel
 * @param channelId
 */
function useAddScenesToChannel(channelId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/channels/${channelId}/scenes`,
        "POST",
        LocalEvents.CHANNELS_SCENES_ADDED
    );
}

export default useAddScenesToChannel;
