import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { tenantProfilePath } from "core/util/routes";
import { FaEdit } from "react-icons/fa";
import React from "react";

interface TenantProfileEditButtonProps {
    tenantProfileId: string;
}

export function TenantProfileEditButton(props: TenantProfileEditButtonProps) {
    return (
        <LinkButton
            to={`${tenantProfilePath}/${props.tenantProfileId}/edit`}
            variant="primary"
            size="md"
        >
            <FaEdit />
        </LinkButton>
    );
}
