import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import Device from "model/Device";
import { Schedule } from "model/Schedule";

interface ChannelUsage {
    schedules: Schedule[];
    devices: Device[];
}

export function useFetchChannelUsage(
    channelId: string
): FetchData<ChannelUsage> {
    return useFetch<ChannelUsage>(
        `${environment.apiBaseUrl}/api/v1/channels/${channelId}/usage`
    );
}
