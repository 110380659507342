import React from "react";
import { NewsItem } from "model/NewsItem";
import draftToHtml from "draftjs-to-html";
import Modal from "core/components/UI/Modal/Modal";

interface NewsShowModalProps {
    show: boolean;
    setShowModal: (show: boolean) => void;
    newsItem?: NewsItem;
}

export function NewsShowModal(props: NewsShowModalProps) {
    if (!props.newsItem) {
        return <></>;
    }

    return (
        <Modal
            show={props.show}
            onHide={(): void => props.setShowModal(false)}
            size={"xl"}
            title={props.newsItem.title}
        >
            <Modal.Body>
                <div
                    dangerouslySetInnerHTML={{
                        __html: draftToHtml(props.newsItem?.description),
                    }}
                ></div>
            </Modal.Body>
        </Modal>
    );
}
