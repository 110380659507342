import { NewsForm, NewsFormFields } from "news/NewsForm";
import { useHistory } from "react-router-dom";
import { newsPath } from "core/util/routes";
import { useCreateNewsItem } from "hooks/api/news/useCreateNewsItem";
import React from "react";

export function NewsCreateForm() {
    const { sendRequest, isLoading } = useCreateNewsItem();
    const history = useHistory();

    async function onSubmit(data: NewsFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            history.push(`${newsPath}`);
        }
    }

    return <NewsForm handleSubmit={onSubmit} isLoading={isLoading} />;
}
