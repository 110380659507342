import React, { ReactElement } from "react";
import { PropsWithChildren } from "react";

interface ShowIfProps {
    condition?: boolean;
}

export function ShowIf(props: PropsWithChildren<ShowIfProps>): ReactElement {
    if (props.condition) {
        return <>{props.children}</>;
    }

    return <></>;
}
