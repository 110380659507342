import { ScheduleEvent } from "model/ScheduleEvent";
import useFetchAllChannels from "hooks/api/channel/useFetchAllChannels";
import { EventFormType } from "schedule/schedule-event/components/EventForm";
import Modal from "core/components/UI/Modal/Modal";
import React, { useEffect } from "react";
import { useUpdateScheduleEvent } from "hooks/api/schedule-event/useUpdateScheduleEvent";
import { EventUpdateFormContainer } from "schedule/schedule-event/components/EventUpdateFormContainer";
import { EventValidationResult } from "schedule/schedule-event/components/FullScheduleCalendar";
import { toast } from "react-toastify";

interface EventUpdateModalProps {
    show: boolean;
    closeModal: () => void;
    scheduleId: string;
    scheduleEventId: string;
    updateEventInCalendar: (event: ScheduleEvent) => void;
    validateEvent: (
        event: EventFormType,
        existingId: string
    ) => EventValidationResult;
}

export function EventUpdateModal(props: EventUpdateModalProps) {
    const channels = useFetchAllChannels();
    const req = useUpdateScheduleEvent(props.scheduleId, props.scheduleEventId);

    useEffect(() => {
        if (req.error) {
            toast.error(
                "Something went wrong while trying to create the event: " +
                    req.error.message
            );
        }
    }, [req.error]);

    async function updateEvent(data: EventFormType) {
        const startDateTime = data.startDate + "T" + data.startTime + "Z";
        const endDateTime = data.endDate + "T" + data.endTime + "Z";
        const duration =
            new Date(endDateTime).getTime() - new Date(startDateTime).getTime();

        let request = {
            channel: data.channel,
            startDateTime: startDateTime,
            duration: duration,
            repeating: data.repeating,
            repeatingEnd: data.repeatingEnd,
        };

        if (data.repeating) {
            request = Object.assign(request, {
                interval: data.interval,
                frequency: data.frequency,
                byWeekDay: data.byweekday,
                byMonthDay: data.bymonthday,
                byMonth: data.bymonth,
                bySetPos: data.bysetpos,
            });
        }

        const result = await req.sendRequest(request);

        props.updateEventInCalendar(result);
        props.closeModal();
    }

    return (
        <Modal
            show={props.show}
            onHide={props.closeModal}
            size={"lg"}
            title="Update Event"
        >
            {channels.response && props.show && (
                <EventUpdateFormContainer
                    closeModal={props.closeModal}
                    channels={channels.response}
                    handleSubmit={updateEvent}
                    scheduleId={props.scheduleId}
                    scheduleEventId={props.scheduleEventId}
                    validateEvent={props.validateEvent}
                />
            )}
        </Modal>
    );
}
