import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { useCallback } from "react";
import { FetchData, Paginated, useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { GroupUser } from "model/GroupUser";

export const userPageSize = 10;

export function useFetchGroupUsers(
    groupId: string,
    urlSearchParams?: Record<string, string>
): FetchData<Paginated<GroupUser>> {
    const fetch = useFetchPaginated<GroupUser>(
        `${environment.apiBaseUrl}/api/v2/groups/${groupId}/users`,
        userPageSize,
        1,
        urlSearchParams
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.USERS_CHANGED, eventCallback);

    return fetch;
}
