import * as React from "react";
import { ReactElement } from "react";
import DeviceListContainer from "device/list/DeviceListContainer";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { DeviceRegistrationButton } from "device/button/DeviceRegistrationButton";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { GroupRole } from "core/auth/GroupRole";

function DeviceListPage(): ReactElement {
    const organizationContext = useOrganizationContext();

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Devices</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <HasGroupRole
                        groupId={organizationContext}
                        role={GroupRole.EDITOR}
                    >
                        <DeviceRegistrationButton />
                    </HasGroupRole>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <DeviceListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}

export default DeviceListPage;
