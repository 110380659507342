import React, { ReactElement } from "react";

import { Card, Col, Container, Row } from "react-bootstrap";
import { FaCalendarDay, FaLaptop, FaTv, FaImage } from "react-icons/fa";
import { SummaryCard } from "home/SummaryCard";
import { useDashboardData } from "hooks/api/dashboard/useDashboardData";
import { Link } from "react-router-dom";
import { devicesPath, registerDevicePath } from "core/util/routes";
import { NewsCard } from "home/NewsCard";
import styles from "./HomePage.scss";

function HomePage(): ReactElement {
    const { response } = useDashboardData();

    return (
        <Container className={"p-4"}>
            <Row className="mb-2">
                <SummaryCard
                    bg={"primary"}
                    title={"Devices"}
                    value={response ? response.deviceCount : ""}
                    icon={FaLaptop}
                />
                <SummaryCard
                    bg={"warning"}
                    title={"Channels"}
                    value={response ? response.channelCount : ""}
                    icon={FaTv}
                />
                <SummaryCard
                    bg={"danger"}
                    title={"Scenes"}
                    value={response ? response.sceneCount : ""}
                    icon={FaImage}
                />
                <SummaryCard
                    bg={"success"}
                    title={"Schedules"}
                    value={response ? response.scheduleCount : ""}
                    icon={FaCalendarDay}
                />
            </Row>
            <Row>
                <Col md={3}>
                    <Card>
                        <Card.Body>
                            <Card.Title className={styles.cardTitle}>Quick links</Card.Title>
                            <hr />
                            <ul>
                                <li>
                                    <Link to={registerDevicePath}>Register New Device</Link>
                                </li>
                                <li>
                                    <Link to={devicesPath}>Device List</Link>
                                </li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
                <NewsCard />
            </Row>
        </Container>
    );
}

export default HomePage;
