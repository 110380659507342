import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import { PersistData } from "@castia/sdk";

export function usePatchTenantProfile(tenantProfileId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/tenant-profiles/${tenantProfileId}`,
        "PATCH"
    );
}
