import { FolderType } from "model/FolderType";
import useFetch from "hooks/useFetch";
import environment from "core/util/environment";
import { Folder } from "model/Folder";
import { FetchData } from "@castia/sdk";

export function useFetchAllFolders(folderType: FolderType): FetchData<Folder[]> {
    return useFetch<Folder[]>(
        `${environment.apiBaseUrl}/api/v1/folders`,
        new URLSearchParams({
            type: folderType,
        })
    );
}
