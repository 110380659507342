import React, { ReactElement, useEffect, useState } from "react";
import { Video } from "model/Video";
import useFetchAllVideos, { videoPageSize } from "hooks/api/media/video/useFetchAllVideos";
import { Paging } from "@castia/sdk";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { VideoOverview } from "video/VideoOverview";

export function VideoLibrary(): ReactElement {
    const [videos, setVideos] = useState<Video[]>(null);
    const { response, isLoading, error, refreshData } = useFetchAllVideos();

    // Set the videos in the state. This ensures that reloads caused by newly uploaded videos don't cause a flashing
    // effect caused by the ResponseErrorHandler component.
    useEffect((): void => {
        if (response) {
            setVideos(response.items);
        }
    }, [response]);

    if (videos) {
        return (
            <div>
                <VideoOverview videos={videos} />
                <div className="d-flex justify-content-end">
                    <Paging
                        currentPage={response.meta.currentPage}
                        onClick={(page): void => {
                            refreshData(
                                new URLSearchParams({
                                    page: String(page),
                                    limit: String(videoPageSize),
                                })
                            );
                        }}
                        totalPages={response.meta.totalPages}
                        showMax={10}
                    />
                </div>
            </div>
        );
    }

    return <ResponseErrorHandler isLoading={isLoading} error={error} />;
}