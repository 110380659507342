import { useHistory } from "react-router-dom";
import { NewsForm, NewsFormFields } from "news/NewsForm";
import { newsPath } from "core/util/routes";
import React from "react";
import { NewsItem } from "model/NewsItem";
import { useUpdateNewsItem } from "hooks/api/news/useUpdateNewsItem";

interface NewsEditFormProps {
    newsItem: NewsItem;
}

export function NewsEditForm(props: NewsEditFormProps) {
    const { sendRequest, isLoading } = useUpdateNewsItem(props.newsItem.id);
    const history = useHistory();

    async function onSubmit(data: NewsFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            history.push(`${newsPath}`);
        }
    }

    return (
        <NewsForm
            handleSubmit={onSubmit}
            isLoading={isLoading}
            existingNewsItem={props.newsItem}
        />
    );
}
