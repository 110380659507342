import React, { ReactElement, useState } from "react";
import Button from "core/components/UI/Button/Button";
import Modal from "core/components/UI/Modal/Modal";
import useDeleteImages from "hooks/api/media/image/useDeleteImages";
import Image from "model/Image";

interface ImageDeleteButtonProps {
    images: Image[];
}

/**
 * Button which deletes a scene via a 'are you sure' popup.
 * @param props
 * @constructor
 */
function ImageDeleteButton(props: ImageDeleteButtonProps): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const { sendRequest } = useDeleteImages();

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    const confirmDelete = (): void => {
        sendRequest({
            ids: props.images.map((image): string => image.id),
        });
        closeModal();
    };

    return (
        <>
            <div className="d-grid">
                <Button
                    variant="danger"
                    size="sm"
                    onClick={openModal}
                    data-cy="image-delete-button"
                >
                    Delete
                </Button>
            </div>
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Are you sure?"
            >
                <Modal.Body>
                    Are you sure you want to delete the following images:
                    <ul>
                        {props.images.map((image): ReactElement => {
                            return <li key={image.id}>{image.name}</li>;
                        })}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        variant={"danger"}
                        onClick={confirmDelete}
                        data-cy="image-delete-confirm-button"
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ImageDeleteButton;
