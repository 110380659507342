/**
 * Parse the given string as JSON or return the string if it is not json.
 * @param value
 */
function parseJsonOrText(value: string): string {
    try {
        return JSON.parse(value);
    } catch (error) {
        return value;
    }
}

export default parseJsonOrText;
