import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";

function usePublishChanges(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/devices/publish`,
        "POST"
    );
}

export default usePublishChanges;
