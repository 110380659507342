import React, { ReactElement } from "react";
import styles from "core/components/Fullscreen/Fullscreen.scss";

class Fullscreen extends React.Component {
    public render(): ReactElement {
        return <div className={styles.fullscreen}>{this.props.children}</div>;
    }
}

export default Fullscreen;
