import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Modal } from "react-bootstrap";
import Button from "core/components/UI/Button/Button";
import React from "react";
import { useInstallPluginForGroup } from "group/hooks/useInstallPluginForGroup";
import { AllowedPluginMultiSelectField } from "core/components/fields/AllowedPluginMultiSelectField";
import { SelectInputChoice } from "core/components/input/SelectInput";

interface InstallPluginForGroupFormData {
    plugins: string;
}

interface InstallPluginForGroupModalBodyProps {
    onClose: () => void;
    groupId: string;
}

const schema = yup.object({
    plugins: yup
        .array()
        .of(yup.string())
        .transform((groups) => {
            return groups && groups.length > 0
                ? groups.map((group: SelectInputChoice) => group.value)
                : undefined;
        }),
});

export function InstallPluginForGroupModalBody(
    props: InstallPluginForGroupModalBodyProps
) {
    const form = useForm<InstallPluginForGroupFormData>({
        resolver: yupResolver(schema),
    });
    const installPluginForGroup = useInstallPluginForGroup();

    async function onSubmit(
        data: InstallPluginForGroupFormData
    ): Promise<void> {
        await installPluginForGroup.sendRequest({
            ...data,
            groupId: props.groupId,
        });
    }

    return (
        <>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Body>
                    <FormProvider {...form}>
                        <AllowedPluginMultiSelectField
                            groupId={props.groupId}
                            helpText=""
                        />
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={installPluginForGroup.isLoading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </>
    );
}
