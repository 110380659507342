import useFetch, { FetchData } from "hooks/useFetch";
import Device from "model/Device";
import environment from "core/util/environment";

function useFetchBySchedule(scheduleId: string): FetchData<Device[]> {
    return useFetch<Device[]>(
        `${environment.apiBaseUrl}/api/v1/devices?contentId=${scheduleId}`
    );
}

export default useFetchBySchedule;
