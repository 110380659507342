import useFetch, { FetchData } from "hooks/useFetch";
import Scene from "model/Scene";
import environment from "core/util/environment";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";

/**
 * Call to fetch a scene and its content
 * @param sceneId
 */
function useFetchOneScene(sceneId: string): FetchData<Scene> {
    const fetch = useFetch<Scene>(
        `${environment.apiBaseUrl}/api/v1/scenes/${sceneId}`
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.SCENE_SETTINGS_CHANGED, eventCallback);
    useEventListener(LocalEvents.SCENES_CHANGED, eventCallback);

    return fetch;
}

export default useFetchOneScene;
