import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useFetchOneUser } from "user/hooks/useFetchOneUser";
import { ResponseErrorHandler } from "@castia/sdk";
import { UserEditForm } from "user/edit/UserEditForm";

interface UserEditPageParams {
    userId: string;
}

export function UserEditPage(): ReactElement {
    const { userId } = useParams<UserEditPageParams>();
    const { response, isLoading, error } = useFetchOneUser(userId);

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Edit User</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && <UserEditForm user={response} />}
                </ResponseErrorHandler>
            </PageLayout.Body>
        </PageLayout>
    );
}
