import { Frequency, RRule, Weekday, WeekdayStr } from "rrule";

export function createRrule(
    frequency: number | Frequency,
    interval: number,
    byweekday: string[],
    bymonthday: number[],
    startDate: string,
    startTime: string,
    repeatingEnd: string,
    repeating: boolean,
    bysetpos: number
): RRule | null {
    if (!!repeating && typeof frequency === "number" && interval && (byweekday || bymonthday) && startDate) {
        return new RRule({
            freq: frequency,
            interval: interval,
            byweekday: byweekday?.map((weekday: WeekdayStr) =>
                Weekday.fromStr(weekday)
            ),
            bymonthday: bymonthday,
            dtstart: startDate
                ? new Date(startDate + "T" + startTime + "Z")
                : null,
            until: repeatingEnd ? new Date(repeatingEnd) : null,
            bysetpos: bysetpos || null,
        });
    }
    return null;
}
