import React, { ReactElement, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaFolderOpen } from "react-icons/fa";
import { FolderMoveFormData, FolderMoveModalBody } from "core/folder/FolderMoveButton/FolderMoveModalBody";
import { FolderType } from "model/FolderType";
import { PersistData } from "hooks/useFetchPersist";

interface FolderMoveButtonProps {
    folderType: FolderType;
    onSave: (data: FolderMoveFormData) => void;
    persistData: PersistData;
    currentFolderId?: string;
}

export function FolderMoveButton(props: FolderMoveButtonProps): ReactElement {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (props.persistData.response) {
            setShowModal(false);
        }
    }, [props.persistData.response]);

    return (
        <>
            <Button
                title="Move to folder"
                variant="dark"
                onClick={() => setShowModal(true)}
            >
                <FaFolderOpen />
            </Button>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
                title="Move to folder"
            >
                {showModal && (
                    <FolderMoveModalBody
                        show={showModal}
                        onClose={() => setShowModal(false)}
                        folderType={props.folderType}
                        persistData={props.persistData}
                        onSave={props.onSave}
                        currentFolderId={props.currentFolderId}
                    />
                )}
            </Modal>
        </>
    );
}
