import React, { ReactElement, useState } from "react";
import { FaTrash } from "react-icons/fa";
import Loader from "core/components/UI/Loader/Loader";
import { Redirect } from "react-router";
import Button from "core/components/UI/Button/Button";
import Modal from "core/components/UI/Modal/Modal";
import DeleteChannelModalBody from "channel/button/DeleteChannelModalBody";
import useDeleteChannel from "hooks/api/channel/useDeleteChannel";
import { channelsPath } from "core/util/routes";
import { Dropdown } from "react-bootstrap";

interface DeleteChannelButtonProps {
    channelId: string;
    variant: "button" | "dropdown";
}

/**
 * Button to delete the channel with the given channelId. Shows a Are you sure modal before deletion.
 * @param props
 * @constructor
 */
function DeleteChannelButton(props: DeleteChannelButtonProps): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { sendRequest, response, isLoading } = useDeleteChannel(
        props.channelId
    );

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    const confirmDelete = (): void => {
        setLoading(true);
        sendRequest();
    };

    if (response && !isLoading) {
        return <Redirect to={channelsPath} />;
    }

    return (
        <>
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Are you sure?"
            >
                <Modal.Body>
                    {loading && <Loader />}
                    {!loading && showModal && (
                        <DeleteChannelModalBody channelId={props.channelId} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant={"danger"} onClick={confirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {props.variant === "button" && (
                <Button variant="danger" onClick={openModal} title="Delete channel">
                    <FaTrash />
                </Button>
            )}
            {props.variant === "dropdown" && (
                <Dropdown.Item
                    onClick={openModal}
                    className="d-flex align-items-center"
                >
                    <FaTrash color="var(--bs-danger)" />
                    &nbsp;Delete channel
                </Dropdown.Item>
            )}
        </>
    );
}

export default DeleteChannelButton;
