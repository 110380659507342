import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useFetchOneGroup } from "group/hooks/useFetchOneGroup";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import PageLayoutHeader from "core/components/UI/PageLayout/PageLayoutHeader/PageLayoutHeader";
import PageLayoutHeaderTitle from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderTitle/PageLayoutHeaderTitle";
import PageLayoutHeaderSecondary from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/PageLayoutHeaderSecondary";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import PageLayoutBody from "core/components/UI/PageLayout/PageLayoutBody/PageLayoutBody";
import { GroupEditButton } from "group/button/GroupEditButton";
import { GroupDeleteButton } from "group/button/GroupDeleteButton";
import { GroupUsersCard } from "group/show/GroupUsersCard";
import { GroupOverviewCard } from "group/show/GroupOverviewCard";
import useAuthentication from "hooks/useAuthentication";
import { ShowIf } from "core/util/ShowIf";
import { useIsAdmin } from "core/auth/useIsAdmin";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { GroupPluginsCard } from "group/show/GroupPluginsCard";

interface GroupPageParams {
    groupId: string;
}

export function GroupShowPage(): ReactElement {
    const { groupId } = useParams<GroupPageParams>();
    const { response, isLoading, error } = useFetchOneGroup(groupId);
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();

    return (
        <PageLayout>
            <ResponseErrorHandler isLoading={isLoading} error={error}>
                {response && (
                    <>
                        <PageLayoutHeader>
                            <PageLayoutHeaderTitle>
                                {response.name}
                            </PageLayoutHeaderTitle>
                            <PageLayoutHeaderSecondary>
                                <HasGroupRole
                                    role={GroupRole.ORGANIZATION_ADMIN}
                                    groupId={response.id}
                                >
                                    <ButtonGroup>
                                        <GroupEditButton groupId={groupId} />
                                        <ShowIf
                                            condition={
                                                isAdmin ||
                                                !auth
                                                    .getGroups()
                                                    .find(
                                                        (group) =>
                                                            group.id === groupId
                                                    )
                                            }
                                        >
                                            <GroupDeleteButton
                                                groupId={groupId}
                                            />
                                        </ShowIf>
                                    </ButtonGroup>
                                </HasGroupRole>
                            </PageLayoutHeaderSecondary>
                        </PageLayoutHeader>
                        <PageLayoutBody>
                            <Row>
                                <Col md={4}>
                                    <GroupOverviewCard group={response} />
                                </Col>
                                <Col md={8}>
                                    <GroupUsersCard group={response} />
                                </Col>
                            </Row>
                            <HasGroupRole groupId={response.id} role={GroupRole.ORGANIZATION_ADMIN}>
                                <Row className="mt-2">
                                    <Col md={8} className="offset-md-4">
                                        <GroupPluginsCard groupId={response.id} />
                                    </Col>
                                </Row>
                            </HasGroupRole>
                        </PageLayoutBody>
                    </>
                )}
            </ResponseErrorHandler>
        </PageLayout>
    );
}
