import { InputProps } from "core/components/input/InputProps";
import { useFormContext } from "react-hook-form";
import { Form } from "react-bootstrap";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import React, { ReactElement } from "react";

interface BooleanInputProps extends InputProps {
    labelPosition?: "above" | "right";
}

export function BooleanInput(props: BooleanInputProps): ReactElement {
    const form = useFormContext();
    const { errors } = form.formState;

    return (
        <div className={props.className ? props.className : "mb-3"}>
            {props.label &&
                (props.labelPosition === "above" || !props.labelPosition) && (
                    <Form.Label>{props.label}</Form.Label>
                )}
            <Form.Check
                id={`checkbox-${props.name}`}
                name={props.name}
                type="checkbox"
                label={
                    props.label && props.labelPosition === "right"
                        ? props.label
                        : undefined
                }
                {...form.register(props.name, props.registerOptions)}
            />
            {errors[props.name] && (
                <FormInputError>{errors[props.name].message}</FormInputError>
            )}
        </div>
    );
}
