import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";
import PageLayoutHeader from "core/components/UI/PageLayout/PageLayoutHeader/PageLayoutHeader";
import PageLayoutBody from "core/components/UI/PageLayout/PageLayoutBody/PageLayoutBody";

/**
 * Layout for the list pages. Contains several sub-components which are styled correctly.
 * @param props
 */
function PageLayout(props: React.PropsWithChildren<unknown>): ReactElement {
    return <Container>{props.children}</Container>;
}

PageLayout.Header = PageLayoutHeader;
PageLayout.Body = PageLayoutBody;
export default PageLayout;
