import { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import Image from "model/Image";
import { useCallback } from "react";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { Paginated } from "@castia/sdk";
import { useFetchPaginated } from "hooks/useFetchPaginated";

export const imagePageSize = 18;

/**
 * Fetch the image list.
 */
function useFetchAllImages(): FetchData<Paginated<Image>> {
    const fetch = useFetchPaginated<Image>(
        `${environment.apiBaseUrl}/api/v1/media/images`,
        imagePageSize,
        1
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.IMAGES_CHANGED, eventCallback);

    return fetch;
}

export default useFetchAllImages;
