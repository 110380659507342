import React, { ReactElement } from "react";
import Device from "model/Device";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import {
    Button,
    Container,
    Form,
    FormGroup,
} from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import useUpdateDevice from "hooks/api/device/useUpdateDevice";
import { SimpleFunction } from "core/util/types/FunctionTypes";
import styles from "device/edit/DeviceConfigForm.scss";
import { ContentInput } from "core/components/input/ContentInput";

interface DeviceConfigFormProps {
    device: Device;
    refreshData: SimpleFunction;
}

interface DeviceForm {
    name: string;
    contentId: string;
    contentType: string;
}

/**
 * @constructor
 */
function DeviceConfigForm(props: DeviceConfigFormProps): ReactElement {
    const { sendRequest } = useUpdateDevice(props.device.id);

    const form = useForm<DeviceForm>({
        defaultValues: {
            name: props.device.name,
            contentId: props.device.contentId,
        },
    });
    const { errors } = form.formState;

    async function onSubmit(data: DeviceForm): Promise<void> {
        const result = await sendRequest({
            name: data.name,
            contentId: data.contentId,
            contentType: data.contentType,
        });
        if (result) {
            props.refreshData();
        }
    }

    return (
        <>
            <Container>
                <FormProvider {...form}>
                    <Form onSubmit={form.handleSubmit(onSubmit)}>
                        <FormGroup>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="name"
                                placeholder={props.device.name}
                                {...form.register("name", {
                                    required: "Name is a required field",
                                })}
                            />
                            {errors.name && (
                                <FormInputError>
                                    {errors.name.message}
                                </FormInputError>
                            )}
                            <Form.Label className="mt-2">
                                Content Type
                            </Form.Label>
                            <br />
                            <ContentInput
                                initialContentType={
                                    props.device.contentType || "SCHEDULE"
                                }
                                initialContentId={props.device.contentId}
                            />
                        </FormGroup>
                        <Button
                            className={styles.save}
                            variant="primary"
                            type="submit"
                        >
                            Save
                        </Button>
                    </Form>
                </FormProvider>
            </Container>
        </>
    );
}

export default DeviceConfigForm;
