import React, { ReactElement } from "react";
import { useForm } from "react-hook-form";
import styles from "image/list/ImageUploadButton.scss";
import { classNames } from "@castia/sdk";
import useCreateImage from "hooks/api/media/image/useCreateImage";

interface ImageUploadFormData {
    files: FileList;
}

interface ImageUploadButtonProps {
    setUploading: (uploading: boolean) => void;
    setCurrentFileUpload: (fileName: string) => void;
}

export function ImageUploadButton(props: ImageUploadButtonProps): ReactElement {
    const { register, handleSubmit } = useForm<ImageUploadFormData>();
    const { sendRequest } = useCreateImage();

    async function onSubmit(data: ImageUploadFormData): Promise<void> {
        props.setUploading(true);
        const files = Array.from(data.files);
        for (const file in files) {
            props.setCurrentFileUpload(files[file].name);
            await sendRequest({
                file: files[file],
            });
        }
        props.setUploading(false);
        props.setCurrentFileUpload(null);
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="align-self-center d-grid flex-grow-1"
            >
                <input
                    id={"file-upload-button"}
                    {...register("files", {
                        onChange: () => {
                            handleSubmit(onSubmit)();
                        }
                    })}
                    type="file"
                    accept="image/*"
                    className="d-none"
                    multiple={true}
                />

                <label
                    htmlFor={"file-upload-button"}
                    className={classNames(
                        "mb-0 btn btn-primary btn-sm btn-block",
                        styles.button
                    )}
                    role="button"
                >
                    Upload
                </label>
            </form>
        </>
    );
}
