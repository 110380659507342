import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { FontCreateForm } from "font/create/FontCreateForm";

export function FontCreatePage() {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Create Font</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <FontCreateForm />
            </PageLayout.Body>
        </PageLayout>
    );
}
