import React, { ReactElement } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { FontLibrary } from "font/list/FontLibrary";
import styles from "./FontPage.scss";
import { FontCreateButton } from "font/create/FontCreateButton";

export function FontPage(): ReactElement {
    const organizationContext = useOrganizationContext();

    return (
        <Container>
            <Row>
                <Col lg={8} md={6} sm={6}>
                    <h1>Fonts</h1>
                </Col>
                <Col className={styles.buttonCol} lg={4} md={6} sm={6}>
                    <HasGroupRole
                        groupId={organizationContext}
                        role={GroupRole.EDITOR}
                    >
                        <FontCreateButton />
                    </HasGroupRole>
                </Col>
            </Row>
            <FontLibrary />
        </Container>
    );
}
