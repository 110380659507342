import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";

/**
 * Call to create a new scene.
 */
function useCreateScene(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/scenes`,
        "POST",
    );
}

export default useCreateScene;
