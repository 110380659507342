import { FullCalendarRepeatingEvent } from "schedule/schedule-event/model/FullCalendarRepeatingEvent";
import { Frequency, rrulestr } from "rrule";
import { EventFormType } from "schedule/schedule-event/components/EventForm";
import { addYears, startOfDay } from "date-fns";
import { createRrule } from "schedule/schedule-event/validator/createRrule";
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping";
import { FullCalendarEvent } from "schedule/schedule-event/model/FullCalendarEvent";

interface ExpandedEvent {
    id: string;
    start: Date;
    end: Date;
    eventId: string;
    channelTitle?: string;
}

export interface Collisions {
    blocking: ExpandedEvent[];
    soft: ExpandedEvent[];
}

const YEARS_IN_FUTURE = 2;

export function getCollisions(
    events: (FullCalendarRepeatingEvent | FullCalendarEvent)[],
    newEvent: EventFormType,
    updateEventId?: string
): Collisions {
    // When checking for an event update, filter out the event we're updating.
    if (updateEventId) {
        events = events.filter((event) => {
            return event.id !== updateEventId;
        });
    }

    return {
        blocking: getBlockingCollisions(events, newEvent),
        soft: getSoftCollisions(events, newEvent),
    };
}

export function getBlockingCollisions(
    events: (FullCalendarRepeatingEvent | FullCalendarEvent)[],
    newEvent: EventFormType
): ExpandedEvent[] {
    const potentialCollisionEvents: ExpandedEvent[] = [];
    if (newEvent.repeating) {
        const repeatingEvents = events
            .filter((event) => "rrule" in event)
            .filter((event: FullCalendarRepeatingEvent) => {
                const rrule = rrulestr(event.rrule);
                return (
                    rrule.options.freq === newEvent.frequency &&
                    rrule.options.interval === newEvent.interval
                );
            });

        potentialCollisionEvents.push(
            ...expandRecurringEvents(
                repeatingEvents as FullCalendarRepeatingEvent[]
            )
        );
    } else {
        const nonRepeatingEvents = events.filter(
            (event) => !("rrule" in event)
        );
        potentialCollisionEvents.push(
            ...expandNonRecurringEvents(
                nonRepeatingEvents as FullCalendarEvent[]
            )
        );
    }

    return calculateCollisions(potentialCollisionEvents, newEvent);
}

/**
 * Get all soft collisions events. A soft collision is a collision of two events that is allowed and automatically resolved
 * in runtime by the collisions resolution logic. This is used to warn users about the overlap and corresponding behaviour.
 */
export function getSoftCollisions(
    events: (FullCalendarRepeatingEvent | FullCalendarEvent)[],
    newEvent: EventFormType
): ExpandedEvent[] {
    const potentialCollisionEvents: ExpandedEvent[] = [];
    const repeatingEvents = events
        .filter((event) => "rrule" in event)
        .filter((event: FullCalendarRepeatingEvent) => {
            const rrule = rrulestr(event.rrule);
            return (
                !newEvent.repeating ||
                rrule.options.freq !== newEvent.frequency ||
                rrule.options.interval !== newEvent.interval
            );
        });

    potentialCollisionEvents.push(
        ...expandRecurringEvents(
            repeatingEvents as FullCalendarRepeatingEvent[]
        )
    );

    if (newEvent.repeating) {
        const nonRepeatingEvents = events.filter(
            (event) => !("rrule" in event)
        );
        potentialCollisionEvents.push(
            ...expandNonRecurringEvents(
                nonRepeatingEvents as FullCalendarEvent[]
            )
        );
    }

    return calculateCollisions(potentialCollisionEvents, newEvent);
}

function calculateCollisions(
    potentialCollisionEvents: ExpandedEvent[],
    newEvent: EventFormType
) {
    const expandedNewEvent = eventFormTypeToExpandedEvent(newEvent);

    return potentialCollisionEvents.filter((potentialCollisionEvent) => {
        if (!Array.isArray(expandedNewEvent)) {
            return areIntervalsOverlapping(
                {
                    start: expandedNewEvent.start,
                    end: expandedNewEvent.end,
                },
                {
                    start: potentialCollisionEvent.start,
                    end: potentialCollisionEvent.end,
                }
            );
        }
        return expandedNewEvent.some((eventOccurrence) => {
            return areIntervalsOverlapping(
                {
                    start: eventOccurrence.start,
                    end: eventOccurrence.end,
                },
                {
                    start: potentialCollisionEvent.start,
                    end: potentialCollisionEvent.end,
                }
            );
        });
    });
}

function expandRecurringEvents(
    events: FullCalendarRepeatingEvent[]
): ExpandedEvent[] {
    const expandedEvents: ExpandedEvent[] = [];

    events.forEach((event) => {
        const rule = rrulestr(event.rrule);
        const occurrences = rule.between(
            startOfDay(new Date(Date.now())),
            addYears(new Date(Date.now()), YEARS_IN_FUTURE),
            true
        );

        occurrences.forEach((occurrence) => {
            expandedEvents.push({
                ...event,
                id: `${event.id}_${occurrence.toISOString()}`,
                start: occurrence,
                end: new Date(
                    occurrence.getTime() + event.duration.milliseconds
                ),
                eventId: event.id,
                channelTitle: event.channel?.title,
            });
        });
    });

    return expandedEvents;
}

function expandNonRecurringEvents(
    events: FullCalendarEvent[]
): ExpandedEvent[] {
    return events.map((event) => {
        return {
            id: `${event.id}_${event.start.toISOString()}`,
            start: event.start,
            end: event.end,
            eventId: event.id,
        };
    });
}

/**
 * Expand the EventFormType event to an ExpandedEvent (or array) instance.
 * This allows interoperable comparisons between these and the calendar events.
 * @param event
 */
function eventFormTypeToExpandedEvent(
    event: EventFormType
): ExpandedEvent | ExpandedEvent[] {
    if (event.repeating) {
        const rule = createRrule(
            event.frequency as Frequency,
            event.interval,
            event.byweekday,
            event.bymonthday,
            event.startDate,
            event.startTime as string,
            event.repeatingEnd,
            event.repeating,
            event.bysetpos
        );
        const startDateTime = event.startDate + "T" + event.startTime + "Z";
        const endDateTime = event.endDate + "T" + event.endTime + "Z";
        const duration =
            new Date(endDateTime).getTime() - new Date(startDateTime).getTime();
        const occurrences = rule.between(
            startOfDay(new Date(Date.now())),
            addYears(new Date(Date.now()), YEARS_IN_FUTURE),
            true
        );
        return occurrences.map((occurrence) => {
            return {
                ...event,
                id: "new",
                start: occurrence,
                end: new Date(occurrence.getTime() + duration),
                eventId: "new",
            };
        });
    }

    return {
        id: "new",
        start: new Date(event.startDate + "T" + event.startTime + "Z"),
        end: new Date(event.endDate + "T" + event.endTime + "Z"),
        eventId: "new",
    };
}
