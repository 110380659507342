import React, { ReactElement, useState } from "react";
import { FolderType } from "model/FolderType";
import { Button } from "react-bootstrap";
import { FaFolderPlus } from "react-icons/fa";
import { FolderCreateModal } from "core/folder/FolderCreateButton/FolderCreateModal";
import { useQuery } from "hooks/useQuery";
import { FolderIdParams } from "core/folder/FolderIdParams";

interface FolderCreateButtonProps {
    type: FolderType;
    successEvent?: string;
}

export function FolderCreateButton(
    props: FolderCreateButtonProps
): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const { folderId } = useQuery<FolderIdParams>();

    return (
        <>
            <Button
                onClick={(): void => setShowModal(true)}
                variant="secondary"
                title="Create folder"
            >
                <FaFolderPlus />
            </Button>
            <FolderCreateModal
                folderType={props.type}
                show={showModal}
                onDone={() => setShowModal(false)}
                successEvent={props.successEvent}
                parentId={folderId}
            />
        </>
    );
}
