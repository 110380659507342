import * as React from "react";
import { ReactElement } from "react";
import styles from "scene/ScenesOverviewPage/components/SceneCreator/TemplatePreview.scss";
import { FaCheck } from "react-icons/fa";
import { Card, Col } from "react-bootstrap";
import { EnrichedTemplateConfiguration } from "core/components/Plugin/PluginContext/PluginContextProvider";
import placeholderThumbnail from "../../../../../assets/placeholder_thumbnail.png";

interface ScenePreviewProps {
    index: number;
    selected: boolean;
    template: EnrichedTemplateConfiguration;
    selectedCallback: (index: number, template: string) => void;
}

function TemplatePreview(props: ScenePreviewProps): ReactElement {
    const thumbnail = props.template.thumbnail
        ? `${props.template.resourceDirectory}/${props.template.thumbnail}`
        : placeholderThumbnail;

    return (
        <Col md={4} key={props.index} className={styles.previewBlock}>
            <div className={props.selected ? styles.selected : ""}>
                <Card>
                    <Card.Img variant="top" src={thumbnail} />
                    <Card.Body>
                        <Card.Text>{props.template.name}</Card.Text>
                    </Card.Body>
                    <div
                        className={styles.selectedOverlay}
                        onClick={(): void => {
                            props.selectedCallback(
                                props.index,
                                props.template.templateIdentifier
                            );
                        }}
                    >
                        <FaCheck className={styles.check} />
                    </div>
                </Card>
            </div>
        </Col>
    );
}

export default TemplatePreview;
