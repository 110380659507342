import Select from "react-select";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { Form } from "react-bootstrap";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import React, { ReactElement, useEffect, useState } from "react";
import { InputProps } from "core/components/input/InputProps";

export interface SelectInputChoice {
    value: string | number;
    label: string;
}

interface MultiSelectInputProps {
    choices: SelectInputChoice[];
    isMulti?: boolean;
    helpText?: string;
    noOptionsMessage?: string;
    label?: string;
    defaultValue?: string | number;
}

export function SelectInput2(
    props: InputProps & MultiSelectInputProps
): ReactElement {
    const form = useFormContext();
    const { errors } = form.formState;
    const controller = useController({
        name: props.name,
        rules: props.registerOptions,
    });
    const watchedValue = useWatch({
        name: props.name,
    });
    const [selectedOption, setSelectedOption] =
        useState<SelectInputChoice>(null);

    function onChange(
        newSelectedOption: SelectInputChoice | SelectInputChoice[]
    ) {
        if (Array.isArray(newSelectedOption)) {
            controller.field.onChange(
                newSelectedOption.map((option) => option.value)
            );
        } else {
            controller.field.onChange(newSelectedOption.value);
        }
    }

    useEffect(() => {
        if (watchedValue !== selectedOption?.value) {
            setSelectedOption(
                props.choices.find((choice) => choice.value === watchedValue)
            );
        }
    }, [watchedValue]);

    return (
        <div className={props.className ? props.className : "mb-3"}>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            <Select
                value={selectedOption}
                onChange={onChange}
                options={props.choices}
                isMulti={props.isMulti}
                noOptionsMessage={() =>
                    props.noOptionsMessage ?? "No options available"
                }
            />
            {props.helpText && <Form.Text muted>{props.helpText}</Form.Text>}
            {errors[props.name] && (
                <FormInputError>{errors[props.name].message}</FormInputError>
            )}
        </div>
    );
}
