import React, { ReactElement } from "react";
import { devicesPath } from "core/util/routes";
import LocalEvents from "events/LocalEvents";
import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";

interface DeviceDeleteButtonProps {
    deviceId: string;
    variant: "button" | "dropdown";
}

function DeviceDeleteButton(props: DeviceDeleteButtonProps): ReactElement {
    return <DeleteWithConfirmButton
        apiPath={`api/v1/devices/${props.deviceId}`}
        title="Delete device"
        message="Are you sure you want to delete this device? This will unlink it from Castia and require you to re-register it if you wish to continue using Castia on the device."
        successRedirectPath={devicesPath}
        successEvent={LocalEvents.DEVICES_CHANGED}
        variant={props.variant}
    />;
}

export default DeviceDeleteButton;
