import {
    EventForm,
    EventFormType,
} from "schedule/schedule-event/components/EventForm";
import Channel from "model/Channel";
import { useFetchOneScheduleEvent } from "hooks/api/schedule-event/useFetchOneScheduleEvent";
import React from "react";
import { EventValidationResult } from "schedule/schedule-event/components/FullScheduleCalendar";

interface EventUpdateFormContainerProps {
    scheduleId: string;
    scheduleEventId: string;
    closeModal: () => void;
    handleSubmit: (data: EventFormType) => void;
    channels: Channel[];
    validateEvent: (event: EventFormType, existingId: string) => EventValidationResult;
}

export function EventUpdateFormContainer(props: EventUpdateFormContainerProps) {
    const existingEvent = useFetchOneScheduleEvent(
        props.scheduleId,
        props.scheduleEventId
    );

    if (!existingEvent.response) {
        return <></>;
    }

    return (
        <EventForm
            handleSubmit={props.handleSubmit}
            channels={props.channels}
            closeModal={props.closeModal}
            existingEvent={existingEvent.response}
            validateEvent={props.validateEvent}
        />
    );
}
