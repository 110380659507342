import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { ScheduleDeleteButton } from "schedule/schedule/components/ScheduleDeleteButton";
import { useFetchOneSchedule } from "hooks/api/schedule/useFetchOneSchedule";
import { ButtonGroup } from "react-bootstrap";
import styles from "schedule/ScheduleDetailPage.scss";
import { ScheduleUpdateButton } from "schedule/schedule/button/ScheduleUpdateButton";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { FullScheduleCalendar } from "schedule/schedule-event/components/FullScheduleCalendar";

interface UrlParams {
    scheduleId: string;
}

export function ScheduleDetailPage(): ReactElement {
    const { scheduleId } = useParams<UrlParams>();
    const { response, isLoading, error, refreshData } =
        useFetchOneSchedule(scheduleId);
    const organizationContext = useOrganizationContext();

    return (
        <PageLayout>
            <ResponseErrorHandler
                isLoading={isLoading}
                retryAction={() => refreshData()}
                error={error}
            >
                {response && (
                    <>
                        <PageLayout.Header>
                            <PageLayout.Header.Title>
                                {response.name}
                            </PageLayout.Header.Title>
                            <PageLayout.Header.Secondary>
                                <ButtonGroup className={styles.buttonGroup}>
                                    <HasGroupRole
                                        groupId={organizationContext}
                                        role={GroupRole.EDITOR}
                                    >
                                        <ScheduleUpdateButton
                                            schedule={response}
                                        />
                                        <ScheduleDeleteButton
                                            variant={"button"}
                                            schedule={response}
                                            inList={false}
                                        />
                                    </HasGroupRole>
                                </ButtonGroup>
                            </PageLayout.Header.Secondary>
                        </PageLayout.Header>
                        <PageLayout.Body>
                            <FullScheduleCalendar schedule={response} />
                        </PageLayout.Body>
                    </>
                )}
            </ResponseErrorHandler>
        </PageLayout>
    );
}
