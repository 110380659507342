import { DeviceInfo } from "model/DeviceInfo";
import { Card, Col, Row } from "react-bootstrap";
import React, { ReactElement } from "react";
import styles from "./DeviceInfoCard.scss";
import { format } from "date-fns";

interface DeviceInfoCardProps {
    deviceInfo: DeviceInfo;
}

export function DeviceInfoCard({
    deviceInfo,
}: DeviceInfoCardProps): ReactElement {
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    Device Info{" "}
                    <em className={styles.small}>
                        (Updated at{" "}
                        {format(
                            new Date(deviceInfo.updatedAt),
                            "dd-LL-yyyy HH:mm:ss"
                        )}
                        )
                    </em>
                </Card.Title>
                <Row>
                    {deviceInfo.deviceUptime && (
                        <Col md={4} className="mb-3">
                            <strong>Device uptime</strong>
                            <div>{deviceInfo.deviceUptime}</div>
                        </Col>
                    )}
                    <Col md={4} className="mb-3">
                        <strong>App version</strong>
                        <div>{deviceInfo.appVersion}</div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <strong>App version name</strong>
                        <div>{deviceInfo.appVersionName}</div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <strong>OS Version</strong>
                        <div>{deviceInfo.osVersion}</div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <strong>OS Version name</strong>
                        <div>{deviceInfo.osVersionName}</div>
                    </Col>
                    <Col md={4} className="mb-3">
                        <strong>OS Name</strong>
                        <div>{deviceInfo.osName}</div>
                    </Col>
                    {deviceInfo.sdkVersion && (
                        <Col md={4} className="mb-3">
                            <strong>SDK Version</strong>
                            <div>{deviceInfo.sdkVersion}</div>
                        </Col>
                    )}
                </Row>
            </Card.Body>
        </Card>
    );
}
