import React, { ReactElement, useCallback, useEffect } from "react";
import { PropsWithChildren } from "react";
import { Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import styles from "core/components/FullscreenOverlay/FullscreenOverlay.scss";
import { Property } from "csstype";

interface FullscreenOverlayProps {
    onClose: () => void;
    backgroundColor?: Property.BackgroundColor;
}

export function FullscreenOverlay(
    props: FullscreenOverlayProps & PropsWithChildren<unknown>
): ReactElement {
    const escapeFunction = useCallback((event) => {
        if (event.key === "Escape") {
            props.onClose();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escapeFunction, false);

        return () => {
            document.removeEventListener("keydown", escapeFunction, false);
        };
    });

    return (
        <div
            className={styles.overlay}
            style={{
                backgroundColor: props.backgroundColor || "rgba(0,0,0,0.7)",
            }}
        >
            <div className={styles.content}>{props.children}</div>
            <Button className={styles.close} onClick={props.onClose}>
                <FaTimes />
            </Button>
        </div>
    );
}
