import React, { ReactElement } from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ScheduleCreateButton } from "schedule/schedule/components/ScheduleCreateButton";
import { ScheduleListContainer } from "schedule/schedule/components/ScheduleListContainer";
import { FolderType } from "model/FolderType";
import LocalEvents from "events/LocalEvents";
import { FolderCreateButton } from "core/folder/FolderCreateButton/FolderCreateButton";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { ButtonGroup } from "react-bootstrap";

export function SchedulePage(): ReactElement {
    const organizationContext = useOrganizationContext();

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Schedules</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <HasGroupRole
                        groupId={organizationContext}
                        role={GroupRole.EDITOR}
                    >
                        <ButtonGroup>
                            <FolderCreateButton
                                type={FolderType.SCHEDULE}
                                successEvent={LocalEvents.SCHEDULES_CHANGED}
                            />
                            <ScheduleCreateButton />
                        </ButtonGroup>
                    </HasGroupRole>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <ScheduleListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}
