import React, { ReactElement } from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { groupPath } from "core/util/routes";
import { FaPlus } from "react-icons/fa";
import { GroupListContainer } from "group/list/GroupListContainer";
import { HasGroupRoleOnAnyGroup } from "core/auth/HasGroupRoleOnAnyGroup";
import { GroupRole } from "core/auth/GroupRole";

export function GroupListPage(): ReactElement {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Groups</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <HasGroupRoleOnAnyGroup role={GroupRole.ORGANIZATION_ADMIN}>
                        <LinkButton
                            to={`${groupPath}/create`}
                            variant="primary"
                            size="md"
                        >
                            <FaPlus /> Create Group
                        </LinkButton>
                    </HasGroupRoleOnAnyGroup>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <GroupListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}
