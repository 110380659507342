import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import {
    ContentType,
    DeviceContentSource,
} from "device/edit/content/DeviceContentSource";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

interface ContentInputProps {
    initialContentType?: ContentType;
    initialContentId?: string;
}

export function ContentInput(props: ContentInputProps) {
    const { setValue } = useFormContext();
    const [contentType, setContentType] = useState<ContentType>(
        props.initialContentType || "SCHEDULE"
    );
    const handleChange = (val: ContentType) => {
        setContentType(val);
        setValue("contentType", val);
    };

    return (
        <>
            <ToggleButtonGroup
                name="content-type"
                type="radio"
                value={contentType}
                onChange={handleChange}
            >
                <ToggleButton value="SCHEDULE" id="togglebutton-schedule">
                    Schedule
                </ToggleButton>
                <ToggleButton value="SCENE" id="togglebutton-scene">
                    Scene
                </ToggleButton>
                <ToggleButton value="CHANNEL" id="togglebutton-channel">
                    Channel
                </ToggleButton>
            </ToggleButtonGroup>
            <br />
            <DeviceContentSource contentId={props.initialContentId || ""} contentType={contentType} />
        </>
    );
}
