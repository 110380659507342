import React, { ReactElement, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";
import { FormProvider, useForm } from "react-hook-form";
import Button from "core/components/UI/Button/Button";
import { useAddUserToGroup } from "user/hooks/useAddUserToGroup";
import { GroupInput } from "user/input/GroupInput";
import * as yup from "yup";
import { SelectInputChoice } from "core/components/input/SelectInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { GroupRoleInput } from "user/input/GroupRoleInput";
import { UserGroup } from "model/UserGroup";

interface UserAddToGroupFormData {
    groupId: string | SelectInputChoice;
    role: string;
}

interface UserAddToGroupModalBodyProps {
    onClose: () => void;
    email: string;
    currentGroups: UserGroup[];
}

const schema = yup.object({
    group: yup.string().transform((groups) => {
        return groups && groups.length > 0
            ? groups.map((group: SelectInputChoice) => group.value)
            : undefined;
    }),
    role: yup
        .string()
        .required()
        .transform((role) => {
            return role ? role.value : undefined;
        }),
});

export function UserAddToGroupModalBody(
    props: UserAddToGroupModalBodyProps
): ReactElement {
    const form = useForm<UserAddToGroupFormData>({
        resolver: yupResolver(schema),
    });
    const addUserToGroup = useAddUserToGroup();
    const watchGroup = form.watch("groupId");

    function handleClose() {
        props.onClose();
    }

    async function onSubmit(data: UserAddToGroupFormData): Promise<void> {
        await addUserToGroup.sendRequest(
            {
                email: props.email,
                role: data.role,
            },
            [
                {
                    key: "groupId",
                    value: (data.groupId as SelectInputChoice).value,
                },
            ]
        );
    }

    useEffect(() => {
        if (addUserToGroup.response && !addUserToGroup.error) {
            props.onClose();
        }
    }, [addUserToGroup.response, addUserToGroup.error]);

    return (
        <>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                {addUserToGroup.isLoading && <Loader />}
                {addUserToGroup.error && (
                    <Error>{addUserToGroup.error.message}</Error>
                )}
                <Modal.Body>
                    <FormProvider {...form}>
                        <GroupInput
                            name={"groupId"}
                            label={"Group"}
                            excludeGroups={props.currentGroups.map(
                                (group) => group.id
                            )}
                            isMulti={false}
                        />
                        {watchGroup && (
                            <GroupRoleInput groupId={watchGroup as string} />
                        )}
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={addUserToGroup.isLoading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </>
    );
}
