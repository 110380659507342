import { ApiError } from "hooks/api/ApiError";
import { toast } from "react-toastify";

export function errorToast(error: ApiError): void {
    if (error.status === 404) {
        toast.error("The requested resource could not be found");
    } else if (error.status === 500) {
        toast.error(
            "Something went wrong while sending or retrieving the data! Please try again later."
        );
    } else if (error.status === 422) {
        toast.error(
            `Something went wrong while sending or retrieving the data! ${error.message}`
        );
    } else if (error.status !== 400) {
        toast.error(
            `Something went wrong while sending or retrieving the data! Please try again later. (${error.message})`
        );
    }
}
