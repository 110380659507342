import { Alert, Button, Modal } from "react-bootstrap";
import React from "react";
import { EventOverlapResolutionExplanation } from "schedule/schedule-event/field/EventOverlapResolutionExplanation";

interface EventFormWarningPage {
    globalWarning: string | any;
    setGlobalWarning: (warning: string) => void;
    submitting: boolean;
}

export function EventFormWarningPage(props: EventFormWarningPage) {
    if (!props.globalWarning) {
        return <></>;
    }

    return (
        <>
            <Modal.Body>
                <Alert variant="warning">
                    {props.globalWarning}
                </Alert>
                <EventOverlapResolutionExplanation />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={"secondary"}
                    onClick={() => {
                        props.setGlobalWarning(null);
                    }}
                    className="me-2"
                >
                    Back
                </Button>
                <Button
                    variant={"primary"}
                    type="submit"
                    disabled={props.submitting}
                >
                    Save
                </Button>
            </Modal.Footer>
        </>
    );
}
