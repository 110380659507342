import React, { ReactElement } from "react";
import { FolderNavigateUpListGroupItem } from "core/folder/FolderNavigateUp/FolderNavigateUpListGroupItem";

interface FolderNavigateUpProps {
    parentFolderId: string | null;
}

export function FolderNavigateUp(props: FolderNavigateUpProps): ReactElement {
    if (!props.parentFolderId) {
        return null;
    }

    return <FolderNavigateUpListGroupItem folderId={props.parentFolderId} />;
}
