import { useGroupSummary } from "hooks/api/groups/useGroupSummary";
import { FetchData } from "@castia/sdk";
import { flatten } from "core/auth/flattenGroups";
import { GroupRole } from "core/auth/GroupRole";
import useAuthentication from "hooks/useAuthentication";
import { findGroupInTree } from "core/auth/useHasGroupRole";
import { useIsAdmin } from "core/auth/useIsAdmin";

export function useFlatSelectGroupSummaryList(
    roleFilter?: GroupRole[]
): FetchData<{ value: string; label: string }[]> {
    const fetchResult = useGroupSummary();
    const auth = useAuthentication();
    const isAdmin = useIsAdmin();

    return {
        ...fetchResult,
        response: fetchResult.response
            ? [...flatten(fetchResult.response, "subGroups")]
                  .filter((group) => {
                      if (!roleFilter || isAdmin) {
                          return true;
                      }

                      const groupInTree = findGroupInTree(
                          group.id,
                          auth.getGroups()
                      );

                      return groupInTree?.roles.some((role) =>
                          roleFilter.includes(role as GroupRole)
                      );
                  })
                  .map((group) => {
                      return { label: group.name, value: group.id };
                  })
            : undefined,
    };
}
