import React, { ReactElement } from "react";
import { ButtonToolbar, Dropdown, Navbar } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import styles from "core/components/Header/Header.scss";
import LogoutButton from "core/components/LogoutButton/LogoutButton";
import Username from "core/components/Header/Username/Username";
import PushButton from "core/components/Header/PublishButton/PublishButton";
import Button from "core/components/UI/Button/Button";
import BreadCrumbs from "core/components/UI/BreadCrumbs/BreadCrumbs";
import { UploadProgressBadge } from "core/components/UploadContext/UploadProgressBadge";
import { AccountProfileLink } from "core/components/Header/AccountProfileLink";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { PasswordResetLink } from "core/components/Header/PasswordResetLink";
import { ConfigureTwoFactorLink } from "core/components/Header/ConfigureTwoFactorLink";

interface HeaderProps {
    onMenuToggleClick: () => void;
}

const Header = (props: HeaderProps): ReactElement => {
    const organizationContext = useOrganizationContext();

    return (
        <header className={styles.header}>
            <Navbar
                className={styles.nav}
                bg="light"
                expand={true}
                variant="dark"
            >
                <span>
                    <Button
                        className={styles.hamburger}
                        variant="link"
                        onClick={props.onMenuToggleClick}
                    >
                        <FaBars />
                    </Button>
                </span>
                <BreadCrumbs />
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className="justify-content-end"
                >
                    <HasGroupRole
                        groupId={organizationContext}
                        role={GroupRole.EDITOR}
                    >
                        <UploadProgressBadge />
                        <PushButton />
                    </HasGroupRole>
                    <ButtonToolbar>
                        <Dropdown drop="down" align="end">
                            <Dropdown.Toggle
                                variant="light"
                                id="dropdown-toggle"
                            >
                                <Username />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <AccountProfileLink />
                                <PasswordResetLink />
                                <ConfigureTwoFactorLink />
                                <Dropdown.Divider />
                                <LogoutButton />
                            </Dropdown.Menu>
                        </Dropdown>
                    </ButtonToolbar>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
};

export default Header;
