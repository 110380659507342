import environment from "core/util/environment";
import useFetchMultipartPost, { PersistData } from "hooks/useFetchMultipartPost";
import LocalEvents from "events/LocalEvents";

export function usePatchTheme(tenantProfileId: string): PersistData {
    return useFetchMultipartPost(
        `${environment.apiBaseUrl}/api/v1/tenant-profiles/${tenantProfileId}/theme`,
        LocalEvents.TENANT_PROFILES_CHANGED,
        "PATCH"
    );
}
