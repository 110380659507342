import React, { ReactElement } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { useRefreshAllDevices } from "hooks/api/device/useRefreshAllDevices";
import { toast } from "react-toastify";

export function PlayerUpdateListItem(): ReactElement {
    const refresh = useRefreshAllDevices();

    async function onClick() {
        const confirmResult = confirm(
            "Are you sure you want to update all players of all users?"
        );
        if (confirmResult) {
            await refresh.sendRequest();
            toast.info("Player software update triggered!");
        }
    }

    return (
        <ListGroup.Item>
            <div className="d-flex justify-content-between">
                <div className="nav-link">
                    Update all player software (reloads browser)
                </div>
                <div>
                    <Button onClick={onClick}>Update Players</Button>
                </div>
            </div>
        </ListGroup.Item>
    );
}
