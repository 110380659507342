import { DeleteWithConfirmButton } from "core/components/button/DeleteWithConfirmButton";
import { keycloakRealmPath } from "core/util/routes";
import LocalEvents from "events/LocalEvents";
import React from "react";

interface KeycloakRealmDeleteButtonProps {
    keycloakRealmId: string;
}

export function KeycloakRealmDeleteButton(
    props: KeycloakRealmDeleteButtonProps
) {
    return (
        <DeleteWithConfirmButton
            apiPath={`api/v1/keycloak-realms/${props.keycloakRealmId}`}
            title={`Delete Keycloak Realm Config`}
            message={
                "WARNING: Are you sure you want to delete this keycloak realm config? Users of this realm WILL NOT be able to login anymore!"
            }
            successRedirectPath={keycloakRealmPath}
            successEvent={LocalEvents.KEYCLOAK_REALMS_CHANGED}
            cyDeleteButton="keycloakRealm-delete-button"
            cyConfirmDeleteButton="keycloakRealm-confirm-delete-button"
        />
    );
}
