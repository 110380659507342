import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";
import { useEventEmitter } from "@castia/sdk";

export function usePatchCurrentUser() {
    const emitter = useEventEmitter();

    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v2/users/me`,
        "PATCH",
        LocalEvents.USER_CHANGED,
        () => {
            emitter.emit(LocalEvents.UPDATE_TOKEN);
        }
    );
}
