import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";

/**
 * Set the position of a scene in a channel.
 * @param channelId
 */
function useSetPosition(channelId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/channels/${channelId}/scenes`,
        "PATCH"
    );
}

export default useSetPosition;
