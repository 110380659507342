import React, { ReactElement, useEffect, useState } from "react";
import { useQuery } from "hooks/useQuery";
import { FolderIdParams } from "core/folder/FolderIdParams";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import styles from "scene/ScenesOverviewPage/components/ScenesListContainer/ScenesListContainer.scss";
import { Paging } from "@castia/sdk";
import { defaultScenePageSize } from "hooks/api/scene/useFetchAllScenesPaginated";
import { useFetchAllSchedulesPaginated } from "hooks/api/schedule/useFetchAllSchedulesPaginated";
import { ScheduleList } from "schedule/schedule/components/ScheduleList";

export function ScheduleListContainer(): ReactElement {
    const { folderId } = useQuery<FolderIdParams>();
    const { response, isLoading, error, refreshData } =
        useFetchAllSchedulesPaginated();
    // Set folderId via state, otherwise it re-renders too often.
    const [stateFolderId, setStateFolderId] = useState(folderId);

    useEffect(() => {
        if (folderId) {
            refreshData(new URLSearchParams({ folderId: folderId }));
            setStateFolderId(folderId);
        } else {
            refreshData(new URLSearchParams({ folderId: "" }));
            setStateFolderId(null);
        }
    }, [folderId]);

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            <ScheduleList
                schedules={response?.items}
                folderId={stateFolderId}
            />
            <div className={styles.pagingContainer}>
                <Paging
                    currentPage={response?.meta.currentPage}
                    onClick={(page): void => {
                        refreshData(
                            new URLSearchParams({
                                page: String(page),
                                limit: String(defaultScenePageSize),
                            })
                        );
                    }}
                    totalPages={response?.meta.totalPages}
                    showMax={10}
                />
            </div>
        </ResponseErrorHandler>
    );
}
