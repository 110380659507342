import { useHistory, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { keycloakRealmPath } from "core/util/routes";
import { IsSysAdmin } from "core/auth/IsSysAdmin";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import PageLayoutHeader from "core/components/UI/PageLayout/PageLayoutHeader/PageLayoutHeader";
import PageLayoutHeaderTitle from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderTitle/PageLayoutHeaderTitle";
import PageLayoutHeaderSecondary from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/PageLayoutHeaderSecondary";
import { Alert, ButtonGroup, Col, Row } from "react-bootstrap";
import PageLayoutBody from "core/components/UI/PageLayout/PageLayoutBody/PageLayoutBody";
import { KeycloakRealmCard } from "keycloak-realm/show/KeycloakRealmCard";
import { useFetchOneKeycloakRealm } from "hooks/api/keycloak-realm/useFetchOneKeycloakRealm";
import { KeycloakRealmEditButton } from "keycloak-realm/button/KeycloakRealmEditButton";
import { KeycloakRealmDeleteButton } from "keycloak-realm/button/KeycloakRealmDeleteButton";
import { KeycloakRealmTenantProfilesCard } from "keycloak-realm/show/KeycloakRealmTenantProfilesCard";

interface KeycloakRealmPageParams {
    keycloakRealmId: string;
}

export function KeycloakRealmShowPage() {
    const { keycloakRealmId } = useParams<KeycloakRealmPageParams>();
    const { response, isLoading, error, refreshData } =
        useFetchOneKeycloakRealm(keycloakRealmId);
    const history = useHistory();

    useEffect(() => {
        if (error?.status === 404) {
            history.push(keycloakRealmPath);
        }
    }, [error]);

    return (
        <IsSysAdmin>
            <PageLayout>
                <ResponseErrorHandler
                    isLoading={isLoading}
                    error={error}
                    retryAction={() => refreshData()}
                >
                    {response && (
                        <>
                            <PageLayoutHeader>
                                <PageLayoutHeaderTitle>
                                    {response.realmName}
                                </PageLayoutHeaderTitle>
                                <PageLayoutHeaderSecondary>
                                    <ButtonGroup>
                                        <KeycloakRealmEditButton
                                            keycloakRealmId={keycloakRealmId}
                                        />
                                        <KeycloakRealmDeleteButton
                                            keycloakRealmId={keycloakRealmId}
                                        />
                                    </ButtonGroup>
                                </PageLayoutHeaderSecondary>
                            </PageLayoutHeader>
                            <PageLayoutBody>
                                {response.default && (
                                    <Row>
                                        <Alert variant="warning">
                                            This is a default configuration.
                                            Changes to this have to be done in
                                            the application environment. Changes
                                            here will be overwritten!
                                        </Alert>
                                    </Row>
                                )}
                                <Row>
                                    <Col md={8}>
                                        <KeycloakRealmCard
                                            keycloakRealm={response}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className={"mt-4"}>
                                        <KeycloakRealmTenantProfilesCard
                                            tenantProfiles={
                                                response.tenantProfiles
                                            }
                                        />
                                    </Col>
                                </Row>
                            </PageLayoutBody>
                        </>
                    )}
                </ResponseErrorHandler>
            </PageLayout>
        </IsSysAdmin>
    );
}
