import { SelectInputChoice } from "core/components/input/SelectInput";
import * as yup from "yup";
import { Group } from "model/Group";
import React, { ReactElement } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextInput } from "core/components/input/TextInput";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ShowIf } from "core/util/ShowIf";
import { GroupInput } from "user/input/GroupInput";
import { useIsAdmin } from "core/auth/useIsAdmin";
import { AllowedPluginMultiSelectField } from "core/components/fields/AllowedPluginMultiSelectField";

export interface GroupFormFields {
    name: string;
    parent?: SelectInputChoice | string;
    plugins?: SelectInputChoice | string;
}

const schema = yup.object({
    name: yup.string().required("Name is a required field"),
    parent: yup.string().transform((parent) => {
        return parent ? parent.value : undefined;
    }),
    plugins: yup
        .array()
        .of(yup.string())
        .transform((groups) => {
            return groups && groups.length > 0
                ? groups.map((group: SelectInputChoice) => group.value)
                : undefined;
        }),
});

interface GroupFormProps {
    existingGroup?: Group;
    handleSubmit: (data: GroupFormFields) => void;
    isLoading: boolean;
}

export function GroupForm(props: GroupFormProps): ReactElement {
    const isCreate = !props.existingGroup;
    const isAdmin = useIsAdmin();
    const form = useForm<GroupFormFields>({
        resolver: yupResolver(schema, {
            context: { isCreate: isCreate },
        }),
        defaultValues: props.existingGroup
            ? {
                  name: props.existingGroup.name,
                  parent: props.existingGroup.parent
                      ? {
                            value: props.existingGroup.parent.id,
                            label: props.existingGroup.parent.name,
                        }
                      : undefined,
              }
            : undefined,
    });
    const parent = form.watch("parent");

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(props.handleSubmit)}>
                <TextInput name="name" label="Name" />

                <ShowIf condition={isCreate || isAdmin}>
                    <Row>
                        <Col>
                            <GroupInput
                                name="parent"
                                label="Parent group"
                                excludeGroups={
                                    !isCreate && props.existingGroup
                                        ? [props.existingGroup.id]
                                        : undefined
                                }
                            />
                        </Col>
                    </Row>
                </ShowIf>
                <ShowIf condition={isCreate && !!parent}>
                    <Row>
                        <Col>
                            <AllowedPluginMultiSelectField
                                parentId={(parent as SelectInputChoice)?.value}
                                helpText="Select the plugins that should be enabled for this new group. This is optional. Only plugins that are installed for the selected parent group can be chosen."
                            />
                        </Col>
                    </Row>
                </ShowIf>

                <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isLoading}
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}
