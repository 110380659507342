import { SelectInput2 } from "core/components/input/SelectInput2";
import React from "react";

interface StartTimeInputProps {
    name: string;
}

export function TimeSelectInput(props: StartTimeInputProps) {
    function generateTimeOptions() {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const formattedHour = hour.toString().padStart(2, "0");
                const formattedMinute = minute.toString().padStart(2, "0");
                const time = `${formattedHour}:${formattedMinute}`;
                options.push(time);
            }
        }
        return options;
    }

    return (
        <SelectInput2
            name={props.name}
            className="mb-0"
            choices={generateTimeOptions().map((time) => {
                return { value: time, label: time };
            })}
        />
    );
}
