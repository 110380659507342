import { Button } from "react-bootstrap";
import React, { ReactElement, useState } from "react";
import { FaMinus } from "react-icons/fa";
import Modal from "core/components/UI/Modal/Modal";
import Loader from "core/components/UI/Loader/Loader";
import { useRemoveUserFromGroup } from "user/hooks/useRemoveUserFromGroup";
import { useEventEmitter } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";

interface UserRemoveFromGroupButtonProps {
    userId: string;
    groupId: string;
    successEvent: string;
    isProfile?: boolean;
}

export function UserRemoveFromGroupButton(
    props: UserRemoveFromGroupButtonProps
): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { sendRequest } = useRemoveUserFromGroup(
        props.userId,
        props.groupId,
        props.successEvent
    );
    const emitter = useEventEmitter();

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    const confirmDelete = async (): Promise<void> => {
        setLoading(true);
        await sendRequest();

        if (props.isProfile) {
            emitter.emit(LocalEvents.UPDATE_TOKEN);
        }
    };

    return (
        <>
            <Button
                variant="danger"
                onClick={openModal}
                title="Remove user from group"
                data-cy="remove-user-from-group-button"
            >
                <FaMinus />
            </Button>
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Are you sure?"
            >
                <Modal.Body>
                    {loading && <Loader />}
                    {!loading &&
                        showModal &&
                        (props.isProfile
                            ? "Are you sure you want to leave this group?"
                            : "Are you sure you want to remove this user from the group?")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        variant={"danger"}
                        onClick={confirmDelete}
                        data-cy="scene-confirm-delete-button"
                    >
                        {props.isProfile ? "Leave" : "Delete"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
