import React, { ReactElement } from "react";
import { Dropdown } from "react-bootstrap";
import environment from "core/util/environment";
import { useTenantProfileContext } from "tenant-profile/TenantProfileContext";

export function ConfigureTwoFactorLink(): ReactElement {
    const tenantProfileContext = useTenantProfileContext();
    const passwordResetLink = `${environment.keycloakUrl}/realms/${tenantProfileContext.auth.realm}/protocol/openid-connect/auth?response_type=code&client_id=${tenantProfileContext.auth.clientName}&redirect_uri=${window.location}&kc_action=CONFIGURE_TOTP`;

    return (
        <Dropdown.Item
            href={passwordResetLink}
        >
            Configure two-factor authentication
        </Dropdown.Item>
    );
}
