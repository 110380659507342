import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

/**
 * Delete channel call.
 *
 * @param channelId
 */
function useDeleteChannel(channelId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/channels/${channelId}`,
        "DELETE",
        LocalEvents.CHANNELS_CHANGED,
    );
}

export default useDeleteChannel;
