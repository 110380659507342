import Select from "react-select";
import { useController, useFormContext } from "react-hook-form";
import { Form } from "react-bootstrap";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import React, { ReactElement } from "react";
import { InputProps } from "core/components/input/InputProps";

export interface SelectInputChoice {
    value: string;
    label: string;
}

interface MultiSelectInputProps {
    choices: SelectInputChoice[];
    isMulti?: boolean;
    helpText?: string;
    noOptionsMessage?: string;
    label?: string;
}

export function SelectInput(props: InputProps & MultiSelectInputProps): ReactElement {
    const form = useFormContext();
    const { errors } = form.formState;
    const controller = useController({
        name: props.name,
        rules: props.registerOptions,
    });

    return <div className={props.className ? props.className : "mb-3"}>
        {props.label && <Form.Label>{props.label}</Form.Label>}
        <Select
            value={form.getValues(props.name)}
            {...controller.field}
            options={props.choices}
            isMulti={props.isMulti}
            noOptionsMessage={() => props.noOptionsMessage ?? "No options available"}
        />
        {props.helpText && <Form.Text muted>
            {props.helpText}
        </Form.Text>}
        {errors[props.name] && (
            <FormInputError>{errors[props.name].message}</FormInputError>
        )}
    </div>;
}
