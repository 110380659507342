import React, { ReactElement, useEffect } from "react";
import { Form } from "react-bootstrap";
import Scene from "model/Scene";
import useFetchAllScenes from "hooks/api/scene/useFetchAllScenes";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { useFormContext } from "react-hook-form";

interface DeviceSceneControlProps {
    contentId: string;
}

export function DeviceSceneControl(
    props: DeviceSceneControlProps
): ReactElement {
    const { response, isLoading, error, refreshData } = useFetchAllScenes();
    const { register, setValue } = useFormContext();

    useEffect((): void => {
        setValue("contentId", props.contentId);
    }, [response]);

    const sceneList = (response || []).map(
        (scene: Scene, index: number): ReactElement => {
            return (
                <option key={index} value={scene.id}>
                    {scene.title} ({scene.id})
                </option>
            );
        }
    );

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            <Form.Label className="mt-2">Scene</Form.Label>
            <Form.Select {...register("contentId")}>
                <option value="">-</option>
                {sceneList}
            </Form.Select>
        </ResponseErrorHandler>
    );
}
