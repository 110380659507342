import React, { ReactElement } from "react";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import useFetchBySchedule from "hooks/api/device/useFetchBySchedule";
import { FolderListItem } from "model/FolderListItem";
import { ListGroup } from "react-bootstrap";
import { devicesPath } from "core/util/routes";
import { ListGroupItemLink } from "core/components/UI/ListGroupItemLink/ListGroupItemLink";
import { Schedule } from "model/Schedule";

interface ScheduleDeleteModalBodyProps {
    schedule: FolderListItem | Schedule;
}

export function ScheduleDeleteModalBody(
    props: ScheduleDeleteModalBodyProps
): ReactElement {
    const { response, isLoading, error } = useFetchBySchedule(
        props.schedule.id
    );

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            {response && (
                <>
                    <div>
                        Are you sure you wish to delete the schedule &apos;
                        {props.schedule.name}&apos;?
                    </div>
                    <br />

                    {response.length > 0 ? (
                        <div>
                            This action will leave the following device(s)
                            without a schedule:
                            <ListGroup>
                                {response.map((device): ReactElement => {
                                    return (
                                        <ListGroupItemLink
                                            key={device.id}
                                            to={`${devicesPath}/${device.id}`}
                                        >
                                            {device.name}
                                        </ListGroupItemLink>
                                    );
                                })}
                            </ListGroup>
                        </div>
                    ) : (
                        <div>
                            This schedule is currently not used on any devices.
                        </div>
                    )}
                </>
            )}
        </ResponseErrorHandler>
    );
}
