import { useUploadProgress } from "hooks/upload/useUploadProgress";
import { Badge, Button, OverlayTrigger, Popover } from "react-bootstrap";
import React, { ReactElement } from "react";
import { FaUpload } from "react-icons/fa";
import { UploadProgressList } from "core/components/UploadContext/UploadProgressList";
import styles from "core/components/UploadContext/UploadProgressBadge.scss";

export function UploadProgressBadge(): ReactElement {
    const items = useUploadProgress();
    const itemsInProgress = items.filter(
        (item) => item.percentage !== 100
    ).length;

    return (
        <OverlayTrigger
            trigger={"click"}
            placement="bottom"
            overlay={
                <Popover id="popover-upload" className={styles.popover}>
                    <Popover.Header as="h3">{`Upload progress`}</Popover.Header>
                    <Popover.Body>
                        {items.length > 0 ? (
                            <UploadProgressList items={items} />
                        ) : (
                            <span>No uploads in progress.</span>
                        )}
                    </Popover.Body>
                </Popover>
            }
        >
            <Button variant="link">
                <FaUpload />
                <Badge bg="danger">{itemsInProgress}</Badge>
            </Button>
        </OverlayTrigger>
    );
}
