import { useDeviceInfo } from "hooks/api/device/useDeviceInfo";
import React, { ReactElement } from "react";
import { ResponseErrorHandler } from "@castia/sdk";
import { Col, Row } from "react-bootstrap";
import { DeviceInfoCard } from "device/show/DeviceInfoCard";
import { PlayerInfoCard } from "device/show/PlayerInfoCard";

interface DeviceInfoCardProps {
    deviceId: string;
}

export function DeviceInfoCardContainer(
    props: DeviceInfoCardProps
): ReactElement {
    const { response, isLoading, error } = useDeviceInfo(props.deviceId);

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            <Row className="mb-3">
                <Col>
                    {response && response.deviceId && (
                        <PlayerInfoCard deviceInfo={response} />
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    {response && response.deviceId && response.appVersion && (
                        <DeviceInfoCard deviceInfo={response} />
                    )}
                </Col>
            </Row>
        </ResponseErrorHandler>
    );
}
