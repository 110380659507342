import React, { useState } from "react";
import Channel from "model/Channel";
import { ChannelEditModal } from "channel/edit/ChannelEditModal";
import { FaEdit } from "react-icons/fa";
import Button from "core/components/UI/Button/Button";

interface EditChannelButtonProps {
    channel: Channel;
}

export function EditChannelButton(props: EditChannelButtonProps) {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                variant={"secondary"}
                onClick={(): void => setShowModal(true)}
                data-cy="edit-channel-button"
            >
                <FaEdit />
            </Button>
            <ChannelEditModal
                show={showModal}
                channel={props.channel}
                onDone={() => setShowModal(false)}
            />
        </>
    );
}
