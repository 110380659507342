import { Card, Col, Row } from "react-bootstrap";
import React from "react";
import { useFetchOneTheme } from "hooks/api/theme/useFetchOneTheme";
import { ResponseErrorHandler } from "@castia/sdk";
import { TenantThemeEditButton } from "tenant-profile/theme/button/TenantThemeEditButton";
import { ThemeVariableField } from "tenant-profile/theme/show/ThemeVariableField";
import { LogoPreviewField } from "tenant-profile/theme/show/LogoPreviewField";
import { FaviconPreviewField } from "tenant-profile/theme/show/FaviconPreviewField";

interface TenantThemeCardProps {
    tenantProfileId: string;
}

export function TenantThemeCard(props: TenantThemeCardProps) {
    const { response, isLoading, error, refreshData } = useFetchOneTheme(
        props.tenantProfileId
    );

    return (
        <Card>
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <div className="align-self-center">Theme settings</div>
                    <div>
                        <TenantThemeEditButton
                            tenantProfileId={props.tenantProfileId}
                        />
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <ResponseErrorHandler
                    isLoading={isLoading}
                    error={error}
                    retryAction={() => refreshData()}
                >
                    {response && Object.keys(response).length > 0 ? (
                        <>
                            <Row>
                                {response.logo && (
                                    <Col>
                                        <dt>Logo</dt>
                                        <dd>
                                            <LogoPreviewField
                                                logoUrl={response.logo}
                                            />
                                        </dd>
                                    </Col>
                                )}
                                {response.favicon && (
                                    <Col>
                                        <dt>Favicon</dt>
                                        <dd>
                                            <FaviconPreviewField
                                                faviconUrl={response.favicon}
                                            />
                                        </dd>
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                {Object.entries(
                                    response?.themeVariables || {}
                                ).map(([key, value], index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <dt>
                                                {key.charAt(0).toUpperCase() +
                                                    key.slice(1)}
                                            </dt>
                                            <dd>
                                                <ThemeVariableField
                                                    themeVariableValue={value}
                                                />
                                            </dd>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </>
                    ) : (
                        <Row>
                            <Col md={12}>
                                There are no custom theme settings for this
                                tenant yet.
                            </Col>
                        </Row>
                    )}
                </ResponseErrorHandler>

                <dl></dl>
            </Card.Body>
        </Card>
    );
}
