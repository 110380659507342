import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";

export function useDeleteFolder(folderId: string, successEvent?: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/folders/${folderId}`,
        "DELETE",
        successEvent
    );
}
