import { TenantProfile } from "model/TenantProfile";
import { Card } from "react-bootstrap";
import React from "react";

interface TenantProfileOverviewCardProps {
    tenantProfile: TenantProfile;
}

export function TenantProfileOverviewCard(
    props: TenantProfileOverviewCardProps
) {
    return (
        <Card>
            <Card.Body>
                <dl>
                    <dt>ID</dt>
                    <dd>{props.tenantProfile.id}</dd>
                    <dt>Dashboard Domain</dt>
                    <dd>{props.tenantProfile.dashboardDomain}</dd>
                    <dt>Player Domain</dt>
                    <dd>{props.tenantProfile.playerDomain}</dd>
                </dl>
            </Card.Body>
        </Card>
    );
}
