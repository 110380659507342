import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Button, Form } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import React from "react";
import * as yup from "yup";
import { TenantProfile } from "model/TenantProfile";
import { FieldNamesMarkedBoolean } from "react-hook-form/dist/types/form";
import { KeycloakRealmInput } from "tenant-profile/input/KeycloakRealmInput";
import { SelectInputChoice } from "core/components/input/SelectInput";

export interface TenantProfileCreateFormFields {
    dashboardDomain: string;
    playerDomain: string;

    keycloakRealmId: SelectInputChoice | string;
    parentTenantId?: SelectInputChoice | string;
}

const schema = yup.object({
    dashboardDomain: yup
        .string()
        .required("Dashboard Domain is a required field"),
    playerDomain: yup.string().required("Player Domain is a required field"),
    keycloakRealmId: yup
        .string()
        .transform((parent) => {
            return parent ? parent.value : undefined;
        })
        .required("Keycloak Realm is a required field"),
    parentTenantId: yup.string().transform((parent) => {
        return parent ? parent.value : undefined;
    }),
});

interface TenantProfileFormProps {
    existingTenantProfile?: TenantProfile;
    handleSubmit: (
        data: TenantProfileCreateFormFields,
        dirtyFields: FieldNamesMarkedBoolean<TenantProfileCreateFormFields>
    ) => void;
    isLoading: boolean;
}

export function TenantProfileForm(props: TenantProfileFormProps) {
    const isCreate = !props.existingTenantProfile;
    const form = useForm<TenantProfileCreateFormFields>({
        resolver: yupResolver(schema, {
            context: { isCreate: isCreate },
        }),
        defaultValues: props.existingTenantProfile
            ? {
                  dashboardDomain: props.existingTenantProfile.dashboardDomain,
                  playerDomain: props.existingTenantProfile.playerDomain,
                  keycloakRealmId: {
                      value: props.existingTenantProfile.keycloakRealm.id,
                      label: props.existingTenantProfile.keycloakRealm
                          .realmName,
                  },
                  parentTenantId: props.existingTenantProfile.parentTenant
                      ? {
                            value: props.existingTenantProfile.parentTenant.id,
                            label: props.existingTenantProfile.parentTenant
                                .dashboardDomain,
                        }
                      : undefined,
              }
            : {},
    });
    const { dirtyFields } = form.formState;

    return (
        <FormProvider {...form}>
            <Form
                onSubmit={form.handleSubmit(
                    (data) => {
                        props.handleSubmit(data, dirtyFields);
                    },
                    (errors, event) => {
                        console.log("errors", errors);
                    }
                )}
            >
                <TextInput
                    name="dashboardDomain"
                    label="Dashboard Domain"
                    helpText="Don't forget to point the domain to the Castia Dashboard in the DNS settings."
                />
                <TextInput
                    name="playerDomain"
                    label="Player Domain"
                    helpText="Don't forget to point the domain to the Castia Player in the DNS settings."
                />
                <KeycloakRealmInput
                    name="keycloakRealmId"
                    label="Keycloak Realm"
                />

                <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isLoading}
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}
