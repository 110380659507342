import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

/**
 * Update device operation.
 *
 * Can be used to update a channel or name of a device for example.
 *
 * @param deviceId
 * @param emitEvent
 */
function useUpdateDevice(deviceId: string, emitEvent?: boolean): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/devices/${deviceId}`,
        "PATCH",
        emitEvent ? LocalEvents.DEVICES_CHANGED : undefined
    );
}

export default useUpdateDevice;
