import { KeycloakRealm } from "model/KeycloakRealm";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { keycloakRealmPath } from "core/util/routes";
import React from "react";

interface KeycloakRealmListProps {
    keycloakRealms: KeycloakRealm[];
}

export function KeycloakRealmList(props: KeycloakRealmListProps) {
    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Realm name</th>
                </tr>
            </thead>
            <tbody>
                {props.keycloakRealms.map((keycloakRealm, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <Link
                                    className="nav nav-link"
                                    to={`${keycloakRealmPath}/${keycloakRealm.id}`}
                                >
                                    {keycloakRealm.realmName}{" "}
                                    {keycloakRealm.default ? "(default)" : ""}
                                </Link>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
