import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import { useEventListener } from "@castia/sdk";
import { useCallback } from "react";
import Scene from "model/Scene";
import LocalEvents from "events/LocalEvents";

/**
 * Fetch the channel list. Subscribe to the channel Change event and reload the data if it gets triggered.
 */
function useFetchAllScenes(): FetchData<Scene[]> {
    const fetch = useFetch<Scene[]>(
        `${environment.apiBaseUrl}/api/v1/scenes?all=1`
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.SCENES_CHANGED, eventCallback);

    return fetch;
}

export default useFetchAllScenes;
