import React, { ReactElement } from "react";
import { Form } from "react-bootstrap";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import { useFormContext } from "react-hook-form";
import { InputProps } from "core/components/input/InputProps";

interface TextInputProps {
    type?: string;
    helpText?: string;
}

export function TextInput(props: TextInputProps & InputProps): ReactElement {
    const form = useFormContext();
    const { errors } = form.formState;

    return (
        <div className={props.className ? props.className : "mb-3"}>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            <Form.Control
                name={props.name}
                placeholder={props.label}
                type={props.type || "text"}
                aria-describedby={
                    props.helpText ? props.name + "HelpBlock" : ""
                }
                {...form.register(props.name, props.registerOptions)}
            />
            {props.helpText && (
                <Form.Text id={props.name + "HelpBlock"}>
                    {props.helpText}
                </Form.Text>
            )}
            {errors[props.name] && (
                <FormInputError>{errors[props.name].message}</FormInputError>
            )}
        </div>
    );
}
