import * as React from "react";
import { FaPlus } from "react-icons/fa";
import { ReactElement, useState } from "react";
import AddSceneToChannelModal from "channel/button/AddSceneToChannelModal";
import Button from "core/components/UI/Button/Button";

interface AddSceneToChannelButtonProps {
    channelId: string;
}

/**
 * Button to add a scene to a channel.
 * @param props
 * @constructor
 */
function AddSceneToChannelButton(
    props: AddSceneToChannelButtonProps
): ReactElement {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button onClick={(): void => setShowModal(true)} data-cy="add-scene-to-channel">
                <FaPlus />
            </Button>
            <AddSceneToChannelModal
                channelId={props.channelId}
                show={showModal}
                onDone={(): void => setShowModal(false)}
            />
        </>
    );
}

export default AddSceneToChannelButton;
