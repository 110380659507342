import { FetchData, useEventListener, useFetch } from "@castia/sdk";
import { GroupSummary } from "model/GroupSummary";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";

export function useGroupSummary(): FetchData<GroupSummary[]> {
    const fetchData = useFetch<GroupSummary[]>(
        `${environment.apiBaseUrl}/api/v2/groups/summary`
    );

    const organizationCallback = useCallback(() => {
        fetchData.refreshData();
    }, []);
    useEventListener(LocalEvents.GROUPS_CHANGED, organizationCallback);

    return fetchData;
}
