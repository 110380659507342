import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useCreateTenantProfile(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/tenant-profiles`,
        "POST",
        LocalEvents.TENANT_PROFILES_CHANGED
    );
}
