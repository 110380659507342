import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { NewsItem } from "model/NewsItem";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { useCallback } from "react";

export const newsPageSize = 10;

export function useNewsItems(pageSize = newsPageSize) {
    const fetch = useFetchPaginated<NewsItem>(
        `${environment.apiBaseUrl}/api/v1/news`,
        pageSize,
        1
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.NEWS_CHANGED, eventCallback);

    return fetch;
}
