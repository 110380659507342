import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { useCallback } from "react";
import { FetchData, Paginated, useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import { Group } from "model/Group";

const groupPageSize = 10;

export function useFetchAllGroups(): FetchData<Paginated<Group>> {
    const fetch = useFetchPaginated<Group>(
        `${environment.apiBaseUrl}/api/v2/groups`,
        groupPageSize,
        1
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.GROUPS_CHANGED, eventCallback);

    return fetch;
}
