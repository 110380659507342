import environment from "core/util/environment";
import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import LocalEvents from "events/LocalEvents";

/**
 * @param scheduleId
 */
export function useUpdateSchedule(scheduleId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v2/schedules/${scheduleId}`,
        "PATCH",
        LocalEvents.SCHEDULES_CHANGED
    );
}
