import { Group } from "model/Group";
import React, { ReactElement } from "react";
import { usePatchGroup } from "group/hooks/usePatchGroup";
import { useHistory } from "react-router-dom";
import { groupPath } from "core/util/routes";
import { GroupForm, GroupFormFields } from "group/GroupForm";
import { Loader } from "@castia/sdk";
import Error from "core/components/UI/Error/Error";

interface GroupEditFormProps {
    group: Group;
}

export function GroupEditForm(props: GroupEditFormProps): ReactElement {
    const history = useHistory();
    const { isLoading, error, sendRequest } = usePatchGroup(
        props.group.id,
        () => {
            history.push(`${groupPath}/${props.group.id}`);
        }
    );

    async function onSubmit(data: GroupFormFields): Promise<void> {
        await sendRequest(data);
    }

    return (
        <>
            {isLoading && <Loader />}
            {error && <Error>{error.message}</Error>}
            <GroupForm
                handleSubmit={onSubmit}
                isLoading={isLoading}
                existingGroup={props.group}
            />
        </>
    );
}
