import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Button, Form } from "react-bootstrap";
import { BooleanInput } from "core/components/input/BooleanInput";
import React from "react";
import * as yup from "yup";
import { NewsItem } from "model/NewsItem";
import { RichTextInput } from "core/input/RichTextInput";
import { TextInput } from "core/components/input/TextInput";

export interface NewsFormFields {
    title: string;
    description: any;
    published: boolean;
}

const schema = yup.object({
    title: yup.string().required("Title is a required field"),
    description: yup.object().required("Text is a required field"),
    published: yup.boolean().required("Published is a required field"),
});

interface NewsFormProps {
    existingNewsItem?: NewsItem;
    handleSubmit: (data: NewsFormFields) => void;
    isLoading: boolean;
}

export function NewsForm(props: NewsFormProps) {
    const isCreate = !props.existingNewsItem;
    const form = useForm<NewsFormFields>({
        resolver: yupResolver(schema, {
            context: { isCreate: isCreate },
        }),
        defaultValues: props.existingNewsItem
            ? {
                  title: props.existingNewsItem.title,
                  description: props.existingNewsItem.description,
                  published: props.existingNewsItem.published,
              }
            : {
                  published: true,
              },
    });

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(props.handleSubmit)}>
                <TextInput name="title" label="Title" />

                <RichTextInput name="description" displayName="Text" />

                <BooleanInput name="published" label="Published" />

                <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isLoading}
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}
