import { ContentType } from "device/edit/content/DeviceContentSource";
import React, { ReactElement } from "react";

interface ContentTypeFieldProps {
    contentType: ContentType;
}

export function ContentTypeField(props: ContentTypeFieldProps): ReactElement {
    switch (props.contentType) {
        case "CHANNEL":
            return <span>Channel</span>;
        case "SCENE":
            return <span>Scene</span>;
        case "SCHEDULE":
            return <span>Schedule</span>;
        default:
            return <span>Unknown</span>;
    }
}
