import Plugin from "model/Plugin";
import environment from "core/util/environment";
import useFetch, { FetchData } from "hooks/useFetch";
import { useEffect, useRef } from "react";
import useAuthentication from "hooks/useAuthentication";

/**
 * Fetch the list of enabled plugins
 */
function useFetchEnabledTemplatePlugins(): FetchData<Plugin[]> {
    const auth = useAuthentication();
    const mounted = useRef<boolean>(false);
    const fetchData = useFetch<Plugin[]>(
        `${environment.apiBaseUrl}/api/v1/plugins/installed`
    );

    // Reload data if the organization changes. Org change always redirects to this page, but if the user was already on this page, we need to refresh the data.
    useEffect(() => {
        if (mounted.current) {
            fetchData.refreshData();
        } else {
            mounted.current = true;
        }

    }, [auth.organizationContext]);


    return fetchData;
}

export default useFetchEnabledTemplatePlugins;
