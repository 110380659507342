import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import Plugin from "model/Plugin";

export function useFetchAllowedPluginsForGroup(
    groupId?: string,
    parentGroupId?: string
): FetchData<Plugin[]> {
    let url = `${environment.apiBaseUrl}/api/v1/plugins/allowed`;
    url += groupId ? `?groupId=${groupId}` : `?parentGroupId=${parentGroupId}`;

    return useFetch(url);
}
