import { Dropdown } from "react-bootstrap";
import React from "react";
import { useHistory } from "react-router-dom";
import { profilePath } from "core/util/routes";

export function AccountProfileLink() {
    const history = useHistory();

    return (
        <Dropdown.Item
            onClick={() => {
                history.push(profilePath);
            }}
        >
            Profile
        </Dropdown.Item>
    );
}
