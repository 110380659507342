import { useParams } from "react-router-dom";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import React from "react";
import { KeycloakRealmEditForm } from "keycloak-realm/edit/KeycloakRealmEditForm";
import { useFetchOneKeycloakRealm } from "hooks/api/keycloak-realm/useFetchOneKeycloakRealm";
import { Alert, Row } from "react-bootstrap";
import { IsSysAdmin } from "core/auth/IsSysAdmin";

interface KeycloakRealmEditPageParams {
    keycloakRealmId: string;
}

export function KeycloakRealmEditPage() {
    const { keycloakRealmId } = useParams<KeycloakRealmEditPageParams>();
    const { response, isLoading, error } =
        useFetchOneKeycloakRealm(keycloakRealmId);

    return (
        <IsSysAdmin>
            <PageLayout>
                <PageLayout.Header>
                    <PageLayout.Header.Title>
                        Edit Keycloak Realm
                    </PageLayout.Header.Title>
                </PageLayout.Header>
                <PageLayout.Body>
                    <ResponseErrorHandler isLoading={isLoading} error={error}>
                        {response && (
                            <>
                                {response.default && (
                                    <Row>
                                        <Alert variant="warning">
                                            This is a default configuration.
                                            Changes to this have to be done in
                                            the application environment. Changes
                                            here will be overwritten!
                                        </Alert>
                                    </Row>
                                )}
                                <KeycloakRealmEditForm
                                    keycloakRealm={response}
                                />
                            </>
                        )}
                    </ResponseErrorHandler>
                </PageLayout.Body>
            </PageLayout>
        </IsSysAdmin>
    );
}
