import { Route, RouteComponentProps, Switch } from "react-router";
import React, { ReactElement } from "react";
import { UserShowPage } from "user/UserShowPage";
import { UserProfileEditPage } from "user/UserProfileEditPage";

export function UserProfileRouter({
    match,
}: RouteComponentProps): ReactElement<RouteComponentProps> {
    return (
        <Switch>
            <Route
                path={`${match.url}/edit`}
                exact
                component={UserProfileEditPage}
            />
            <Route
                path={`${match.url}`}
                render={() => {
                    return <UserShowPage isProfile={true} />;
                }}
            />
        </Switch>
    );
}
