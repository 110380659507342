import React, { ReactElement } from "react";
import { useUpdateFolder } from "hooks/api/folder/useUpdateFolder";
import { FolderMoveButton } from "core/folder/FolderMoveButton/FolderMoveButton";
import { FolderType } from "model/FolderType";
import { FolderMoveFormData } from "core/folder/FolderMoveButton/FolderMoveModalBody";

interface FolderMoveButtonContainerProps {
    folderId: string;
    folderType: FolderType;
    successEvent?: string;
}

export function FolderMoveButtonContainer(
    props: FolderMoveButtonContainerProps
): ReactElement {
    const updateFolder = useUpdateFolder(props.folderId, props.successEvent);

    function onSave(data: FolderMoveFormData) {
        updateFolder.sendRequest({
            folder: data.folder,
        });
    }

    return (
        <FolderMoveButton
            folderType={props.folderType}
            onSave={onSave}
            persistData={updateFolder}
            currentFolderId={props.folderId}
        />
    );
}
