import { useEventListener, useFetch } from "@castia/sdk";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";
import { TenantProfile } from "model/TenantProfile";

export function useFetchOneTenantProfile(tenantProfileId: string) {
    const fetch = useFetch<TenantProfile>(
        `${environment.apiBaseUrl}/api/v1/tenant-profiles/${tenantProfileId}`
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.TENANT_PROFILES_CHANGED, eventCallback);

    return fetch;
}
