import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import React, { ReactElement, useState } from "react";
import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { Row } from "react-bootstrap";
import { SortableChannelScenePreview } from "channel/edit/preview/SortableChannelScenePreview";
import ChannelSceneDTO from "model/ChannelSceneDTO";
import ChannelScenePreview from "channel/edit/preview/ChannelScenePreview";
import useSetPosition from "hooks/api/channel/useSetPosition";
import { DragStartEvent } from "@dnd-kit/core/dist/types";

interface ChannelEditorProps {
    scenes: ChannelSceneDTO[];
    channelId: string;
}

export function ChannelEditor(props: ChannelEditorProps): ReactElement {
    const [activeId, setActiveId] = useState(null);
    const [items, setItems] = useState(
        props.scenes.sort((a, b): number => (a.position < b.position ? -1 : 1))
    );
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const [rowWidth, setRowWidth] = useState(1000);
    const { sendRequest } = useSetPosition(props.channelId);

    const handleDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        setActiveId(null);
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = items.findIndex(
                (channelScene) => channelScene.id === active.id
            );
            const newIndex = items.findIndex(
                (channelScene) => channelScene.id === over.id
            );

            const reorderedItems = arrayMove(items, oldIndex, newIndex);
            reorderedItems.forEach(
                (item: ChannelSceneDTO, position: number): void => {
                    item.position = position;
                }
            );

            setItems(reorderedItems);

            const updatedScenePositionDTO = reorderedItems.map(
                (item: ChannelSceneDTO): Record<string, string | number> => {
                    return {
                        id: item.id,
                        position: item.position,
                    };
                }
            );
            sendRequest({
                channelScenes: updatedScenePositionDTO,
            });
        }
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
        >
            <Row
                ref={(refElem: HTMLDivElement) => {
                    if (refElem) {
                        setRowWidth(refElem.clientWidth);
                    }
                }}
                data-cy="channel-scenes"
            >
                <SortableContext items={items} strategy={rectSortingStrategy}>
                    {items.map((channelScene) => (
                        <SortableChannelScenePreview
                            key={channelScene.id}
                            id={channelScene.id}
                            channelScene={channelScene}
                            channelId={props.channelId}
                        />
                    ))}
                    <DragOverlay>
                        {activeId ? (
                            <Row style={{ width: rowWidth }}>
                                <ChannelScenePreview
                                    channelScene={items.find(
                                        (item) => item.id === activeId
                                    )}
                                    channelId={props.channelId}
                                    hideContent
                                />
                            </Row>
                        ) : null}
                    </DragOverlay>
                </SortableContext>
            </Row>
        </DndContext>
    );
}
