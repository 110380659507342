import { ResponseErrorHandler } from "@castia/sdk";
import { useFlatSelectGroupSummaryList } from "user/hooks/useFlatSelectGroupSummaryList";
import React, { ReactElement } from "react";
import { SelectInput } from "core/components/input/SelectInput";
import { GroupRole } from "core/auth/GroupRole";

interface GroupInputProps {
    name: string;
    label: string;
    excludeGroups?: string[];
    isMulti?: boolean;
}

export function GroupInput(props: GroupInputProps): ReactElement {
    const { response, isLoading, error } = useFlatSelectGroupSummaryList([
        GroupRole.RESELLER,
        GroupRole.ORGANIZATION_ADMIN,
    ]);

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            {response && (
                <SelectInput
                    name={props.name}
                    label={props.label}
                    choices={response?.filter((group) => {
                        return (
                            !props.excludeGroups ||
                            !props.excludeGroups.includes(group.value)
                        );
                    })}
                    registerOptions={{
                        required: "Group is a required field",
                    }}
                    isMulti={props.isMulti}
                />
            )}
        </ResponseErrorHandler>
    );
}
