import { PropsWithChildren } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";
import * as React from "react";
import styles from "./OverflowMenu.scss";
import { ButtonVariant } from "react-bootstrap/types";

interface OverflowMenuProps {
    variant: ButtonVariant;
}

export function OverflowMenu(props: PropsWithChildren<OverflowMenuProps>) {
    return (
        <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
                variant={props.variant}
                type="button"
                className={styles.overflow}
                data-cy={"overflow-menu"}
            >
                <FaEllipsisV />
            </Dropdown.Toggle>

            <Dropdown.Menu>{props.children}</Dropdown.Menu>
        </Dropdown>
    );
}
