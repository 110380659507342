/**
 * The debounce function can be called as many times as you want, but it only
 * calls the function you pass it with the given delay once per interval.
 * @param func The function you want to debounce
 * @param delay How long to delay the call to func
 */
export function debounce(func: () => void, delay: number) {
    let debounceTimer: ReturnType<typeof setTimeout> | undefined;
    return () => {
        if (debounceTimer) clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func(), delay);
    };
}
