import React, { ReactElement } from "react";
import { ButtonGroup, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Device from "model/Device";
import DeviceDeleteButton from "device/button/DeviceDeleteButton";
import { devicesPath } from "core/util/routes";
import { DeviceConnectedField } from "device/field/DeviceConnectedField";
import styles from "./DeviceList.scss";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { DeviceContentFormModal } from "device/edit/DeviceContentFormModal";
import { OverflowMenu } from "core/components/OverflowMenu/OverflowMenu";

interface DeviceListProps {
    devices: Device[];
}

function DeviceList(props: DeviceListProps) {
    const organizationContext = useOrganizationContext();

    const devicesList = props.devices.map(
        (device: Device, index: number): ReactElement => {
            return (
                <tr key={index}>
                    <td>
                        <Link
                            className="nav nav-link"
                            to={`${devicesPath}/${device.id}`}
                        >
                            {device.name ? device.name : device.id}
                        </Link>
                    </td>
                    <td className={styles.connectedColumn}>
                        <DeviceConnectedField connected={device.connected} />
                    </td>
                    <td className="text-end">
                        <HasGroupRole
                            groupId={organizationContext}
                            role={GroupRole.EDITOR}
                        >
                            <ButtonGroup>
                                <DeviceContentFormModal
                                    device={device}
                                    showButton
                                />
                                <OverflowMenu variant="secondary">
                                    <DeviceDeleteButton
                                        deviceId={device.id}
                                        variant="dropdown"
                                    />
                                </OverflowMenu>
                            </ButtonGroup>
                        </HasGroupRole>
                    </td>
                </tr>
            );
        }
    );

    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Connected</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>{devicesList}</tbody>
        </Table>
    );
}

export default DeviceList;
