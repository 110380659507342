import React, { forwardRef, ReactElement } from "react";
import { asUploadButton } from "@rpldy/upload-button";
import Button from "core/components/UI/Button/Button";

// eslint-disable-next-line react/display-name
const CustomUploadButton = asUploadButton(
    // eslint-disable-next-line react/display-name
    forwardRef((props, ref) => (
        <Button ref={ref} {...props} size="sm">
            Upload
        </Button>
    ))
);

export function VideoUploadButton(): ReactElement {
    return (
        <div className="d-grid w-100">
            <CustomUploadButton />{" "}
        </div>
    );
}
