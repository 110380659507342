import React from "react";
import { ReactElement } from "react";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { groupPath } from "core/util/routes";
import { UserRemoveFromGroupButton } from "user/button/UserRemoveFromGroupButton";
import { UserAddToGroupButton } from "user/button/UserAddToGroupButton";
import styles from "./UserGroupCard.scss";
import { useHasGroupRole } from "core/auth/useHasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { UserGroup } from "model/UserGroup";
import { useRoleLabel } from "hooks/useRoleLabel";
import LocalEvents from "events/LocalEvents";
import { IsAdmin } from "core/auth/IsAdmin";

interface UserGroupsCardProps {
    userId: string;
    email: string;
    groups: UserGroup[];
    isProfile?: boolean;
}

interface GroupNameFieldProps {
    name: string;
    groupId: string;
}

function GroupNameField(props: GroupNameFieldProps): ReactElement {
    const isOrganizationAdminForGroup = useHasGroupRole(
        "props.groups",
        GroupRole.ORGANIZATION_ADMIN
    );

    if (isOrganizationAdminForGroup) {
        return (
            <Link className="nav-link" to={`${groupPath}/${props.groupId}`}>
                {props.name}
            </Link>
        );
    }
    return <div className={styles.groupName}>{props.name}</div>;
}

export function UserGroupsCard(props: UserGroupsCardProps): ReactElement {
    const roleLabel = useRoleLabel();

    return (
        <Card>
            <Card.Body>
                <Card.Title className="d-flex justify-content-between">
                    <span>Groups</span>
                    <IsAdmin>
                        <UserAddToGroupButton
                            email={props.email}
                            currentGroups={props.groups}
                        />
                    </IsAdmin>
                </Card.Title>
                <Table hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Role</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.groups.map((group, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <GroupNameField
                                            groupId={group.id}
                                            name={group.name}
                                        />
                                    </td>
                                    <td className="align-middle">
                                        {roleLabel(group.role)}
                                    </td>
                                    <td className="text-end">
                                        <UserRemoveFromGroupButton
                                            userId={props.userId}
                                            groupId={group.id}
                                            successEvent={
                                                LocalEvents.USER_CHANGED
                                            }
                                            isProfile={props.isProfile}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}
