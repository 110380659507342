import React, { ReactElement } from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ListGroup } from "react-bootstrap";
import { PlayerUpdateListItem } from "sysadmin/PlayerAdminPage/components/PlayerUpdateListItem";
import { PlayerReloadPluginsListItem } from "sysadmin/PlayerAdminPage/components/PlayerReloadPluginsListItem";

export function PlayerAdminPage(): ReactElement {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>
                    Player Administation
                </PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <ListGroup>
                    <PlayerUpdateListItem />
                    <PlayerReloadPluginsListItem />
                </ListGroup>
            </PageLayout.Body>
        </PageLayout>
    );
}
