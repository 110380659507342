import React, { ReactElement } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import { BooleanInput } from "core/components/input/BooleanInput";
import { GroupInput } from "user/input/GroupInput";
import { RoleInput } from "user/input/RoleInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectInputChoice } from "core/components/input/SelectInput";
import { User } from "model/User";
import { ShowIf } from "core/util/ShowIf";
import { useRoleLabel } from "hooks/useRoleLabel";

export interface UserCreateFormFields {
    email: string;
    firstName: string;
    lastName: string;
    groups?: SelectInputChoice[] | string[];
    roles: SelectInputChoice[] | string[];
    enabled: boolean;
}

const schema = yup.object({
    email: yup.string().email().required("E-mail is a required field"),
    firstName: yup.string().required("First name is a required field"),
    lastName: yup.string().required("Last name is a required field"),
    groups: yup
        .array()
        .when("$isCreate", {
            is: true,
            then: (innerSchema) =>
                innerSchema.required("Group is a required field"),
        })
        .transform((groups) => {
            return groups && groups.length > 0
                ? groups.map((group: SelectInputChoice) => group.value)
                : undefined;
        }),
    roles: yup
        .array()
        .transform((roles) => {
            return roles && roles.length > 0
                ? roles.map((role: SelectInputChoice) => role.value)
                : undefined;
        })
        .required("Roles is a required field"),
    enabled: yup.boolean().required("Enabled is a required field"),
});

interface UserFormProps {
    existingUser?: User;
    handleSubmit: (data: UserCreateFormFields) => void;
    isLoading: boolean;
}

export function UserForm(props: UserFormProps): ReactElement {
    const isCreate = !props.existingUser;
    const getRoleLabel = useRoleLabel();
    const form = useForm<UserCreateFormFields>({
        resolver: yupResolver(schema, {
            context: { isCreate: isCreate },
        }),
        defaultValues: props.existingUser
            ? {
                  email: props.existingUser.email,
                  firstName: props.existingUser.firstName,
                  lastName: props.existingUser.lastName,
                  roles: props.existingUser.roles?.map((role) => {
                      return { value: role, label: getRoleLabel(role) };
                  }),
                  enabled: props.existingUser.enabled,
              }
            : {
                  enabled: true,
              },
    });

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(props.handleSubmit)}>
                <TextInput
                    name="email"
                    label="E-mail"
                    registerOptions={{
                        required: "E-mail is a required field",
                    }}
                />
                <Row>
                    <Col>
                        <TextInput
                            name="firstName"
                            label="First name"
                            registerOptions={{
                                required: "First name is a required field",
                            }}
                        />
                    </Col>
                    <Col>
                        <TextInput
                            name="lastName"
                            label="Last name"
                            registerOptions={{
                                required: "Last name is a required field",
                            }}
                        />
                    </Col>
                </Row>

                <ShowIf condition={isCreate}>
                    <Row>
                        <Col>
                            <GroupInput name="groups" label="Groups" isMulti />
                        </Col>
                    </Row>
                </ShowIf>

                <RoleInput />

                <BooleanInput name="enabled" label="Enabled" />

                <Button
                    variant="primary"
                    type="submit"
                    disabled={props.isLoading}
                >
                    Save
                </Button>
            </Form>
        </FormProvider>
    );
}
