import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useCreateSchedule(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v2/schedules`,
        "POST",
        LocalEvents.SCHEDULES_CHANGED
    );
}
