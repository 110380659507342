import environment from "core/util/environment";
import useFetchPersist, { PersistData } from "hooks/useFetchPersist";

export function useUpdateGroupUser(
    groupId: string,
    userId: string
): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v2/groups/${groupId}/users/${userId}`,
        "PATCH"
    );
}
