import { EnrichedTemplateConfiguration } from "core/components/Plugin/PluginContext/PluginContextProvider";
import usePlugins from "hooks/usePlugins";

export function useTemplate(
    templateIdentifier: string
): EnrichedTemplateConfiguration {
    const { findTemplate } = usePlugins();

    return findTemplate(templateIdentifier);
}
