import React, { ReactElement, useEffect, useState } from "react";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { Paging } from "@castia/sdk";
import {
    fontPageSize,
    useFetchAllFonts,
} from "hooks/api/media/font/useFetchAllFonts";
import { FontList } from "font/list/FontList";
import { Font } from "model/Font";

export function FontLibrary(): ReactElement {
    const [fonts, setFonts] = useState<Font[]>(null);

    const { response, isLoading, error, refreshData } = useFetchAllFonts();

    // Set the images in the state. This ensures that reloads caused by newly uploaded images don't cause a flashing
    // effect caused by the ResponseErrorHandler component.
    useEffect((): void => {
        if (response) {
            setFonts(response.items);
        }
    }, [response]);

    if (fonts) {
        return (
            <div>
                <FontList fonts={fonts} />
                <div className="d-flex justify-content-end">
                    <Paging
                        currentPage={response.meta.currentPage}
                        onClick={(page): void => {
                            refreshData(
                                new URLSearchParams({
                                    page: String(page),
                                    limit: String(fontPageSize),
                                })
                            );
                        }}
                        totalPages={response.meta.totalPages}
                        showMax={10}
                    />
                </div>
            </div>
        );
    }

    return <ResponseErrorHandler isLoading={isLoading} error={error} />;
}
