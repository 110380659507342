import * as React from "react";
import Button from "core/components/UI/Button/Button";
import { ReactElement, useState } from "react";
import ChannelCreateModal from "channel/list/ChannelList/ChannelCreator/ChannelCreatorModal/ChannelCreateModal";

/**
 * Button to create a new channel.
 * @constructor
 */
function ChannelCreateButton(): ReactElement {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                variant={"primary"}
                size={"sm"}
                onClick={(): void => setShowModal(true)}
            >
                New Channel
            </Button>
            <ChannelCreateModal
                show={showModal}
                onDone={(): void => setShowModal(false)}
            />
        </>
    );
}

export default ChannelCreateButton;
