import React, { ReactElement } from "react";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { useFetchSceneUsage } from "hooks/api/scene/useFetchSceneUsage";
import { ListGroup } from "react-bootstrap";
import { channelsPath, devicesPath } from "core/util/routes";
import { ListGroupItemLink } from "core/components/UI/ListGroupItemLink/ListGroupItemLink";

interface DeleteSceneModalBodyProps {
    sceneId: string;
}

/**
 * Component to show the delete channel modal body which shows which devices are affected by the deletion.
 * @param props
 * @constructor
 */
function DeleteSceneModalBody(props: DeleteSceneModalBodyProps): ReactElement {
    const { response, isLoading, error } = useFetchSceneUsage(props.sceneId);

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            {response?.channels?.length === 0 &&
                response?.devices?.length === 0 && (
                    <div>
                        This scene is not used in any timetables and is not set
                        directly to any devices.
                    </div>
                )}
            {response?.channels?.length > 0 && (
                <>
                    This action will remove the scene from the following
                    channels:
                    <ListGroup>
                        {response.channels.map((channel): ReactElement => {
                            return (
                                <ListGroupItemLink
                                    key={channel.id}
                                    to={`${channelsPath}/${channel.id}`}
                                >
                                    {channel.title}
                                </ListGroupItemLink>
                            );
                        })}
                    </ListGroup>
                </>
            )}
            <br />
            {response?.devices?.length > 0 && (
                <>
                    This scene is currently used by the devices below. If you
                    remove it, the devices will no longer have any content set
                    on them.
                    <ListGroup>
                        {response.devices.map((device) => {
                            return (
                                <ListGroupItemLink
                                    key={device.id}
                                    to={`${devicesPath}/${device.id}`}
                                >
                                    {device.name}
                                </ListGroupItemLink>
                            );
                        })}
                    </ListGroup>
                </>
            )}
        </ResponseErrorHandler>
    );
}

export default DeleteSceneModalBody;
