import { Route, RouteComponentProps, Switch } from "react-router";
import React, { ReactElement } from "react";
import { UserListPage } from "user/UserListPage";
import { UserCreatePage } from "user/UserCreatePage";
import { UserShowPage } from "user/UserShowPage";
import { UserEditPage } from "user/UserEditPage";

export function UserRouter({
    match,
}: RouteComponentProps): ReactElement<RouteComponentProps> {
    return (
        <Switch>
            <Route
                path={`${match.url}/create`}
                exact
                component={UserCreatePage}
            />
            <Route
                path={`${match.url}/:userId`}
                exact
                component={UserShowPage}
            />
            <Route
                path={`${match.url}/:userId/edit`}
                exact
                component={UserEditPage}
            />
            <Route path={`${match.url}`} component={UserListPage} />
        </Switch>
    );
}
