import React, { ReactElement, useState } from "react";
import DropdownTreeSelect, {
    TreeData,
    TreeNode,
} from "react-dropdown-tree-select";
import styles from "core/folder/FolderSelectInput/FolderSelectInput.scss";
import { Folder } from "model/Folder";
import { useFormContext, UseFormRegister } from "react-hook-form";

interface FolderSelectInputProps<FormData> {
    name: string;
    register: UseFormRegister<FormData>;
    currentValue: string;
    folders: Folder[];
}

function foldersToData(
    folders: Folder[],
    currentValue?: string,
    selectedValue?: string
): TreeData {
    return folders.map((folder) => {
        const currentIsSelected = selectedValue === folder.id;

        return {
            label: folder.name,
            value: folder.id,
            children: folder.children
                ? foldersToData(folder.children, currentValue, selectedValue)
                : null,
            checked: currentIsSelected,
        };
    });
}

export function FolderSelectInput<FormData>(
    props: FolderSelectInputProps<FormData>
): ReactElement {
    const [selectedFolder, setSelectedFolder] = useState(props.currentValue);
    const { register, setValue } = useFormContext();

    function onChange(currentNode: TreeNode, selectedNodes: TreeNode[]) {
        if (!selectedNodes || selectedNodes.length === 0) {
            setSelectedFolder("");
            setValue(props.name, "");
        } else {
            setSelectedFolder(currentNode.value);
            setValue(props.name, currentNode.value);
        }
    }

    return (
        <>
            <DropdownTreeSelect
                data={foldersToData(
                    props.folders,
                    props.currentValue,
                    selectedFolder
                )}
                onChange={onChange}
                mode="radioSelect"
                className={styles.folderSelect}
                inlineSearchInput
            />
            <input type="hidden" {...register(props.name)} />
        </>
    );
}
