import * as React from "react";

import ScenesListContainer from "scene/ScenesOverviewPage/components/ScenesListContainer/ScenesListContainer";
import SceneCreateModal from "scene/ScenesOverviewPage/components/SceneCreator/SceneCreateModal";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { FolderCreateButton } from "core/folder/FolderCreateButton/FolderCreateButton";
import { FolderType } from "model/FolderType";
import { ButtonGroup } from "react-bootstrap";
import LocalEvents from "events/LocalEvents";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";

export default function SceneOverviewPage() {
    const organizationContext = useOrganizationContext();

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Scenes</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <HasGroupRole
                        groupId={organizationContext}
                        role={GroupRole.EDITOR}
                    >
                        <ButtonGroup>
                            <FolderCreateButton
                                type={FolderType.SCENE}
                                successEvent={LocalEvents.SCENES_CHANGED}
                            />
                            <SceneCreateModal />
                        </ButtonGroup>
                    </HasGroupRole>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <ScenesListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}
