import React, { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import PageLayoutHeaderTitle from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderTitle/PageLayoutHeaderTitle";
import PageLayoutHeaderSecondary from "core/components/UI/PageLayout/PageLayoutHeader/ListPageLayoutHeaderSecondary/PageLayoutHeaderSecondary";
import { classNames } from "@castia/sdk";
import styles from "core/components/UI/PageLayout/PageLayoutHeader/PageLayoutHeader.scss";

function PageLayoutHeader(
    props: React.PropsWithChildren<unknown>
): ReactElement {
    return (
        <Row><Col className={classNames("d-flex justify-content-between", styles.header)}>{props.children}</Col></Row>
    );
}

PageLayoutHeader.Title = PageLayoutHeaderTitle;
PageLayoutHeader.Secondary = PageLayoutHeaderSecondary;
export default PageLayoutHeader;
