import { FetchData, useEventListener, useFetch } from "@castia/sdk";
import environment from "core/util/environment";
import { DashboardData } from "model/DashboardData";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";

export function useDashboardData(): FetchData<DashboardData> {
    const fetchData = useFetch<DashboardData>(
        `${environment.apiBaseUrl}/api/v1/dashboard`
    );

    // Reload data if the organization changes. Org change always redirects to this page, but if the user was already on this page, we need to refresh the data.
    const organizationCallback = useCallback(() => {
        fetchData.refreshData();
    }, []);
    useEventListener(
        LocalEvents.ORGANIZATION_CONTEXT_CHANGED,
        organizationCallback
    );

    return fetchData;
}
