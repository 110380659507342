import React from "react";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { newsPath } from "core/util/routes";

export function NewsCreateButton() {
    return (
        <LinkButton to={`${newsPath}/create`} variant="primary">
            New Article
        </LinkButton>
    );
}
