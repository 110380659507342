import { TenantProfile } from "model/TenantProfile";
import { useHistory } from "react-router-dom";
import { tenantProfilePath } from "core/util/routes";
import React from "react";
import {
    TenantProfileCreateFormFields,
    TenantProfileForm,
} from "tenant-profile/TenantProfileForm";
import { usePatchTenantProfile } from "hooks/api/tenant-profile/usePatchTenantProfile";
import { FieldNamesMarkedBoolean } from "react-hook-form/dist/types/form";

interface TenantProfileEditFormProps {
    tenantProfile: TenantProfile;
}

export function TenantProfileEditForm(props: TenantProfileEditFormProps) {
    const { sendRequest, isLoading } = usePatchTenantProfile(
        props.tenantProfile.id
    );
    const history = useHistory();

    async function onSubmit(
        data: TenantProfileCreateFormFields,
        dirtyFields: FieldNamesMarkedBoolean<TenantProfileCreateFormFields>
    ): Promise<void> {
        const updatedFields: any = {
            keycloakSettings: {},
        };
        Object.keys(dirtyFields).forEach(
            (key: keyof TenantProfileCreateFormFields) => {
                const isDirty = dirtyFields[key];
                if (isDirty) {
                    if (key.startsWith("keycloak")) {
                        const keycloakLessKey = key.slice(8);
                        updatedFields.keycloakSettings[
                            keycloakLessKey.charAt(0).toLowerCase() +
                                keycloakLessKey.slice(1)
                        ] = data[key];
                    } else {
                        updatedFields[key] = data[key];
                    }
                }
            }
        );

        const result = await sendRequest(updatedFields);
        if (result) {
            history.push(`${tenantProfilePath}`);
        }
    }

    return (
        <TenantProfileForm
            handleSubmit={onSubmit}
            isLoading={isLoading}
            existingTenantProfile={props.tenantProfile}
        />
    );
}
