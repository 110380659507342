import { Group } from "model/Group";
import React from "react";
import { ReactElement } from "react";
import { Card } from "react-bootstrap";

interface GroupOverviewCardProps {
    group: Group;
}

export function GroupOverviewCard(props: GroupOverviewCardProps): ReactElement {
    return (
        <Card>
            <Card.Body>
                <Card.Title>Group</Card.Title>
                <dl>
                    <dt>ID</dt>
                    <dd>{props.group.id}</dd>
                    <dt>Name</dt>
                    <dd>{props.group.name}</dd>
                </dl>
            </Card.Body>
        </Card>
    );
}
