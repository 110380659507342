import PageLayout from "core/components/UI/PageLayout/PageLayout";
import React from "react";
import { TenantProfileCreateForm } from "tenant-profile/create/TenantProfileCreateForm";

export function TenantProfileCreatePage() {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>
                    Create Tenant Profile
                </PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <TenantProfileCreateForm />
            </PageLayout.Body>
        </PageLayout>
    );
}
