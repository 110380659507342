import { PersistData } from "@castia/sdk";
import useFetchPersist from "hooks/useFetchPersist";
import environment from "core/util/environment";

export function useUpdateNewsItem(newsItemId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/news/${newsItemId}`,
        "PUT"
    );
}
