import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useUpdateChannel(channelId: string): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/channels/${channelId}`,
        "PATCH",
        LocalEvents.CHANNELS_CHANGED
    );
}
