import React from "react";
import styles from "./LogoPreviewField.scss";

interface LogoPreviewFieldProps {
    logoUrl: string;
}

export function LogoPreviewField(props: LogoPreviewFieldProps) {
    return (
        <img src={props.logoUrl} alt="Logo preview" className={styles.logo} />
    );
}
