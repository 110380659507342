import { UseFormReturn, useWatch } from "react-hook-form";
import React, { useEffect } from "react";
import { RRule } from "rrule";
import { createRrule } from "schedule/schedule-event/validator/createRrule";

export function useEventFormRrule(form: UseFormReturn<any>): RRule | undefined {
    const [rrule, setRrule] = React.useState<RRule | undefined>(undefined);
    const [
        frequency,
        interval,
        byweekday,
        bymonthday,
        startDate,
        startTime,
        repeatingEnd,
        repeating,
        bysetpos,
    ] = useWatch({
        name: [
            "frequency",
            "interval",
            "byweekday",
            "bymonthday",
            "startDate",
            "startTime",
            "repeatingEnd",
            "repeating",
            "bysetpos",
        ],
        control: form.control,
        defaultValue: form?.getValues([
            "frequency",
            "interval",
            "byweekday",
            "bymonthday",
            "startDate",
            "startTime",
            "repeatingEnd",
            "repeating",
            "bysetpos",
        ]),
    });

    useEffect(() => {
        const rule = createRrule(
            frequency,
            interval,
            byweekday,
            bymonthday,
            startDate,
            startTime,
            repeatingEnd,
            repeating,
            bysetpos
        );
        setRrule(rule);
    }, [
        frequency,
        interval,
        byweekday,
        bymonthday,
        startDate,
        startTime,
        repeatingEnd,
        repeating,
        bysetpos,
    ]);

    return rrule;
}
