import { Dropdown } from "react-bootstrap";
import * as React from "react";
import { useCopyScene } from "hooks/api/scene/useCopyScene";
import { FaCopy } from "react-icons/fa";
import { toast } from "react-toastify";

interface SceneCopyDropdownItemProps {
    sceneId: string;
}

export function SceneCopyDropdownItem(props: SceneCopyDropdownItemProps) {
    const { sendRequest } = useCopyScene(props.sceneId);

    async function onClick(): Promise<void> {
        await sendRequest();
        toast.success("Scene copied!");
    }

    return (
        <Dropdown.Item
            onClick={() => {
                onClick();
            }}
            className="d-flex align-items-center"
        >
            <FaCopy color="var(--bs-info)" />
            &nbsp;Copy scene
        </Dropdown.Item>
    );
}
