import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Modal } from "react-bootstrap";
import { TextInput } from "core/components/input/TextInput";
import * as yup from "yup";
import { useInviteUserToGroup } from "group/hooks/useInviteUserToGroup";
import Button from "core/components/UI/Button/Button";
import { GroupRole } from "core/auth/GroupRole";
import { GroupRoleInput } from "user/input/GroupRoleInput";

interface UserInviteToGroupFormData {
    email: string;
    role: GroupRole;
}

interface UserInviteToGroupButtonProps {
    onClose: () => void;
    groupId: string;
}

const schema = yup.object({
    email: yup.string().email().required(),
    role: yup
        .string()
        .required()
        .transform((role) => {
            return role ? role.value : undefined;
        }),
});

export function UserInviteToGroupModalBody(
    props: UserInviteToGroupButtonProps
) {
    const form = useForm<UserInviteToGroupFormData>({
        resolver: yupResolver(schema),
    });
    const inviteUserToGroup = useInviteUserToGroup(props.groupId);

    async function onSubmit(data: UserInviteToGroupFormData): Promise<void> {
        await inviteUserToGroup.sendRequest(data);
    }

    return (
        <>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Body>
                    <FormProvider {...form}>
                        <TextInput name="email" label="Email" />
                        <GroupRoleInput groupId={props.groupId} />
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={inviteUserToGroup.isLoading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </>
    );
}
