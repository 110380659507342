import { useFormContext, useWatch } from "react-hook-form";
import React, { useEffect } from "react";
import { Frequency } from "rrule";
import { Form } from "react-bootstrap";
import { addDays, getISODay } from "date-fns";
import { weekdays } from "schedule/schedule-event/input/WeekdaysInput";
import { ScheduleEvent } from "model/ScheduleEvent";

interface MonthlyInputProps {
    existingEvent?: ScheduleEvent;
}

const numberNames: { [key: number]: string } = {
    1: "first",
    2: "second",
    3: "third",
    4: "fourth",
    5: "fifth",
};

export enum MONTHLY_CHOICE {
    ON_DAY_NUMBER = "onDayNumber",
    EVERY_NTH_WEEKDAY = "everyNthWeekday",
    LAST_WEEKDAY = "lastWeekday",
}

export function MonthlyInput(props: MonthlyInputProps) {
    const form = useFormContext();
    const [monthlyRadioValue, setMonthlyRadioValue] = React.useState(
        MONTHLY_CHOICE.ON_DAY_NUMBER
    );
    const [frequency, startDate] = useWatch({
        name: ["frequency", "startDate"],
    });

    useEffect(() => {
        if (
            props.existingEvent &&
            props.existingEvent.frequency === Frequency.MONTHLY.valueOf()
        ) {
            const [byweekday, bysetpos] = form.getValues([
                "byweekday",
                "bysetpos",
            ]);
            if (byweekday && byweekday.length > 0) {
                if (bysetpos === -1) {
                    setMonthlyRadioValue(MONTHLY_CHOICE.LAST_WEEKDAY);
                } else {
                    setMonthlyRadioValue(MONTHLY_CHOICE.EVERY_NTH_WEEKDAY);
                }
            } else {
                setMonthlyRadioValue(MONTHLY_CHOICE.ON_DAY_NUMBER);
            }
        }
    }, [props.existingEvent]);

    useEffect(() => {
        if (frequency === Frequency.MONTHLY) {
            form.setValue("byweekday", []);
            form.setValue("bymonthday", new Date(startDate).getDate());
            form.setValue("bysetpos", null);
        }
    }, [frequency, startDate]);
    const dateDay = new Date(startDate).getDate();

    function onChange(data: any) {
        setMonthlyRadioValue(data.currentTarget.value);
        if (data.currentTarget.value === MONTHLY_CHOICE.ON_DAY_NUMBER) {
            form.setValue("byweekday", []);
            form.setValue("bymonthday", new Date(startDate).getDate());
            form.setValue("bysetpos", null);
        } else if (
            data.currentTarget.value === MONTHLY_CHOICE.EVERY_NTH_WEEKDAY
        ) {
            form.setValue("byweekday", [
                weekdays[getISODay(new Date(startDate)) - 1].value,
            ]);
            form.setValue("bysetpos", Math.ceil(dateDay / 7));
            form.setValue("bymonthday", null);
        } else if (data.currentTarget.value === MONTHLY_CHOICE.LAST_WEEKDAY) {
            form.setValue("byweekday", [
                weekdays[getISODay(new Date(startDate)) - 1].value,
            ]);
            form.setValue("bysetpos", -1);
            form.setValue("bymonthday", null);
        }
    }

    if (frequency !== Frequency.MONTHLY) {
        return null;
    }

    const isLastDay =
        new Date(startDate).getMonth() !==
        addDays(new Date(startDate), 7).getMonth();

    return (
        <div>
            <Form.Group>
                <Form.Check
                    label={`On day ${new Date(startDate).getDate()}`}
                    name="monthlyRadio"
                    type="radio"
                    value={MONTHLY_CHOICE.ON_DAY_NUMBER}
                    id={"monthly-radio-1"}
                    onChange={onChange}
                    checked={monthlyRadioValue === MONTHLY_CHOICE.ON_DAY_NUMBER}
                />
                <Form.Check
                    label={`Every ${
                        numberNames[Math.ceil(dateDay / 7)]
                    } ${new Date(startDate).toLocaleString("default", {
                        weekday: "long",
                    })}`}
                    type="radio"
                    value={MONTHLY_CHOICE.EVERY_NTH_WEEKDAY}
                    id={"monthly-radio-2"}
                    name={"monthlyRadio"}
                    onChange={onChange}
                    checked={
                        monthlyRadioValue === MONTHLY_CHOICE.EVERY_NTH_WEEKDAY
                    }
                />
                {isLastDay && (
                    <Form.Check
                        label={`Every last ${new Date(startDate).toLocaleString(
                            "default",
                            {
                                weekday: "long",
                            }
                        )}`}
                        type="radio"
                        value={MONTHLY_CHOICE.LAST_WEEKDAY}
                        id={"monthly-radio-3"}
                        name={"monthlyRadio"}
                        onChange={onChange}
                        checked={
                            monthlyRadioValue === MONTHLY_CHOICE.LAST_WEEKDAY
                        }
                    />
                )}
            </Form.Group>
        </div>
    );
}
