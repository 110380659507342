import useFetch, { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import { useEventListener } from "@castia/sdk";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";
import { Schedule } from "model/Schedule";

export function useFetchAllSchedules(): FetchData<Schedule[]> {
    const fetch = useFetch<Schedule[]>(
        `${environment.apiBaseUrl}/api/v2/schedules?all=1`
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.SCHEDULES_CHANGED, eventCallback);

    return fetch;
}
