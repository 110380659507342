import React, { ReactElement } from "react";
import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { groupPath } from "core/util/routes";
import { FaEdit } from "react-icons/fa";

interface GroupEditButtonProps {
    groupId: string;
}

export function GroupEditButton(props: GroupEditButtonProps): ReactElement {
    return <LinkButton
        to={`${groupPath}/${props.groupId}/edit`}
        variant="primary"
        size="md"
    >
        <FaEdit />
    </LinkButton>;
}
