/* global SystemJS */
import React, { ReactElement, useEffect, useRef, useState } from "react";
import PluginContext from "core/components/Plugin/PluginContext/PluginContext";
import useFetchEnabledTemplatePlugins from "hooks/api/plugin/useFetchEnabledTemplatePlugins";
import Loader from "core/components/UI/Loader/Loader";
import Plugin from "model/Plugin";
import {
    PageConfiguration,
    PagePluginConfiguration,
    PluginType,
    TemplateConfiguration,
    TemplatePluginConfiguration,
} from "@castia/sdk";
import * as Sentry from "@sentry/react";

/**
 * Extension of the TemplateConfiguration for local usage. It adds the templateIdentifier, which is the concatenation of
 * the pluginId from the pluginService and the key from the TemplateConfiguration.
 */
export interface EnrichedTemplateConfiguration extends TemplateConfiguration {
    templateIdentifier: string;
    resourceDirectory: string;
}

/**
 * List of loaded plugins.
 */
export interface LoadedPlugins {
    templates: EnrichedTemplateConfiguration[];
    findTemplate: (template: string) => EnrichedTemplateConfiguration;
    pages: PageConfiguration[];
}

/**
 * Provider for the installed plugins. It loads the plugins and exposes the metadata about these plugins so other
 * components can use these to display them.
 * @param props
 * @constructor
 */
function PluginContextProvider(
    props: React.PropsWithChildren<unknown>
): ReactElement {
    const { response, isLoading, error } = useFetchEnabledTemplatePlugins();
    const templates = useRef<EnrichedTemplateConfiguration[]>(null);
    const pages = useRef<PageConfiguration[]>(null);
    const [loaded, isLoaded] = useState(false);

    useEffect(() => {
        if (isLoading) {
            isLoaded(false);
        }
        if (response && !isLoading && !error) {
            const loadPluginCode = async () => {
                const loadedTemplates = await loadPluginTemplates(
                    response.filter(
                        (plugin) => plugin.type === PluginType.TEMPLATE
                    )
                );
                const loadedPages = await loadPluginPages(
                    response.filter((plugin) => plugin.type === PluginType.PAGE)
                );
                templates.current = loadedTemplates;
                pages.current = loadedPages;
            };
            loadPluginCode().then((): void => {
                isLoaded(true);
            });
        }
    }, [response, isLoading, error]);

    if (isLoading) {
        return <Loader />;
    } else if (error) {
        // TODO proper error handling.
        return <div>Something went wrong while loading the plugins.</div>;
    }

    if (!loaded) {
        return <Loader />;
    }

    const loadedPlugins: LoadedPlugins = {
        templates: templates.current,
        findTemplate: (template: string): EnrichedTemplateConfiguration => {
            return templates.current.find(
                (temp): boolean => temp.templateIdentifier === template
            );
        },
        pages: pages.current,
    };

    return (
        <PluginContext.Provider value={loadedPlugins}>
            {props.children}
        </PluginContext.Provider>
    );
}

/**
 * Import plugins into the runtime via SystemJS.
 * @param installedPlugins
 */
async function loadPluginTemplates(
    installedPlugins: Plugin[]
): Promise<EnrichedTemplateConfiguration[]> {
    const templates: EnrichedTemplateConfiguration[] = [];
    for (const installedPlugin of installedPlugins) {
        try {
            const parcel: { configuration: TemplatePluginConfiguration } =
                await SystemJS.import(installedPlugin.file);
            const templateConfig = parcel.configuration.templateConfiguration;
            if (Array.isArray(templateConfig)) {
                for (const templateConfiguration of templateConfig) {
                    templates.push(
                        enrichTemplateConfiguration(
                            installedPlugin,
                            templateConfiguration
                        )
                    );
                }
            } else {
                templates.push(
                    enrichTemplateConfiguration(installedPlugin, templateConfig)
                );
            }
        } catch (error) {
            console.error(`Error loading plugin ${installedPlugin.id}`, error);
            Sentry.captureException(error);
        }
    }
    return templates;
}

async function loadPluginPages(
    installedPlugins: Plugin[]
): Promise<PageConfiguration[]> {
    const pages: PageConfiguration[] = [];
    for (const installedPlugin of installedPlugins) {
        try {
            const parcel: { configuration?: PagePluginConfiguration } =
                await SystemJS.import(installedPlugin.file);
            if (parcel.configuration?.pageConfiguration) {
                pages.push(...parcel.configuration.pageConfiguration);
            } else {
                throw new Error(
                    "Could not load plugin as page plugin. Plugin has no exported pageConfiguration."
                );
            }
        } catch (error) {
            console.error(
                `Error loading page plugin ${installedPlugin.id}`,
                error
            );
            Sentry.captureException(error);
        }
    }

    return pages;
}

/**
 * Enrich the Template configuration for local usage. This basically comes down to combining information from the
 * PluginService and the configuration in the plugins source code.
 *
 * It adds a unique identifier to fetch the templates on. By doing this in this way template keys need to only be unique
 * within a plugin.
 *
 * @param plugin
 * @param templateConfiguration
 */
function enrichTemplateConfiguration(
    plugin: Plugin,
    templateConfiguration: TemplateConfiguration
): EnrichedTemplateConfiguration {
    return {
        templateIdentifier: `${plugin.id}/${templateConfiguration.key}`,
        resourceDirectory: plugin.pluginDirectory || "http://localhost:9100",
        ...templateConfiguration,
    };
}

export default PluginContextProvider;
