import React, { ReactElement } from "react";
import { useReloadDevicePlugins } from "hooks/api/device/useReloadDevicePlugins";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { FaPuzzlePiece } from "react-icons/fa";

interface DeviceReloadPluginsButtonProps {
    deviceId: string;
}

export function DeviceReloadPluginsButton(props: DeviceReloadPluginsButtonProps): ReactElement {
    const reload = useReloadDevicePlugins(props.deviceId);

    async function reloadPlayer(): Promise<void> {
        const confirmResult = confirm("Are you sure you want to reload the plugins for this player?");
        if (confirmResult) {
            await reload.sendRequest();
            toast.info(`Player plugin reload triggered!`);
        }
    }

    return <Button
        variant="dark"
        onClick={reloadPlayer}
        title="Reload player plugins"
    >
        <FaPuzzlePiece />
    </Button>;
}
