import { Route, RouteComponentProps, Switch } from "react-router";
import React, { ReactElement } from "react";
import { NewsCreatePage } from "news/NewsCreatePage";
import { NewsListPage } from "news/NewsListPage";
import { NewsEditPage } from "news/NewsEditPage";

export function NewsRouter({
    match,
}: RouteComponentProps): ReactElement<RouteComponentProps> {
    return (
        <Switch>
            <Route
                path={`${match.url}/create`}
                exact
                component={NewsCreatePage}
            />
            <Route
                path={`${match.url}/:newsItemId/edit`}
                exact
                component={NewsEditPage}
            />
            <Route path={`${match.url}`} component={NewsListPage} />
        </Switch>
    );
}
