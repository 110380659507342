import * as React from "react";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { registerDevicePath } from "core/util/routes";

export function DeviceRegistrationButton(): ReactElement {
    return (
        <Link
            to={registerDevicePath}
            className="btn btn-primary btn-block btn-sm"
        >
            Register Device
        </Link>
    );
}
