import React, { ReactElement } from "react";
import { Card, Col, Row } from "react-bootstrap";
import styles from "home/SummaryCard.scss";
import { Variant } from "react-bootstrap/esm/types";
import { IconType } from "react-icons";

interface SummaryCardProps {
    bg: Variant;
    title: string;
    value: string | number;
    icon: IconType;
}

export function SummaryCard(props: SummaryCardProps): ReactElement {
    return (
        <Col md={3}>
            <Card bg={props.bg} className={styles.summaryCard}>
                <Card.Body>
                    <Row className="text-white">
                        <Col>
                            <div className="text-xs font-weight-bold text-uppercase mb-1">
                                <span className={styles.smallCardTitle}>
                                    {props.title}
                                </span>
                            </div>
                            <div className="h5 mb-0 font-weight-bold">
                                <strong>{props.value}</strong>
                            </div>
                        </Col>
                        <Col
                            xs="auto"
                            className="d-flex justify-content-center flex-column"
                        >
                            <props.icon size={28} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    );
}
