import React, { ReactElement, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaColumns } from "react-icons/fa";
import {
    SceneLayoutForm,
    SceneLayoutFormData,
} from "scene/ScenesOverviewPage/components/SceneLayoutForm";
import Scene from "model/Scene";
import Loader from "core/components/UI/Loader/Loader";
import { useUpdateScene } from "hooks/api/scene/useUpdateScene";
import { useTemplate } from "hooks/useTemplate";

interface SceneLayoutButtonProps {
    scene: Scene;
}

export function SceneLayoutButton(props: SceneLayoutButtonProps): ReactElement {
    const [showModal, setShowModal] = useState(false);
    const { sendRequest, error, isLoading } = useUpdateScene(props.scene.id);
    const template = useTemplate(props.scene.template);

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    async function onSubmit(data: SceneLayoutFormData) {
        const result = await sendRequest({
            layout: data.layout,
        });
        if (result) {
            setShowModal(false);
        }
    }

    // If there is only one layout, don't show the layout button.
    if (template.layouts.length <= 1) {
        return <></>;
    }

    return (
        <>
            <Button variant="info" title="Select Layout" onClick={openModal}>
                <FaColumns />
            </Button>
            <Modal
                show={showModal}
                size="xl"
                onHide={(): void => setShowModal(false)}
            >
                <Modal.Header>Select Layout</Modal.Header>
                <SceneLayoutForm
                    error={error}
                    onSubmit={onSubmit}
                    secondaryButtonAction={closeModal}
                    template={props.scene.template}
                    initialLayout={props.scene.layout}
                />
                {isLoading && <Loader />}
            </Modal>
        </>
    );
}
