import React, { ReactElement, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { FolderEditModal } from "core/folder/FolderEditButton/FolderEditModal";

interface FolderEditButtonProps {
    folderId: string;
    successEvent?: string;
}

export function FolderEditButton(props: FolderEditButtonProps): ReactElement {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                onClick={(): void => setShowModal(true)}
                variant="info"
                title="Edit folder"
            >
                <FaEdit />
            </Button>
            <FolderEditModal
                show={showModal}
                onDone={() => setShowModal(false)}
                folderId={props.folderId}
                successEvent={props.successEvent}
            />
        </>
    );
}
