import { Card } from "react-bootstrap";
import React from "react";
import { KeycloakRealm } from "model/KeycloakRealm";

interface TenantProfileKeycloakCardProps {
    keycloakRealm: KeycloakRealm;
}

export function KeycloakRealmCard(props: TenantProfileKeycloakCardProps) {
    return (
        <Card>
            <Card.Header>Keycloak Settings</Card.Header>
            <Card.Body>
                <dl>
                    <dt>Realm Name</dt>
                    <dd>{props.keycloakRealm.realmName}</dd>
                    <dt>URL</dt>
                    <dd>{props.keycloakRealm.url}</dd>
                    <dt>API Client Name</dt>
                    <dd>{props.keycloakRealm.apiClientName}</dd>
                    <dt>API Client Secret</dt>
                    <dd>***************</dd>
                    <dt>Dashboard Client Name</dt>
                    <dd>{props.keycloakRealm.dashboardClientName}</dd>
                </dl>
            </Card.Body>
        </Card>
    );
}
