import React, { ReactElement } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ChannelSceneDTO from "model/ChannelSceneDTO";
import ChannelScenePreview from "channel/edit/preview/ChannelScenePreview";

interface SortableItemProps {
    channelScene: ChannelSceneDTO;
    channelId: string;
    id: string;
}

export function SortableChannelScenePreview(
    props: SortableItemProps
): ReactElement {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 100 : 1,
        opacity: isDragging ? 0.3 : 1,
    };

    return (
        <ChannelScenePreview
            channelId={props.channelId}
            channelScene={props.channelScene}
            attributes={attributes}
            listeners={listeners}
            ref={setNodeRef}
            style={style}
            hideContent={false}
        />
    );
}
