import { useState } from "react";
import { useItemProgressListener, useItemStartListener } from "@rpldy/uploady";

export interface UploadingItem {
    id: string;
    name: string;
    percentage: number;
    uploadedBytes: number;
}

export function useUploadProgress(): UploadingItem[] {
    const [items, setItems] = useState<UploadingItem[]>([]);

    useItemProgressListener((batchItem) => {
        setItems((prevState) => {
            const localItems = [...prevState];
            const itemIndex = prevState
                .map((item) => item.id)
                .indexOf(batchItem.id);
            localItems[itemIndex] = {
                ...localItems[itemIndex],
                percentage: batchItem.completed,
                uploadedBytes: batchItem.loaded,
            };

            return localItems;
        });
    });
    useItemStartListener((batchItem) => {
        setItems(() => {
            return [
                ...items,
                {
                    id: batchItem.id,
                    percentage: batchItem.completed,
                    uploadedBytes: batchItem.loaded,
                    name: batchItem.file.name,
                },
            ];
        });
    });

    return items;
}
