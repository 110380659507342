import React, { ReactElement } from "react";
import { useCreateUser } from "user/hooks/useCreateUser";
import { UserCreateFormFields, UserForm } from "user/UserForm";
import { useHistory } from "react-router-dom";
import { userPath } from "core/util/routes";

export function UserCreateForm(): ReactElement {
    const { sendRequest, isLoading } = useCreateUser();
    const history = useHistory();

    async function onSubmit(data: UserCreateFormFields): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            history.push(`${userPath}`);
        }
    }

    return <UserForm handleSubmit={onSubmit} isLoading={isLoading} />;
}
