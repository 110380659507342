import React, { ReactElement, useEffect, useState } from "react";
import { Video } from "model/Video";
import { Col, Row } from "react-bootstrap";
import { VideoDeleteButton } from "video/VideoDeleteButton";
import { VideoPreview } from "@castia/sdk";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { useHasGroupRole } from "core/auth/useHasGroupRole";

interface VideoOverviewProps {
    videos: Video[];
}

export function VideoOverview(props: VideoOverviewProps): ReactElement {
    const [selectedVideos, setSelectedVideos] = useState<Video[]>([]);
    const organizationContext = useOrganizationContext();
    const isEditor = useHasGroupRole(organizationContext, GroupRole.EDITOR);

    useEffect((): void => {
        // If the image array changed, check if all selected images still exist and remove them if not.
        const newSelectedVideos = selectedVideos.filter(
            (selectedVideo): boolean => {
                return props.videos.some((video: Video): boolean => {
                    return video.id === selectedVideo.id;
                });
            }
        );
        setSelectedVideos(newSelectedVideos);
    }, [props.videos]);

    function isSelected(videoId: string): boolean {
        const foundImage = selectedVideos.find(
            (selectedVideo): boolean => selectedVideo.id === videoId
        );

        return !!foundImage;
    }

    function toggleSelect(videoId: string): void {
        if (!isEditor) {
            return;
        }
        if (isSelected(videoId)) {
            setSelectedVideos(
                selectedVideos.filter(
                    (selectedImage): boolean => selectedImage.id !== videoId
                )
            );
        } else {
            const newSelectedVideo = props.videos.find(
                (video) => video.id === videoId
            );
            setSelectedVideos((oldSelectedVideos): Video[] => [
                ...oldSelectedVideos,
                newSelectedVideo,
            ]);
        }
    }

    const videoElements = props.videos.map((video): ReactElement => {
        return (
            <VideoPreview
                key={video.id}
                video={video}
                selected={isSelected(video.id)}
                toggleSelect={toggleSelect}
            />
        );
    });

    return (
        <>
            <Row className={selectedVideos.length === 0 && "invisible"}>
                <Col
                    lg={{ span: 2, offset: 10 }}
                    md={{ span: 3, offset: 9 }}
                    sm={12}
                >
                    <HasGroupRole
                        groupId={organizationContext}
                        role={GroupRole.EDITOR}
                    >
                        <VideoDeleteButton videos={selectedVideos} />
                    </HasGroupRole>
                </Col>
            </Row>
            <Row data-cy="videos-list">{videoElements}</Row>
        </>
    );
}
