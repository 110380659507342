import { Card } from "react-bootstrap";
import React from "react";
import { TenantProfileList } from "tenant-profile/list/TenantProfileList";
import { TenantProfile } from "model/TenantProfile";

interface KeycloakRealmTenantProfilesCardProps {
    tenantProfiles: TenantProfile[];
}

export function KeycloakRealmTenantProfilesCard(
    props: KeycloakRealmTenantProfilesCardProps
) {
    return (
        <Card>
            <Card.Header>Tenant Profiles using this Realm</Card.Header>
            <Card.Body>
                <TenantProfileList tenantProfiles={props.tenantProfiles} />
            </Card.Body>
        </Card>
    );
}
