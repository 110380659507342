import { useParams } from "react-router-dom";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import React from "react";
import { useFetchOneTheme } from "hooks/api/theme/useFetchOneTheme";
import { TenantThemeEditForm } from "tenant-profile/theme/edit/TenantThemeEditForm";

interface ThemeEditPageParams {
    tenantProfileId: string;
}

export function TenantThemeEditPage() {
    const { tenantProfileId } = useParams<ThemeEditPageParams>();
    const { response, isLoading, error } = useFetchOneTheme(tenantProfileId);

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Edit Theme</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && <TenantThemeEditForm theme={response} />}
                </ResponseErrorHandler>
            </PageLayout.Body>
        </PageLayout>
    );
}
