import React, { ReactElement } from "react";
import { SelectInput } from "core/components/input/SelectInput";
import { useRoleLabel } from "hooks/useRoleLabel";
import { useFetchManageableRoles } from "user/hooks/useFetchManageableRoles";
import { ResponseErrorHandler } from "@castia/sdk";

export function RoleInput(): ReactElement {
    const getRoleLabel = useRoleLabel();
    const { response, isLoading, error, refreshData } =
        useFetchManageableRoles();

    return (
        <ResponseErrorHandler
            isLoading={isLoading}
            error={error}
            retryAction={() => refreshData()}
        >
            {response && (
                <SelectInput
                    name="roles"
                    label="Roles"
                    choices={response.map((role) => {
                        return {
                            label: getRoleLabel(role),
                            value: role,
                        };
                    })}
                    registerOptions={{ required: "Roles is a required field" }}
                    isMulti
                />
            )}
        </ResponseErrorHandler>
    );
}
