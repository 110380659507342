import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { tenantProfilePath } from "core/util/routes";
import React from "react";
import { TenantProfile } from "model/TenantProfile";

interface TenantProfileListProps {
    tenantProfiles: TenantProfile[];
}

export function TenantProfileList(props: TenantProfileListProps) {
    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Dashboard domain</th>
                    <th>Player domain</th>
                </tr>
            </thead>
            <tbody>
                {props.tenantProfiles.map((tenantProfile, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <Link
                                    className="nav nav-link"
                                    to={`${tenantProfilePath}/${tenantProfile.id}`}
                                >
                                    {tenantProfile.dashboardDomain}
                                </Link>
                            </td>
                            <td>
                                {tenantProfile.playerDomain}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
