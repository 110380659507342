import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Content } from "model/Content";
import { ReactElement } from "react";
import useFetchOneScene from "hooks/api/scene/useFetchOneScene";
import Loader from "core/components/UI/Loader/Loader";
import TemplateContainer from "core/components/TemplateContainer/TemplateContainer";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import ScaledScene from "scene/ScenesEditorPage/components/ScaledScene/ScaledScene";

interface RouteInfo {
    scene: string;
}

interface SceneContainerProps extends RouteComponentProps<RouteInfo> {
    onContentChange?: (content: Content) => void;
    editable: boolean;
}

/**
 * Container for showing a scene based on the id in the url.
 * @param props
 * @constructor
 */
function SceneContainer(props: SceneContainerProps): ReactElement {
    const { response, isLoading, error } = useFetchOneScene(
        props.match.params.scene
    );

    if (isLoading) {
        return <Loader />;
    }

    return (
        <ResponseErrorHandler isLoading={isLoading} error={error}>
            <ScaledScene>
            <TemplateContainer
                editable={props.editable}
                scene={response}
                onContentChange={props.onContentChange}
                movable={false}
            />
            </ScaledScene>
        </ResponseErrorHandler>
    );
}

export default SceneContainer;
