import { default as React, ReactElement } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "core/components/UI/Modal/Modal";
import { useCreateSchedule } from "hooks/api/schedule/useCreateSchedule";
import { ScheduleForm } from "schedule/schedule/components/ScheduleForm";

interface ScheduleCreateModalProps {
    onDone: () => void;
    show: boolean;
}

interface FormValues {
    name: string;
    fallbackChannel: string;
}

export function ScheduleCreateModal(
    props: ScheduleCreateModalProps
): ReactElement {
    const { sendRequest, error, reset } = useCreateSchedule();
    const form = useForm();

    async function onSubmit(data: FormValues): Promise<void> {
        const result = await sendRequest({
            name: data.name,
            fallbackChannel: data.fallbackChannel ? data.fallbackChannel : null,
        });
        if (result) {
            form.reset();
            reset();
            props.onDone();
        }
    }

    function handleClose(): void {
        props.onDone();
    }

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            size="lg"
            title="Create new schedule"
            data-cy="create-schedule-modal"
        >
            <FormProvider {...form}>
                <ScheduleForm
                    error={error}
                    onSubmit={onSubmit}
                    handleClose={handleClose}
                />
            </FormProvider>
        </Modal>
    );
}
