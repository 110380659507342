import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { groupPath } from "core/util/routes";
import { GroupDeleteButton } from "group/button/GroupDeleteButton";
import { Collapse } from "react-bootstrap";
import { Group } from "model/Group";
import { GroupList } from "group/list/GroupList";
import styles from "./GroupListItem.scss";
import { FaCaretRight } from "react-icons/fa";
import { classNames } from "@castia/sdk";
import { ShowIf } from "core/util/ShowIf";
import { useIsAdmin } from "core/auth/useIsAdmin";

interface GroupListItemProps {
    group: Group;
    depth: number;
}

export function GroupListItem(props: GroupListItemProps): ReactElement {
    const [open, setOpen] = useState(
        props.depth === 0 && props.group.children?.length > 0
    );
    const hasChildren = props.group.children && props.group.children.length > 0;
    const isAdmin = useIsAdmin();

    return (
        <>
            <>
                <span
                    className={classNames(styles.lineContainer, styles.cell)}
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    <div
                        className={classNames(
                            "d-flex align-items-center",
                            styles.caretContainer
                        )}
                    >
                        <FaCaretRight
                            className={classNames(
                                styles.caret,
                                open ? styles.open : "",
                                !hasChildren ? "invisible" : ""
                            )}
                        />
                        <Link
                            className={classNames("nav nav-link", styles.link)}
                            to={`${groupPath}/${props.group.id}`}
                        >
                            {props.group.name}
                        </Link>
                    </div>
                </span>
                <span
                    className={classNames(styles.buttonContainer, styles.cell)}
                >
                    <ShowIf condition={isAdmin || props.depth > 0}>
                        <GroupDeleteButton groupId={props.group.id} />
                    </ShowIf>
                </span>
            </>
            <Collapse in={open}>
                <span className={styles.subGrid}>
                    {hasChildren && (
                        <GroupList
                            groups={props.group.children}
                            depth={props.depth + 1}
                        />
                    )}
                </span>
            </Collapse>
        </>
    );
}
