import React from "react";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { HasRole } from "core/auth/HasRole";
import { Role } from "core/auth/Role.enum";
import { NewsListContainer } from "news/list/NewsListContainer";
import { NewsCreateButton } from "news/create/NewsCreateButton";

export function NewsListPage() {
    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>News</PageLayout.Header.Title>
                <PageLayout.Header.Secondary>
                    <HasRole role={Role.ADMIN}>
                        <NewsCreateButton />
                    </HasRole>
                </PageLayout.Header.Secondary>
            </PageLayout.Header>
            <PageLayout.Body>
                <NewsListContainer />
            </PageLayout.Body>
        </PageLayout>
    );
}
