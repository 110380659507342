import React, { ReactElement } from "react";
import styles from "device/create/input/RegistrationCodeInput.scss";
import ReactCodeInput from "react-code-input";

interface RegistrationCodeInputProps {
    onComplete: (value: string) => void;
    disabled: boolean;
}

interface RegistrationCodeInputState {
    value: string;
}

class RegistrationCodeInput extends React.Component<
    RegistrationCodeInputProps,
    RegistrationCodeInputState
> {
    private static LENGTH = 8;

    public constructor(props: RegistrationCodeInputProps) {
        super(props);
        this.state = {
            value: "",
        };
    }

    private onChange(value: string): void {
        this.setState(
            {
                value: value.toUpperCase(),
            },
            (): void => {
                if (this.state.value.length === RegistrationCodeInput.LENGTH) {
                    this.props.onComplete(this.state.value);
                }
            }
        );
    }

    public render(): ReactElement {
        return (
            <div className={styles.codeInput}>
                <ReactCodeInput
                    type="text"
                    fields={RegistrationCodeInput.LENGTH}
                    onChange={this.onChange.bind(this)}
                    disabled={this.props.disabled}
                    value={this.state.value}
                    // inputStyle={{textTransform: "uppercase"}}
                />
                <input
                    type="hidden"
                    readOnly={true}
                    maxLength={RegistrationCodeInput.LENGTH}
                    autoComplete="off"
                    value={this.state.value}
                />
            </div>
        );
    }
}

export default RegistrationCodeInput;
