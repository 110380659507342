import React, { ReactElement } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Form, FormGroup, Row } from "react-bootstrap";
import Modal from "core/components/UI/Modal/Modal";
import styles from "scene/ScenesOverviewPage/components/SceneCreator/SceneCreateModal.scss";
import FormInputError from "core/components/UI/FormInputError/FormInputError";
import TemplateLayoutSelectInput from "scene/ScenesOverviewPage/components/SceneCreator/TemplateLayoutSelectInput";
import Button from "core/components/UI/Button/Button";
import { ApiError } from "@castia/sdk/dist/hooks/ApiError";
import Error from "core/components/UI/Error/Error";
import { useTemplate } from "hooks/useTemplate";

interface SceneLayoutFormProps {
    onSubmit: (data: SceneLayoutFormData) => void;
    secondaryButtonText?: string;
    secondaryButtonAction: () => void;
    template: string;
    initialLayout?: string;
    error?: ApiError;
}

export interface SceneLayoutFormData {
    layout: string;
}

export function SceneLayoutForm(props: SceneLayoutFormProps): ReactElement {
    const form = useForm<SceneLayoutFormData>();
    const { errors } = form.formState;
    const foundTemplate = useTemplate(props.template);

    return (
        <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(props.onSubmit)}>
            <Modal.Body>
                {props.error && (
                    <Error>Something went wrong while saving the layout.</Error>
                )}
                <FormGroup>
                    <Form.Label className={styles.templateLabel}>
                        Layout
                    </Form.Label>
                    {errors.layout && (
                        <FormInputError>
                            {errors.layout.message}
                        </FormInputError>
                    )}
                    <Row data-cy="layout-select-row">
                        <TemplateLayoutSelectInput
                            name="layout"
                            layouts={foundTemplate.layouts}
                            template={foundTemplate}
                            initialSelectedLayout={props.initialLayout}
                        />
                    </Row>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={"secondary"}
                    onClick={props.secondaryButtonAction}
                >
                    {props.secondaryButtonText || "Cancel"}
                </Button>
                <Button variant={"primary"} type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Form>
</FormProvider>
    );
}
