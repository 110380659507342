import { toast } from "react-toastify";
import { Button, ListGroup } from "react-bootstrap";
import React from "react";
import { useReloadAllDevicePlugins } from "hooks/api/device/useReloadAllDevicePlugins";

export function PlayerReloadPluginsListItem() {
    const refresh = useReloadAllDevicePlugins();

    async function onClick() {
        const confirmResult = confirm(
            "Are you sure you want to reload the plugins of all players of all users?"
        );
        if (confirmResult) {
            await refresh.sendRequest();
            toast.info("Player plugin reload triggered!");
        }
    }

    return (
        <ListGroup.Item>
            <div className="d-flex justify-content-between">
                <div className="nav-link">
                    Reload all players plugins (reloads content, screen flashes)
                </div>
                <div>
                    <Button onClick={onClick}>Update Plugins</Button>
                </div>
            </div>
        </ListGroup.Item>
    );
}