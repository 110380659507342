import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useFetchOneGroup } from "group/hooks/useFetchOneGroup";
import PageLayout from "core/components/UI/PageLayout/PageLayout";
import { ResponseErrorHandler } from "@castia/sdk";
import { GroupEditForm } from "group/edit/GroupEditForm";

interface GroupEditPageParams {
    groupId: string;
}

export function GroupEditPage(): ReactElement {
    const { groupId } = useParams<GroupEditPageParams>();
    const { response, isLoading, error } = useFetchOneGroup(groupId);

    return (
        <PageLayout>
            <PageLayout.Header>
                <PageLayout.Header.Title>Edit Group</PageLayout.Header.Title>
            </PageLayout.Header>
            <PageLayout.Body>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    {response && <GroupEditForm group={response} />}
                </ResponseErrorHandler>
            </PageLayout.Body>
        </PageLayout>
    );
}
