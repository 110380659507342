import { useContext } from "react";
import AuthContext from "services/auth/AuthContext";
import { Auth } from "services/auth/AuthContextProvider";

/**
 * Use the authentication context.
 */
export function useAuthentication(): Auth {
    return useContext(AuthContext);
}

export default useAuthentication;
