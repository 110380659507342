import * as React from "react";
import { ReactElement } from "react";
import { ButtonGroup, Container } from "react-bootstrap";
import AddSceneToChannelButton from "channel/button/AddSceneToChannelButton";
import styles from "channel/ChannelEditorPage.scss";
import DeleteChannelButton from "channel/button/DeleteChannelButton";
import { useEventListener } from "@castia/sdk";
import LocalEvents from "events/LocalEvents";
import useFetchChannel from "hooks/api/channel/useFetchChannel";
import ResponseErrorHandler from "core/components/ApiComponent/ResponseErrorHandler";
import { useParams } from "react-router-dom";
import { ChannelEditor } from "channel/edit/ChannelEditor";
import { EditChannelButton } from "channel/button/EditChannelButton";
import { useOrganizationContext } from "hooks/useOrganizationContext";
import { HasGroupRole } from "core/auth/HasGroupRole";
import { GroupRole } from "core/auth/GroupRole";

interface RouteInfo {
    channel: string;
}

/**
 * Main editor component to edit which scenes are shown in a channel.
 * @constructor
 */
function ChannelEditorPage(): ReactElement {
    const { channel } = useParams<RouteInfo>();
    const { response, isLoading, refreshData, error } =
        useFetchChannel(channel);
    const organizationContext = useOrganizationContext();

    useEventListener(LocalEvents.CHANNELS_SCENES_ADDED, refreshData);

    return (
        <>
            <Container fluid={true}>
                <div className={styles.header} data-cy="channel-header">
                    {response?.title || channel}
                    <ButtonGroup>
                        {response && (
                            <>
                                <HasGroupRole groupId={organizationContext} role={GroupRole.EDITOR}>
                                    <AddSceneToChannelButton channelId={channel} />
                                    <EditChannelButton channel={response} />
                                    <DeleteChannelButton channelId={channel} variant="button" />
                                </HasGroupRole>
                            </>
                        )}
                    </ButtonGroup>
                </div>
                <ResponseErrorHandler isLoading={isLoading} error={error}>
                    <ChannelEditor
                        scenes={response?.channelScenes}
                        channelId={channel}
                    />
                </ResponseErrorHandler>
            </Container>
        </>
    );
}

export default ChannelEditorPage;
