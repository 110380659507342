import { useFetchPaginated } from "hooks/useFetchPaginated";
import environment from "core/util/environment";
import { userPageSize } from "user/hooks/useFetchAllUsers";
import { WireguardPeerEntry } from "model/WireguardPeerEntry";

export function useFetchAllWireGuardPeerEntries() {
    return useFetchPaginated<WireguardPeerEntry>(
        `${environment.apiBaseUrl}/api/v1/wireguard-peers`,
        userPageSize,
        1
    );
}
