import { Content } from "model/Content";
import {
    default as React,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from "react";
import environment from "core/util/environment";
import Scene from "model/Scene";
import PluginTemplateComponent from "core/components/Plugin/PluginTemplateComponent/PluginTemplateComponent";
import usePlugins from "hooks/usePlugins";
import { deepMerge } from "core/util/deepMerge";
import { expandObject, TemplateSettings } from "@castia/sdk";

interface TemplateContainerProps {
    scene: Scene;
    editable: boolean;
    onContentChange?: (content: Content) => void;
    onSettingsItemChange?: (value: string, key: string) => void;
    movable: boolean;
    previewMode?: string;
}

/**
 * Container which renders a template and handles changes to its content.
 * @param props
 * @constructor
 */
function TemplateContainer(props: TemplateContainerProps): ReactElement {
    const { findTemplate } = usePlugins();
    const content: Content = useRef(props.scene.content || {});
    const [settings, setSettings] = useState<TemplateSettings>(
        props.scene.settings
    );

    function contentChange(value: string, fieldName: string): void {
        const updatedContent = content.current;
        updatedContent[fieldName] = value;
        content.current = updatedContent;
        props.onContentChange(content.current);
    }

    const templateToShow = findTemplate(props.scene.template);

    function onSettingsItemChange(value: string, key: string) {
        setSettings((prevState) => {
            const updatedSettings = deepMerge(
                {},
                prevState,
                expandObject({
                    [`custom.${key}`]: value,
                })
            );

            props.onSettingsItemChange(value, key);
            return updatedSettings as TemplateSettings;
        });
    }

    useEffect(() => {
        setSettings(props.scene.settings);
    }, [props.scene.settings]);

    const combinedSettings = {
        title: props.scene.settings.title,
        custom: deepMerge(
            {},
            templateToShow.defaultSettings || {},
            settings.custom
        ),
    };

    return (
        <>
            {templateToShow ? (
                <PluginTemplateComponent
                    template={templateToShow.templateIdentifier}
                    layoutId={props.scene.layout}
                    resourceDirectory={templateToShow.resourceDirectory}
                    environment={environment}
                    templateProps={{
                        editable: props.editable,
                        content: props.scene.content,
                        settings: combinedSettings,
                        onChange: contentChange,
                        preview: true,
                        onSettingsItemChange: onSettingsItemChange,
                        movable: props.movable,
                        previewMode: props.previewMode,
                    }}
                />
            ) : (
                "Something went wrong! The template in this scene does not exist :("
            )}
        </>
    );
}

export default TemplateContainer;
