import { useHasGroupRoleOnAnyGroup } from "core/auth/useHasGroupRoleOnAnyGroup";
import { GroupRole } from "core/auth/GroupRole";
import { ShowIf } from "core/util/ShowIf";
import React, { PropsWithChildren } from "react";

interface HasGroupRoleOnAnyGroupProps {
    role: GroupRole;
}

export function HasGroupRoleOnAnyGroup(
    props: PropsWithChildren<HasGroupRoleOnAnyGroupProps>
) {
    const hasGroupRole = useHasGroupRoleOnAnyGroup(props.role);

    return <ShowIf condition={hasGroupRole}>{props.children}</ShowIf>;
}
