import * as React from "react";
import { ReactElement } from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";
import AuthContextProvider from "services/auth/AuthContextProvider";
import EventContextProvider from "events/EventContextProvider";
import WebSocketComponent from "core/components/WebSocketComponent/WebSocketComponent";
import PluginContextProvider from "core/components/Plugin/PluginContext/PluginContextProvider";
import { UploadContextProvider } from "core/components/UploadContext/UploadContextProvider";
import { FontFace } from "core/components/FontFace";
import { TenantProfileContextProvider } from "tenant-profile/TenantProfileContextProvider";

type RouteComponent =
    | React.FunctionComponent<RouteComponentProps<unknown>>
    | React.ComponentClass<unknown>;

/**
 * Private route which requires Keycloak authentication to display.
 */
export default class PrivateRoute extends React.Component<RouteProps> {
    public constructor(props: RouteProps) {
        super(props);
    }

    private renderFunction(
        Component?: RouteComponent
    ): (props: RouteProps) => ReactElement {
        return (props: RouteProps): ReactElement => {
            // @ts-ignore
            return <Component {...props} />;
        };
    }

    public render(): ReactElement {
        const { component, ...rest } = this.props;
        return (
            <TenantProfileContextProvider>
                <EventContextProvider>
                    <AuthContextProvider>
                        <UploadContextProvider>
                            <PluginContextProvider>
                                <FontFace />
                                <WebSocketComponent />
                                <Route
                                    {...rest}
                                    render={this.renderFunction(component).bind(
                                        this
                                    )}
                                />
                            </PluginContextProvider>
                        </UploadContextProvider>
                    </AuthContextProvider>
                </EventContextProvider>
            </TenantProfileContextProvider>
        );
    }
}
