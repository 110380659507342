import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";

export function useRemoveUserFromGroup(
    userId: string,
    groupId: string,
    successEvent: string
): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v2/groups/${groupId}/users/${userId}`,
        "DELETE",
        successEvent
    );
}
