import LinkButton from "core/components/UI/LinkButton/LinkButton";
import { fontPath } from "core/util/routes";
import React from "react";

export function FontCreateButton() {
    return (
        <LinkButton
            to={`${fontPath}/create`}
            variant="primary"
            size="md"
            data-cy="create-font-button"
        >
            Add Font
        </LinkButton>
    );
}
