import { useFetchPersist } from "@castia/sdk";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

export function useDeleteNewsItem(newsItemId: string) {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/news/${newsItemId}`,
        "DELETE",
        LocalEvents.NEWS_CHANGED
    );
}
