import React, { ReactElement, useState } from "react";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import Modal from "core/components/UI/Modal/Modal";
import { UserAddToGroupModalBody } from "user/button/UserAddToGroupModalBody";
import { UserGroup } from "model/UserGroup";

interface UserAddToGroupButtonProps {
    email: string;
    currentGroups: UserGroup[];
}

export function UserAddToGroupButton(
    props: UserAddToGroupButtonProps
): ReactElement {
    const [showModal, setShowModal] = useState(false);

    const openModal = async (): Promise<void> => {
        setShowModal(true);
    };

    const closeModal = (): void => {
        setShowModal(false);
    };

    return (
        <>
            <Button
                variant="primary"
                onClick={openModal}
                title="invite user to group"
                data-cy="invite-user-group-button"
            >
                <FaPlus />
            </Button>
            <Modal
                show={showModal}
                onHide={closeModal}
                size={"lg"}
                title="Invite user to group"
            >
                <UserAddToGroupModalBody
                    email={props.email}
                    currentGroups={props.currentGroups}
                    onClose={closeModal}
                />
            </Modal>
        </>
    );
}
