import { useHistory } from "react-router-dom";
import { tenantProfilePath } from "core/util/routes";
import React from "react";
import {
    TenantProfileCreateFormFields,
    TenantProfileForm,
} from "tenant-profile/TenantProfileForm";
import { useCreateTenantProfile } from "hooks/api/tenant-profile/useCreateTenantProfile";

export function TenantProfileCreateForm() {
    const { sendRequest, isLoading } = useCreateTenantProfile();
    const history = useHistory();

    async function onSubmit(
        data: TenantProfileCreateFormFields
    ): Promise<void> {
        const result = await sendRequest(data);
        if (result) {
            history.push(`${tenantProfilePath}`);
        }
    }

    return <TenantProfileForm handleSubmit={onSubmit} isLoading={isLoading} />;
}
