import useFetchPersist, { PersistData } from "hooks/useFetchPersist";
import environment from "core/util/environment";
import LocalEvents from "events/LocalEvents";

/**
 * Create channel call
 */
function useCreateChannel(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/channels`,
        "POST",
        LocalEvents.CHANNELS_CHANGED
    );
}

export default useCreateChannel;
