import { Role } from "core/auth/Role.enum";
import { GroupRole } from "core/auth/GroupRole";

export function useRoleLabel() {
    return (role: Role | string | GroupRole): string => {
        switch (role) {
            case GroupRole.EDITOR:
                return "Editor";
            case GroupRole.READER:
                return "Reader";
            case Role.DEVELOPER:
                return "Developer";
            case GroupRole.RESELLER:
                return "Reseller";
            case Role.DEVICE:
                return "Device";
            case GroupRole.LOCATION_ADMIN:
                return "Location Admin";
            case GroupRole.ORGANIZATION_ADMIN:
                return "Organization Admin";
            case Role.ADMIN:
                return "Admin";
            case Role.SYSADMIN:
                return "System Administrator";
            case Role.DEVICE_SETUP:
                return "Device Setup";
            default:
                return "Unknown Role";
        }
    };
}
