import { FetchData } from "hooks/useFetch";
import environment from "core/util/environment";
import { Paginated, useEventListener } from "@castia/sdk";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";
import { useFetchPaginated } from "hooks/useFetchPaginated";
import { FolderListItem } from "model/FolderListItem";

const channelPageSize = 10;

/**
 * Fetch the channel list. Subscribe to the channel Change event and reload the data if it gets triggered.
 */
function useFetchAllChannelsPaginated(folderId?: string): FetchData<Paginated<FolderListItem>> {
    const fetch = useFetchPaginated<FolderListItem>(
        `${environment.apiBaseUrl}/api/v1/channels`,
        channelPageSize,
        1,
        folderId ? {
            folderId: folderId,
        }: undefined
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.CHANNELS_CHANGED, eventCallback);

    return fetch;
}

export default useFetchAllChannelsPaginated;
