import { Col, Form, Row } from "react-bootstrap";
import React from "react";
import { useFormContext } from "react-hook-form";

interface RepeatingEndInputProps {
    name: string;
}

export function RepeatingEndInput(props: RepeatingEndInputProps) {
    const form = useFormContext();
    const [hasEndDate, setHasEndDate] = React.useState(false);

    function onClearClick() {
        form.setValue(props.name, null);
    }

    return (
        <>
            <Form.Label>Repeating ends</Form.Label>
            <Form.Group>
                <Form.Check
                    label="Never"
                    type="radio"
                    value="radioEndsNever"
                    name="radioEnds"
                    checked={hasEndDate === false}
                    onChange={() => {
                        setHasEndDate(false);
                        onClearClick();
                    }}
                />
                <Row className="align-items-center">
                    <Col md={2}>
                        <Form.Check
                            label="At"
                            type="radio"
                            value="radioEndsAt"
                            name="radioEnds"
                            checked={hasEndDate === true}
                            onChange={() => {setHasEndDate(true);}}
                        />
                    </Col>
                    <Col md={4}>
                        <Form.Control
                            type="date"
                            {...form.register(props.name)}
                            disabled={!hasEndDate}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}
