import { FetchData, useEventListener, useFetch } from "@castia/sdk";
import { User } from "model/User";
import environment from "core/util/environment";
import { useCallback } from "react";
import LocalEvents from "events/LocalEvents";

export function useFetchOneUser(
    userId?: string,
    fetchProfile = false
): FetchData<User> {
    const fetch = useFetch<User>(
        fetchProfile
            ? `${environment.apiBaseUrl}/api/v2/users/me`
            : `${environment.apiBaseUrl}/api/v2/users/${userId}`
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.USER_CHANGED, eventCallback);

    return fetch;
}
