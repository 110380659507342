import React, { ReactElement } from "react";
import { Breadcrumb } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styles from "core/components/UI/BreadCrumbs/BreadCrumbs.scss";
import { rootPath } from "core/util/routes";

/**
 * Component showing breadcrumbs based on the URL.
 */
function BreadCrumbs(): ReactElement {
    const path = window.location.pathname;
    // Split the path on slashes and filter out the app part as we don't want to show this in the breadcrumb.
    const splitPath = path.split("/").filter((value): boolean => {
        return value !== "app";
    });

    const breadCrumbItems = splitPath.map(
        (pathItem, index, array): ReactElement => {
            const link = `${rootPath}${array.slice(0, index + 1).join("/")}`;
            const activeItem = index + 1 === array.length;
            const classes = activeItem
                ? "breadcrumb-item active"
                : "breadcrumb-item";
            return (
                <li className={classes} key={index}>
                    {activeItem ? (
                        pathItem
                    ) : (
                        <NavLink to={link}>{pathItem}</NavLink>
                    )}
                </li>
            );
        }
    );

    return (
        <Breadcrumb className={styles.breadcrumbs}>
            {breadCrumbItems}
        </Breadcrumb>
    );
}

export default BreadCrumbs;
